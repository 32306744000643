import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Checkbox } from 'rsuite'
import { BorderButton, CustomButton, CustomDropDown, CustomText } from '../../component'

function SettingUsersCreate() {
  const navigate = useNavigate()
  const { t } = useTranslation('common')

  return (
    <div className="p-5 h-full flex flex-col">
      <CustomText variant="xlPoppinsBold">Permissions</CustomText>
      <div className="flex flex-1 flex-col mt-6 p-5 bg-white rounded-lg border border-stroke h-[100%] overflow-hidden">
        <CustomText variant="lgPoppinsSemiBold">Select authorization for a user</CustomText>
        <div className="flex flex-col flex-1 mt-3 rounded-t-lg border border-b-0 border-stroke overflow-hidden overflow-y-auto scrollbar">
          <div className="py-3 px-5 border-b border-b-stroke">
            <CustomText variant="mdPoppinsSemiBold">Fill in information</CustomText>
          </div>
          <div className="flex-1 p-5 w-1/2 self-center">
            <CustomDropDown
              value={[]}
              className="!mb-3"
              title="Name"
              data={[]}
              labelField="title"
              valueField="handle"
              onSelect={(values) => {}}
            />
            <div className="mt-5">
              <CustomText variant="mdPoppinsSemiBold">Username:</CustomText>
              <CustomText variant="mdPoppinsRegular">carl@inphiz.com</CustomText>
            </div>
            <div className="mt-5">
              <CustomText variant="mdPoppinsSemiBold">GDPR-data:</CustomText>
              <Checkbox checked={false} onChange={() => {}} className="cm-checkbox flex-1 mt-2">
                Has permission to see all data
              </Checkbox>
            </div>
            <div className="mt-5">
              <CustomText variant="mdPoppinsSemiBold">Roles:</CustomText>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap rounded-b-lg border border-t-0 border-stroke gap-4 justify-end items-center p-4">
          <div className="flex gap-4 flex-wrap">
            <BorderButton
              children={t('cancel')}
              className="!px-2 sm:!px-5 flex-1"
              textColor="text-textPrimary"
              onClick={() => navigate(-1)}
            />
            <CustomButton
              children={t('save')}
              className="flex-auto"
              backgroundColor="bg-secondary"
              textColor="text-textWhite"
              onClick={() => {}}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SettingUsersCreate
