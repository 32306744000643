import { useMemo } from 'react'
import FlatList from 'flatlist-react'
import { useTranslation } from 'react-i18next'
import type { Group, Social } from '../../../packages/api-web'
import { useSocialList } from '../../../packages/api-web'
import { Spinner, TextWithIcon } from '../../atoms'
import { SocialEmpty } from '../../molecules'
import { SocialDetails as SocialDetailsComponent } from '../../../screen'
import { SendMessage } from '../../../icons'

interface SnackListProps {
  selectedItem?: Group
  onEmptyBtnPress?: () => void
}

export function SnackList({ selectedItem, onEmptyBtnPress }: SnackListProps) {
  const { t } = useTranslation('common')
  const { data, hasNextPage, fetchNextPage, isLoading } = useSocialList({
    selectedSocialGroupHandle: selectedItem?.handle,
  })

  const socialList = useMemo(() => {
    const result = data?.pages?.flatMap(i => i.items)
    return result as Social[]
  }, [data])

  const renderItem = (item: Social, idx: string) => {
    return (
      <li key={idx} className="pb-2 cursor-pointer" onClick={() => {}}>
        <SocialDetailsComponent
          socialHandle={item?.handle ?? ''}
          typeHandle={undefined}
          onDeleteSuccess={() => {}}
        />
      </li>
    )
  }

  const blank = () =>
    isLoading
      ? (
          <Spinner />
        )
      : (
          <SocialEmpty
            title={t('letTalk')}
            subTitle={t('socialNoData')}
            btnTitle={t('startConversation')}
            onEmptyBtnPress={onEmptyBtnPress}
          />
        )

  return (
    <div className="flex flex-col flex-1 overflow-auto scrollbar">
      {socialList && socialList.length > 0 && (
        <button
          className="bg-white p-4 rounded-lg mb-5 border border-stroke text-left"
          onClick={onEmptyBtnPress}
        >
          <TextWithIcon
            text={t('whatAreYouThinking')}
            rightIcon={<SendMessage className="fill-secondary" />}
          />
        </button>
      )}
      <ul>
        <FlatList
          list={socialList}
          renderItem={renderItem}
          hasMoreItems={hasNextPage}
          loadMoreItems={fetchNextPage}
          renderWhenEmpty={blank}
        />
      </ul>
    </div>
  )
}
