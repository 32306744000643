import { useTranslation } from 'react-i18next'
import { RightIcon } from '../../../icons'
import { CustomCircleBar, CustomText } from '../../atoms'

interface Params {
  title?: string
  subTitle?: string
  percentage?: string
  targetLine?: number
  color?: string
  isShowEmployee?: boolean
}

export function ComplianceCard({
  title,
  subTitle,
  percentage,
  targetLine,
  color,
  isShowEmployee,
}: Params) {
  const { t } = useTranslation('common')

  return (
    <div className="rounded-lg border border-stroke">
      <CustomText variant="mdPoppinsSemiBold" className="p-3 border-b border-stroke">
        {title ?? ''}
      </CustomText>
      <div className="p-3">
        <div className="flex">
          <CustomText variant="mdPoppinsRegular">{t('case').toUpperCase()}</CustomText>
          <CustomText variant="mdPoppinsSemiBold">{`: ${targetLine} %`}</CustomText>
        </div>
        <div className="my-5 flex items-center justify-center relative">
          <CustomCircleBar
            colour={color ?? ''}
            percentage={percentage ? Number.parseInt(percentage) : 0}
            size={180}
            isLine
            targetLine={targetLine ?? 0}
          />
          <CustomText variant="xxlPoppinsSemiBold" className="absolute">
            {`${percentage}%`}
          </CustomText>
        </div>
        <div className="h-4 mb-3 flex items-center content-end justify-end">
          <CustomText variant="mdPoppinsRegular">
            {isShowEmployee ? t('seeEmployees') : ''}
          </CustomText>
          {isShowEmployee && <RightIcon />}
        </div>
        <CustomText variant="mdPoppinsRegular" className="p-4 bg-bubble italic">
          {subTitle ?? ''}
        </CustomText>
      </div>
    </div>
  )
}
