import { useMutation } from '@tanstack/react-query'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import type { UserDto } from '@inphiz-shared/shared-models/src/Dto/user-dto'

export function usePostEmployee() {
  return useMutation({
    mutationFn: async (params: UserDto) => {
      const data = {
        item: params,
      }
      const result = await APIFunctions.POST(APIConst.usersList, data)
      return result
    },
  })
}
