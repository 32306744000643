import { useTranslation } from 'react-i18next'
import type { KeyboardEvent } from 'react'
import { useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  BorderButton,
  CustomButton,
  CustomMultiSelectMenu,
  CustomPagination,
  CustomTable,
  CustomText,
  TextWithIcon,
} from '../../component'
import { CloseIcon, RightIcon, SearchIcon } from '../../icons'

function Experiences() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const [searchTxt, setSearchTxt] = useState('')
  const [itemPerPage, setItemPage] = useState(20)
  const [skipItem, setSkipItem] = useState(0)
  const [selectedItem, setSelectedItem] = useState()
  const [filterData, setFilterData] = useState([
    {
      id: 1,
      header: t('typeOfExperience'),
      data: [],
      selectedData: [],
    },
    {
      id: 2,
      header: t('appendixMandatory'),
      data: [],
      selectedData: [],
    },
    {
      id: 3,
      header: t('regulatoryCompliance'),
      data: [],
      selectedData: [],
    },
  ])
  const data = {
    totalCount: 110,
    items: [
      {
        name: 'Test',
        jobTitle: 'Test',
        manager: 'Test',
        cost: '2113',
        department: 'Mobile',
        work: '414',
      },
      {
        name: 'Test',
        jobTitle: 'Test',
        manager: 'Test',
        cost: '2113',
        department: 'Mobile',
        work: '414',
      },
    ],
  }

  const tableHeaderList = useMemo(
    () => [
      { title: t('title_experience'), id: 1, key: 'name' },
      { title: t('periodOfValidity'), id: 2, key: 'jobTitle' },
      { title: t('appendixMandatory'), id: 3, key: 'manager' },
      { title: t('expires_renew'), id: 4, key: 'cost' },
      { title: t('typeOfExperience'), id: 5, key: 'department' },
      { title: t('regulatoryCompliance'), id: 6, key: 'work' },
    ],
    [t],
  )

  const employeeList = {
    totalCount: 110,
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>, refetchFn: () => void) => {
    if (event.key === 'Enter')
      refetchFn()
  }

  return (
    <div className="p-5 h-full flex flex-col">
      <CustomText variant="xlPoppinsBold">{t('experiences')}</CustomText>
      <div className="mt-6 px-5 py-3 bg-white rounded-lg flex items-center justify-between border border-stroke">
        <div className="flex items-center gap-3 flex-wrap">
          {filterData.map((item, index) => {
            return (
              <div className="flex items-center gap-3" key={index}>
                <CustomMultiSelectMenu
                  selectedValue={item?.selectedData}
                  menuList={item?.data ?? []}
                  onSelectedValue={(value) => {}}
                  buttonComponent={(
                    <div>
                      <TextWithIcon
                        text={item?.header}
                        rightIcon={<RightIcon className="h-4 w-4 rotate-90 ml-4" />}
                        textVariant="smPoppinsRegular"
                      />
                      {item?.selectedData.length > 0 && (
                        <div className="absolute -top-4 right-0 bg-primary h-4 w-4 rounded-full justify-center items-center flex">
                          <CustomText variant="xsmPoppinsRegular" textColor="text-textWhite">
                            {item?.selectedData.length.toString()}
                          </CustomText>
                        </div>
                      )}
                    </div>
                  )}
                />
                <div className="h-8 bg-black/10 w-[1px]" />
              </div>
            )
          })}
        </div>
        <BorderButton children={t('filter')} className="px-7 self-end" />
      </div>
      <div className="mt-6 py-6 px-5 bg-white rounded-lg border border-stroke flex flex-col overflow-hidden">
        <div className="flex flex-col sm:flex-row mb-4 gap-4">
          <CustomText className="mr-auto self-center" wrapText={false} variant="xlPoppinsBold">
            {`${t('total')}: ${employeeList?.totalCount ?? 0}`}
          </CustomText>
          <div className="flex flex-1 items-center justify-between">
            <div className="flex items-center w-auto gap-4">
              <SearchIcon />
              <input
                type="text"
                value={searchTxt}
                placeholder={t('searchPlaceholder')}
                className="outline-0 flex-1 min-w-[100px]"
                onChange={e => setSearchTxt(e.target.value)}
                // onKeyDown={e => handleKeyDown(e, refetch)}
              />
              {searchTxt && (
                <button onClick={() => setSearchTxt('')}>
                  <CloseIcon className="h-5" />
                </button>
              )}
            </div>
            <CustomButton
              children={t('create')}
              textVariant="smPoppinsMedium"
              className="px-4 sm:px-8 bg-secondary"
              onClick={() => {
                navigate('/experiences/certificate')
              }}
            />
          </div>
        </div>
        <div className="flex flex-col overflow-hidden rounded-lg border border-stroke">
          <div className="flex-1 overflow-x-auto overflow-y-auto scrollbar bg-white">
            <CustomTable
              tableHeaderList={tableHeaderList}
              tableDataList={data?.items}
              displayKeys={['name', 'jobTitle', 'manager', 'cost', 'department', 'work']}
            />
          </div>
          {data && data?.items.length > 0 && (
            <CustomPagination
              totalItem={data?.totalCount}
              itemPerPage={itemPerPage}
              onChange={setItemPage}
              onPagePress={index => setSkipItem((index - 1) * itemPerPage)}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default Experiences
