import { useTranslation } from 'react-i18next'
import { CustomText, LineChart } from '../../atoms'

interface tabArrayParams {
  color?: string
  title?: string
  info?: string
  subTitle?: string
  rounded?: boolean | undefined
}

export function ControlCenterHourCost() {
  const { t } = useTranslation('common')
  const barLabel = [
    {
      color: 'bg-chartBlue',
      title: t('budgetCost'),
      rounded: true,
    },
    {
      color: 'bg-chartGreen',
      title: t('outcomeCost'),
    },
    {
      color: 'bg-chartRed',
      title: t('outcomeCostWorked'),
    },
  ] as unknown as tabArrayParams[]

  return (
    <div>
      <div className="mt-5 py-5 px-5 bg-white rounded-lg border border-stroke flex flex-col overflow-hidden">
        <CustomText variant="mdPoppinsSemiBold">{t('controllableCosts')}</CustomText>
        <div className="mt-5 grid grid-cols-1 lg:grid-cols-3 gap-5">
          <div className="col-span-2">
            <LineChart
              minYAxis={0}
              maxYAxis={1200}
              labels={[
                'Jan 2024',
                'Feb 2024',
                'Mar 2024',
                'Apr 2024',
                'May 2024',
                'Jun 2024',
                'July 2024',
                'Aug 2024',
                'Sep 2024',
                'Oct 2024',
                'Nov 2024',
                'Dec 2024',
              ]}
              datasets={[
                {
                  label: t('budgetCost'),
                  data: [920, 945, 890, 930, 930, 1040, 790, 840, 920, 950, 1050, 1000],
                  pointRadius: 5,
                  pointHoverRadius: 4,
                  pointBackgroundColor: 'blue',
                  tension: 0.1,
                  borderWidth: 1,
                  segment: {
                    borderColor: 'blue',
                    borderDash: [6, 6],
                  },
                  stack: '1',
                },
                {
                  label: t('outcomeCost'),
                  data: [580, 610, 590, 620, 600, 700, 620, 600, 610, 650, 770, 710],
                  pointRadius: 5,
                  pointHoverRadius: 4,
                  pointBackgroundColor: 'red',
                  tension: 0.1,
                  borderWidth: 1,
                  borderColor: 'red',
                  stack: '2',
                },
                {
                  label: t('outcomeCostWorked'),
                  data: [470, 470, 450, 480, 480, 520, 390, 420, 460, 480, 590, 560],
                  pointRadius: 5,
                  pointHoverRadius: 4,
                  pointBackgroundColor: 'green',
                  tension: 0.1,
                  borderWidth: 1,
                  borderColor: 'green',
                  stack: '3',
                },
              ]}
            />
          </div>
          <div className="flex flex-col gap-3 justify-center">
            {barLabel?.map((item) => {
              return (
                <div className="flex items-center">
                  <div
                    className={`h-5 w-5 mr-2 ${item?.color} ${item?.rounded ? 'rounded-full' : ''}`}
                  />
                  <CustomText variant="mdPoppinsRegular">{item?.title ?? ''}</CustomText>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
