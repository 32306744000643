import { useEffect, useState } from 'react'
import { Checkbox } from 'rsuite'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  BorderButton,
  CustomButton,
  CustomDropDown,
  CustomInput,
  CustomNumberInput,
  CustomText,
  CustomTextArea,
  DeleteAlert,
  ImagePicker,
  Spinner,
} from '../../component'
import CustomDatePicker from '../../component/atoms/CustomDatePicker'
import type { EquipmentCategoryItem } from '../../packages/api-web/Equipment'
import {
  useEquipmentCategory,
  useEquipmentCreate,
  useEquipmentDetails,
  useUpdateEquipment,
} from '../../packages/api-web/Equipment'
import { useEquipmentDelete } from '../../packages/api-web/Equipment/useEquipmentDelete'
import type { UploadResponse } from '../../packages/api-web'
import { useUploadMedia } from '../../packages/api-web'

interface CategoryItem extends EquipmentCategoryItem {
  titleIncludingParentsTitle?: string
}

interface EquipmentDetails {
  title?: string
  description?: string
  category?: CategoryItem[]
  isSignature?: boolean
  isVisible?: boolean
  price?: number
  endOfUseDate?: Date
  media?: string
}

function EquipmentCreate() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { id } = useParams()
  const { data: equipment, isFetching: isEquipmentFetching } = useEquipmentDetails({
    handle: id ?? '',
  })
  const { data: categoryList, isFetching } = useEquipmentCategory()
  const { mutate: updateEquipment, isPending: isUpdatePending } = useUpdateEquipment()
  const { mutate: deleteEquipment, isPending: isDeletePending } = useEquipmentDelete()
  const { mutate, isPending } = useEquipmentCreate()
  const { mutate: uploadMediaMute, isPending: isUploadImage } = useUploadMedia()
  const [equipmentDetails, setEquipmentDetails] = useState<EquipmentDetails>({
    title: '',
    description: '',
    category: [],
    isSignature: true,
    isVisible: true,
    price: 0,
    endOfUseDate: undefined,
    media: undefined,
  })
  const [errorString, setErrorString] = useState<string[]>([])
  const [isShowAlert, setIsShowAlert] = useState(false)
  const [selectedImage, setSelectedImage] = useState(undefined)

  const allChildEquipmentCategories = categoryList?.items
    ?.flatMap(item =>
      item?.childEquipmentCategories?.map(child => ({
        ...child,
        titleIncludingParentsTitle: `${child.title} (${item.title})`,
      })),
    )
    .reverse() as any[]

  useEffect(() => {
    if (equipment && equipment.item && !isEquipmentFetching) {
      const {
        title,
        content,
        equipmentCategoryHandle,
        requiresSign,
        isVisible,
        price,
        endOfUseDate,
        media,
        mediaItemHandles,
      } = equipment?.item
      setEquipmentDetails({
        title,
        description: content,
        category:
          (allChildEquipmentCategories?.filter(
            i => i?.handle == equipmentCategoryHandle,
          ) as CategoryItem[]) || undefined,
        isSignature: requiresSign,
        isVisible,
        price,
        endOfUseDate: endOfUseDate ? new Date(endOfUseDate) : undefined,
        media: mediaItemHandles && mediaItemHandles?.length > 0 ? media?.[0].url : undefined,
      })
    }
  }, [equipment])

  const onSavePress = () => {
    if (
      equipmentDetails?.title
      && Array.isArray(equipmentDetails?.category)
      && equipmentDetails.category.length > 0
    ) {
      const paramsObj = {
        title: equipmentDetails.title,
        content: equipmentDetails.description,
        endOfUseDate: equipmentDetails.endOfUseDate?.toISOString(),
        price: equipmentDetails.price ? equipmentDetails.price : undefined,
        requiresSign: equipmentDetails.isSignature,
        isVisible: equipmentDetails.isVisible,
        equipmentCategoryHandle: equipmentDetails.category[0].handle,
      }
      if (id) {
        const updatedParams = {
          ...paramsObj,
          handle: id,
          mediaItemHandles: equipmentDetails.media ? equipment?.item?.mediaItemHandles : [],
        }
        const requestParams: FormData = new FormData()
        if (selectedImage) {
          requestParams.append('files', selectedImage)
          uploadMediaMute(requestParams, {
            onSuccess: (response: UploadResponse) => {
              if (response.isSuccess) {
                const newObject = {
                  ...updatedParams,
                  handle: id,
                  mediaItemHandles: response.items?.map(i => i.handle)?.filter(Boolean) as string[],
                }
                mutate(newObject, {
                  onSuccess: (data) => {
                    if (data.isSuccess) {
                      navigate(-1)
                    }
                  },
                })
              }
            },
          })
        }
        else {
          updateEquipment(updatedParams, {
            onSuccess: (data) => {
              if (data.isSuccess) {
                navigate(-1)
              }
            },
          })
        }
      }
      else {
        const requestParams: FormData = new FormData()
        if (selectedImage) {
          requestParams.append('files', selectedImage)
          uploadMediaMute(requestParams, {
            onSuccess: (response: UploadResponse) => {
              if (response.isSuccess) {
                const newObject = {
                  ...paramsObj,
                  mediaItemHandles: response.items?.map(i => i.handle)?.filter(Boolean) as string[],
                }
                mutate(newObject, {
                  onSuccess: (data) => {
                    if (data.isSuccess) {
                      navigate(-1)
                    }
                  },
                })
              }
            },
          })
        }
        else {
          mutate(paramsObj, {
            onSuccess: (data) => {
              if (data.isSuccess) {
                navigate(-1)
              }
            },
          })
        }
      }
    }
    else {
      const errors = []
      if (!equipmentDetails.title)
        errors.push(t('equipmentTitle'))
      if (!equipmentDetails?.category?.length)
        errors.push(t('equipmentCategory'))
      setErrorString(errors)
    }
  }

  if (isFetching || isEquipmentFetching)
    return <Spinner />

  return (
    <div className="p-5 h-full flex flex-col">
      {(isPending || isUpdatePending || isDeletePending || isUploadImage) && <Spinner />}
      <CustomText variant="xlPoppinsBold">{t('equipment')}</CustomText>
      <div className="flex flex-1 flex-col mt-6 px-5 bg-white rounded-lg border border-stroke h-[100%] overflow-hidden">
        <CustomText variant="lgPoppinsSemiBold" className="my-3">
          {id ? t('editEquipment') : t('createEquipment')}
        </CustomText>
        <div className="flex-1 overflow-hidden overflow-y-auto scrollbar">
          <div className="border border-stroke rounded-lg">
            <div className="py-3 px-5 border-b border-b-stroke">
              <CustomText variant="mdPoppinsSemiBold">{t('details')}</CustomText>
            </div>
            <div className="flex-1 p-5 overflow-y-auto scrollbar">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                <CustomInput
                  className={`${!equipmentDetails.title && errorString.length > 0 ? 'outline-textDanger' : ''}`}
                  title={t('title')}
                  value={equipmentDetails.title}
                  onChange={(e) => {
                    setEquipmentDetails({
                      ...equipmentDetails,
                      title: e.target.value,
                    })
                  }}
                  placeholder={t('exHotWork')}
                />
                <CustomDropDown
                  value={equipmentDetails?.category && equipmentDetails?.category[0]?.handle}
                  className="!mb-3"
                  title={`${t('category')}`}
                  data={allChildEquipmentCategories ?? []}
                  labelField="titleIncludingParentsTitle"
                  valueField="handle"
                  style={{
                    borderColor:
                      equipmentDetails?.category?.length == 0 && errorString.length > 0
                        ? 'red'
                        : '',
                    borderWidth:
                      equipmentDetails?.category?.length == 0 && errorString.length > 0 ? 2 : 0,
                    borderRadius: 10,
                  }}
                  onSelect={(_, values) => {
                    setEquipmentDetails({
                      ...equipmentDetails,
                      category: values as any,
                    })
                  }}
                  placeholder={t('selectExperienceType')}
                />
                <CustomDatePicker
                  className="!mt-3"
                  title={t('endOfUseDate')}
                  minDate={new Date()}
                  value={equipmentDetails.endOfUseDate}
                  onChange={(date) => {
                    if (date) {
                      setEquipmentDetails({
                        ...equipmentDetails,
                        endOfUseDate: date,
                      })
                    }
                  }}
                  placement="bottomEnd"
                />
                <CustomNumberInput
                  className="!mt-2"
                  title={t('costEstimation')}
                  value={equipmentDetails.price}
                  onChange={(value) => {
                    setEquipmentDetails({
                      ...equipmentDetails,
                      price: Number(value),
                    })
                  }}
                />
                <div className="flex flex-col gap-5">
                  <CustomTextArea
                    rows={5}
                    title={t('description')}
                    value={equipmentDetails.description}
                    onChange={(e) => {
                      setEquipmentDetails({
                        ...equipmentDetails,
                        description: e.target.value,
                      })
                    }}
                  />
                  <Checkbox
                    checked={equipmentDetails.isSignature}
                    onChange={() => {
                      setEquipmentDetails({
                        ...equipmentDetails,
                        isSignature: !equipmentDetails.isSignature,
                      })
                    }}
                    className="cm-checkbox"
                  >
                    {t('requiresSignature')}
                  </Checkbox>
                  <Checkbox
                    checked={equipmentDetails.isVisible}
                    onChange={() => {
                      setEquipmentDetails({
                        ...equipmentDetails,
                        isVisible: !equipmentDetails.isVisible,
                      })
                    }}
                    className="cm-checkbox"
                  >
                    {t('isVisible')}
                  </Checkbox>
                </div>
                <ImagePicker
                  title={t('uploadImage')}
                  defaultImage={equipmentDetails.media}
                  buttonTitle={t('choose')}
                  onClearImage={() => {
                    setEquipmentDetails({
                      ...equipmentDetails,
                      media: undefined,
                    })
                  }}
                  selectedImage={(item) => {
                    setSelectedImage(item)
                  }}
                />
              </div>
            </div>
          </div>
          {errorString.length > 0 && (
            <ul className="mt-5 list-disc pl-4">
              {errorString.map((i, index) => (
                <li key={index} className="text-textDanger">
                  {i}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="relative flex flex-wrap gap-4 justify-between my-4">
          {id ? (
            <BorderButton
              children={t('delete')}
              className="!px-6"
              textColor="text-textPrimary"
              onClick={() => setIsShowAlert(true)}
            />
          ) : (
            <div />
          )}
          <div className="flex gap-4">
            <CustomButton
              children={t('save')}
              backgroundColor="bg-secondary"
              textColor="text-textWhite"
              className="!px-6"
              onClick={onSavePress}
            />
            <CustomButton
              children={t('cancel')}
              backgroundColor="bg-bthDisable"
              className="!px-6"
              textColor="text-textPrimary"
              onClick={() => {
                navigate(-1)
              }}
            />
          </div>
        </div>
      </div>
      {isShowAlert && (
        <DeleteAlert
          title={`${t('equipmentType')}: ${equipment?.item?.title}`}
          subTitle={t('deleteEquipment')}
          leftButtonText={t('cancel')}
          rightButtonText={t('delete')}
          onCloseAlert={() => {
            setIsShowAlert(false)
          }}
          onRightButton={() => {
            setIsShowAlert(false)
            if (equipment?.item) {
              deleteEquipment(equipment.item, {
                onSuccess: (data) => {
                  if (data.isSuccess) {
                    navigate(-1)
                  }
                },
              })
            }
          }}
        />
      )}
    </div>
  )
}

export default EquipmentCreate
