import type { ReactNode } from 'react'

import { Logger } from '@inphiz/logger'
import * as Sentry from '@sentry/react-native'
import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query'

const queryCache = new QueryCache({
  onError: (error, query) => {
    Logger.Log.error('Global query error', { error, query })
    Sentry.withScope((scope) => {
      scope.setContext('query', { queryHash: query.queryHash })
      scope.setFingerprint([query.queryHash.replaceAll(/\d/g, '0')])
      Sentry.captureException(error)
    })
  },
})

const mutationCache = new MutationCache({
  onError: (error, variables, context, mutation) => {
    Logger.Log.error('Global mutation error', { error, variables, context, mutation })
    Sentry.withScope((scope) => {
      scope.setContext('mutation', {
        mutationId: mutation.mutationId,
        variables: mutation.state.variables,
      })
      if (mutation.options.mutationKey) {
        scope.setFingerprint(
          // Duplicate to prevent modification
          Array.from(mutation.options.mutationKey) as string[],
        )
      }
      Sentry.captureException(error)
    })
  },
})

export const queryClient = new QueryClient({
  queryCache,
  mutationCache,
})

export interface ApiProviderProps {
  readonly children: ReactNode
}
