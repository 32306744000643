import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { JobTitleDto } from '@inphiz-shared/shared-models/src/F_Selfleadership/job-title-dto'
import { QueryBuilder } from '@inphiz/core-utilities'

const JobTitleItemDto = new JobTitleDto()
export type JobTitleItem = typeof JobTitleItemDto

const RolePositionsDto = new DataCollectionApiResponse<JobTitleItem>()
export type RolePositionsData = typeof RolePositionsDto

interface RolePositionParams {
  itemPerPage?: number
  skip?: number
  searchTxt?: string
  organizationUnitHandle?: string
}

export function useRolePositions({
  itemPerPage = 20,
  skip = 0,
  searchTxt = '',
  organizationUnitHandle,
}: RolePositionParams) {
  return useQuery<RolePositionsData>({
    queryKey: [QueryKey.rolePositions],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.employeesPerformance.getJobTitles)
        .add('cursor', skip)
        .add('take', itemPerPage)
        .add('filter', searchTxt)
      if (organizationUnitHandle) {
        qb.add('ParametersWithValues[OrganizationUnitHandle]', organizationUnitHandle)
      }
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
  })
}
