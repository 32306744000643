import { APIConst } from '@inphiz/api'
import { useContext, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { UserContext } from '../../../contexts/UserContext'
import { CameraIcon, SendMessage } from '../../../icons'
import { CustomImg } from '../../atoms'

export interface AddCommentProps {
  onTapPublish?: (text: string) => void
  selectedImage?: (item: any) => void
}

export function AddComment({ onTapPublish, selectedImage }: AddCommentProps) {
  const { user } = useContext(UserContext)
  const { t } = useTranslation()
  const [message, setMessage] = useState<string>('')
  const hiddenFileInput = useRef<HTMLInputElement>(null)

  const handleClick = () => {
    hiddenFileInput?.current?.click()
  }

  const handleChange = (event: any) => {
    const fileUploaded = event.target.files[0]
    selectedImage?.(fileUploaded)
  }

  return (
    <div className="flex flex-1 items-center justify-between w-full">
      <input type="file" accept="image/*" onChange={handleChange} ref={hiddenFileInput} hidden />
      <CustomImg
        name={user?.userData?.displayName ?? 'Anonym'}
        imgSrc={
          user?.userData?.profileImageHandle
            ? `${APIConst.baseURL}/${APIConst.mediaUpload}/${user?.userData?.profileImageHandle}`
            : ''
        }
        altText="user_image"
        className="h-8 w-8 rounded-full"
        imgClassName="rounded-full"
      />
      <div className="flex mx-5 flex-1 rounded-full border-0 ring-1 ring-stroke">
        <input
          type="text"
          className="flex-1 py-1.5 pl-5 rounded-full placeholder:text-grey/40 focus:outline-none"
          placeholder={t('typeHere')}
          value={message}
          onChange={(e) => {
            setMessage(e.target.value)
          }}
        />
        <button
          className="mr-3"
          onClick={() => {
            handleClick()
          }}
        >
          <CameraIcon className="h-4 w-4" />
        </button>
      </div>
      <button
        onClick={() => {
          if (message.length > 0) {
            onTapPublish?.(message)
          }
          setMessage('')
        }}
      >
        <SendMessage className="fill-secondary" />
      </button>
    </div>
  )
}
