import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import {
  BorderButton,
  CustomAlert,
  CustomButton,
  CustomInput,
  CustomText,
  Spinner,
} from '../../atoms'
import { InActiveIcon } from '../../../icons'
import { usePostLoginAccount, usePutPassword } from '../../../packages/api-web'

interface Params {
  userHandle?: string
  isCreatePassword?: boolean
  onCloseAlert?: () => void
}

export function ResetPasswordModal({ userHandle, isCreatePassword = false, onCloseAlert }: Params) {
  const { t } = useTranslation('common')
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [errorString, setErrorString] = useState<string[]>([t('passwordErr')])
  const { isPending, mutate } = usePutPassword()
  const { isPending: isLoginPending, mutate: createLoginMutate } = usePostLoginAccount()

  const validatePassword = (password: string): boolean => {
    // Regular expression for validation
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/
    return passwordRegex.test(password)
  }

  const checkValidation = () => {
    const errors = []
    if (!validatePassword(password)) {
      errors.push(t('passwordErr'))
    }
    if (repeatPassword !== password) {
      errors.push(t('repeatPasswordErr'))
    }
    setErrorString(errors)
    return Object.keys(errors).length === 0
  }

  return (
    <CustomAlert
      onClose={() => {
        onCloseAlert?.()
      }}
    >
      {(isPending || isLoginPending) && <Spinner />}
      <CustomText variant="mdPoppinsSemiBold">
        {isCreatePassword ? 'Create password' : t('resetPassword')}
      </CustomText>
      <div className="flex my-5 p-3 justify-center text-left border border-yellow rounded-md">
        <InActiveIcon className="fill-yellow" />
        <CustomText variant="mdPoppinsRegular" className="ml-2 flex-1">
          {t('passwordWarning')}
        </CustomText>
      </div>
      <div className="flex flex-col gap-3 text-left">
        <CustomInput
          title={t('password')}
          value={password}
          onChange={(e) => {
            setPassword(e.target.value)
          }}
          placeholder=""
          onBlur={() => {
            checkValidation()
          }}
        />
        <CustomInput
          title={t('repeatPassword')}
          value={repeatPassword}
          onChange={(e) => {
            setRepeatPassword(e.target.value)
          }}
          placeholder=""
          onBlur={() => {
            checkValidation()
          }}
        />
        {errorString.length > 0 && (
          <ul className="mt-5 list-disc pl-4">
            {errorString.map((i, index) => (
              <li key={index} className="text-textDanger">
                {i}
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="flex py-4 justify-end border-t border-stroke mt-5">
        <div className="flex gap-4 flex-wrap justify-end">
          <BorderButton
            children={t('cancel')}
            className="!px-2 flex-1"
            textColor="text-textPrimary"
            onClick={() => {
              onCloseAlert?.()
            }}
          />
          <CustomButton
            children={isCreatePassword ? t('create') : t('add')}
            disabled={!(password && repeatPassword && errorString?.length == 0)}
            className="!px-6 flex-1"
            backgroundColor={
              password && repeatPassword && errorString?.length == 0
                ? 'bg-secondary'
                : 'bg-bthDisable'
            }
            textColor="text-textWhite"
            onClick={() => {
              if (isCreatePassword) {
                const data = {
                  userHandle,
                  password,
                }
                createLoginMutate(data, {
                  onSuccess: () => {
                    onCloseAlert?.()
                  },
                })
              }
              else {
                const data = {
                  userIdentityValue: userHandle,
                  password,
                }
                mutate(data, {
                  onSuccess: () => {
                    onCloseAlert?.()
                  },
                })
              }
            }}
          />
        </div>
      </div>
    </CustomAlert>
  )
}
