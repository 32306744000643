import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import { APIConst } from '@inphiz/api'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { UserDto } from '@inphiz-shared/shared-models/src/Dto/user-dto'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { QueryBuilder } from '@inphiz/core-utilities'

const UserParamDto = new UserDto()
type UserItem = typeof UserParamDto

const UserManagementDto = new DataCollectionApiResponse<UserItem>()
type UserManagementList = typeof UserManagementDto

interface Params {
  itemPerPage?: number
  skip?: number
}

export function useManagementUser({ itemPerPage = 20, skip = 0 }: Params) {
  return useQuery<UserManagementList>({
    queryKey: [QueryKey.getManagementUsers],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.managementUsers)
        .add('cursor', skip)
        .add('take', itemPerPage)
        .add('isorderascending', 'False')
        .add('ParametersWithValues[HideSuperAdmins]', 'True')
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
  })
}
