import { CloseIcon } from '../../../icons'
import { NotificationList } from '../../../screen'

interface CustomSlideMenuProps {
  rightSidebarToggle: boolean
  onClick: () => void
}

export function CustomNotificationSideMenu({ rightSidebarToggle, onClick }: CustomSlideMenuProps) {
  return (
    <div
      aria-hidden="true"
      className={`${rightSidebarToggle ? 'translate-x-0' : 'translate-x-full'} top-0 right-0 bottom-0 left-0 bg-black/50 z-50 fixed items-start flex justify-end`}
      onClick={onClick}
    >
      <button className="z-40 top-4 right-4 p-1 bg-white" onClick={onClick}>
        <CloseIcon />
      </button>
      <div
        className={`${rightSidebarToggle ? 'w-80' : 'w-0'} ease-in-out duration-200 bg-bgColor h-full -mt-3 overflow-hidden overflow-y-auto scrollbar`}
        onClick={e => e.stopPropagation()}
      >
        <NotificationList />
      </div>
    </div>
  )
}
