import { useTranslation } from 'react-i18next'
import type { KeyboardEvent } from 'react'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import type { ValueType } from 'rsuite/esm/Checkbox'
import type { MyActionCardRef } from '../../component'
import {
  BorderButton,
  CustomMultiSelectMenu,
  CustomPagination,
  CustomTable,
  CustomText,
  MyActionCard,
  Spinner,
  TextWithIcon,
} from '../../component'
import { CalenderIcon, CloseIcon, RightIcon, SearchIcon } from '../../icons'
import {
  useActionList,
  useEmployeesStateList,
  useManagerFilterList,
  useOrganizationUnits,
} from '../../packages/api-web'
import { UserContext } from '../../contexts/UserContext'

const actionTypes = [
  { actionType: 'Critical', title: 'neededAction', subTitle: 'neededTxt', id: 1 },
  { actionType: 'Recommended', title: 'recommendedAction', subTitle: 'recommendedTxt', id: 2 },
  { actionType: 'Other', title: 'otherAction', subTitle: 'otherTxt', id: 3 },
]

interface DataType {
  title: string
  handle: string
}
interface FilterData {
  id: number
  header: string
  data: any[] | []
  selectedData: ValueType[]
  calenderType: boolean
}

interface SelectionMenuProps {
  text: string
  selectedValue: ValueType[]
  menuList: {
    title: string
    handle: string
  }[]
  onSelectedValue: (value: ValueType[]) => void
  onFilterChange?: (filter: string) => void
  onClearFilter?: () => void
  rightIcon?: React.ReactNode
  type?: boolean
}

const SelectionMenu: React.FC<SelectionMenuProps> = ({
  text,
  selectedValue,
  menuList,
  rightIcon,
  type,
  onSelectedValue,
  onFilterChange,
  onClearFilter,
}) => (
  <div className="relative">
    <CustomMultiSelectMenu
      selectedValue={selectedValue}
      menuList={menuList}
      calender={type}
      onSelectedValue={onSelectedValue}
      onFilterChange={onFilterChange}
      onFilterClear={onClearFilter}
      buttonComponent={(
        <div className="flex items-center">
          <TextWithIcon
            text={text}
            rightIcon={rightIcon ?? <RightIcon className="h-4 w-4 rotate-90 ml-3" />}
            textVariant="smPoppinsRegular"
          />
          {selectedValue.length > 0 && (
            <div className="absolute -top-4 right-0 bg-primary h-4 w-4 rounded-full flex justify-center items-center">
              <CustomText variant="xsmPoppinsRegular" textColor="text-textWhite">
                {type ? '1' : selectedValue.length.toString()}
              </CustomText>
            </div>
          )}
        </div>
      )}
    />
  </div>
)

function MyDay() {
  const { t } = useTranslation('common')
  const { user } = useContext(UserContext)
  const [loading, setLoading] = useState(true)
  const [itemPerPage, setItemPage] = useState(20)
  const [skipItem, setSkipItem] = useState(0)
  const [searchTxt, setSearchTxt] = useState('')
  const [actionSearchTxt, setActionSearchTxt] = useState('')
  const [filterTxt, setFilterTxt] = useState('')
  const [selectedFilter, setSelectedFilter] = useState([
    { actionType: '', title: 'all', id: 4, selected: true },
    { actionType: 'Critical', title: 'neededTxt', id: 1, selected: false },
    { actionType: 'Recommended', title: 'recommendedTxt', id: 2, selected: false },
    { actionType: 'Other', title: 'otherTxt', id: 3, selected: false },
  ])
  const [filterData, setFilterData] = useState<FilterData[]>([
    {
      id: 1,
      header: 'organization',
      data: [],
      selectedData: [],
      calenderType: false,
    },
    {
      id: 2,
      header: 'region',
      data: [],
      selectedData: [],
      calenderType: false,
    },
    {
      id: 4,
      header: 'reportsTo',
      data: [],
      selectedData: [],
      calenderType: false,
    },
    {
      id: 5,
      header: 'actionCreated',
      data: [],
      selectedData: [],
      calenderType: true,
    },
  ])

  const isManager = user.userData.metadata?.HasReportingToMe === 'True' ? user.userData.handle : ''

  const tableHeaderList = useMemo(
    () => [
      { title: t('tableSeverity'), id: 1, key: 'severity' },
      { title: t('tableTitle'), id: 2, key: 'description' },
      { title: t('tableDescription'), id: 3, key: 'additionalData' },
      { title: t('tableUser'), id: 4, key: 'title' },
      { title: t('tableUnit'), id: 5, key: 'unit' },
      { title: t('tableCreatedDate'), id: 6, key: 'date' },
    ],
    [t],
  )

  const cardRefs = actionTypes.map(() => useRef<MyActionCardRef>(null))

  const { data: organizationList, isFetching } = useOrganizationUnits()
  const { data: listEmployeeState, isFetching: isListFetching } = useEmployeesStateList()
  const {
    data: managerList,
    isFetching: isManagerList,
    refetch: refetchManagerList,
  } = useManagerFilterList({ filterTxt })
  const {
    data,
    refetch,
    isFetching: isTableFetching,
  } = useActionList({
    actionType: '',
    searchTxt,
    managerHandles: filterData[2].selectedData,
    organizationUnitHandles: filterData[0].selectedData,
    stateNames: filterData[1].selectedData,
    startDate: filterData[3].selectedData[0]?.toString(),
    endDate: filterData[3].selectedData[1]?.toString(),
  })

  // Function to calculate the working day
  const getWorkingDay = () => {
    const today = new Date()
    const dayOfWeek = today.getDay() // 0: Sunday, 1: Monday, ..., 6: Saturday
    const targetDate = new Date(today)
    // Check if today is Saturday (6), Sunday (0), or Monday (1)
    if (dayOfWeek === 6) {
      // If today is Saturday, set it to the previous Friday (1 day ago)
      targetDate.setDate(today.getDate() - 1)
    }
    else if (dayOfWeek === 0) {
      // If today is Sunday, set it to the previous Friday (2 days ago)
      targetDate.setDate(today.getDate() - 2)
    }
    else if (dayOfWeek === 1) {
      // If today is Monday, set it to the previous Friday (3 days ago)
      targetDate.setDate(today.getDate() - 3)
    }
    else {
      // Otherwise, set the date to yesterday (for any working day)
      targetDate.setDate(today.getDate() - 1)
    }
    // Format the date as YYYY-MM-DD
    return targetDate.toISOString()
  }

  useEffect(() => {
    if (
      !isFetching
      && !isListFetching
      && !isManagerList
      && !isTableFetching
      && organizationList
      && listEmployeeState
      && managerList
      && isManager
    ) {
      setLoading(false)
    }
  }, [
    isFetching,
    isListFetching,
    isManagerList,
    isTableFetching,
    organizationList,
    listEmployeeState,
    managerList,
    isManager,
  ])

  useEffect(() => {
    const newFilter: FilterData[] = [...filterData]
    newFilter[3].selectedData = [getWorkingDay(), getWorkingDay()]
    setFilterData(newFilter)
  }, [])

  useEffect(() => {
    if (user.userData.metadata?.HasReportingToMe == 'True') {
      const newFilter: FilterData[] = [...filterData]
      const handle = isManager ?? ''
      if (!newFilter[2].selectedData.includes(handle)) {
        newFilter[2].selectedData.push(handle)
        setFilterData(newFilter)
      }
    }
  }, [user])

  useEffect(() => {
    if (organizationList || listEmployeeState || managerList || user) {
      const isHandleInManagerList = managerList?.items.some(i => i.handle === user.userData.handle)
      const newFilter: FilterData[] = [...filterData]
      newFilter[0].data = organizationList?.items ?? []
      newFilter[1].data = listEmployeeState?.items ?? []
      newFilter[2].data = isHandleInManagerList
        ? (managerList?.items.map(i => ({ ...i, title: i.displayName })) ?? [])
        : [
            ...(managerList?.items.map(i => ({ ...i, title: i.displayName })) ?? []),
            { handle: user.userData.handle ?? '', title: user.userData.displayName ?? '' },
          ]
      setFilterData(newFilter)
    }
  }, [organizationList, listEmployeeState, managerList, user])

  useEffect(() => {
    if (user && user.userData && user.userData.handle)
      refetch()
  }, [user])

  const actionTableData = useMemo(() => {
    if (!data?.items) {
      return []
    }
    const selectedFilterItem = selectedFilter.find(i => i.selected)
    if (selectedFilterItem && selectedFilterItem.title === 'all') {
      console.log('Returning all data')
      return data.items
    }
    const filterId = selectedFilter.filter(i => i.selected).map(i => i.id)
    const result = data.items.filter((i) => {
      return filterId.includes(i.severity)
    })
    return result
  }, [data, selectedFilter])

  const handleRefetch = () => {
    refetch()
    cardRefs.forEach(ref => ref.current?.refetchData())
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>, refetchFn: () => void) => {
    if (event.key === 'Enter')
      refetchFn()
  }

  const handleFilterChange = (newFilterTxt: string) => {
    setFilterTxt(newFilterTxt)
    setTimeout(() => {
      refetchManagerList()
    }, 500)
  }

  const handleFilterSelect = (index: number) => {
    let newData = [...selectedFilter]
    if (newData[index].title === 'all') {
      newData = newData.map(item => ({ ...item, selected: item.title === 'all' }))
    }
    else {
      newData[index].selected = !newData[index].selected
      newData[0].selected = false
      const areOthersSelected = newData.some((item, idx) => idx !== 0 && item.selected)
      if (!areOthersSelected) {
        newData = newData.map(item => ({ ...item, selected: item.title === 'all' }))
      }
    }
    setSkipItem(0)
    setItemPage(20)
    setSelectedFilter(newData)
  }

  if (loading)
    return <Spinner />

  return (
    <div className="p-5 h-full flex flex-col">
      <CustomText variant="xlPoppinsBold">{t('my_day')}</CustomText>
      <div className="overflow-y-auto scrollbar flex flex-col mt-6">
        <div className="px-5 py-3 bg-white border-stroke border rounded-lg flex items-center justify-between">
          <div className="flex items-center gap-3 flex-wrap">
            {filterData.map((i, index) => (
              <React.Fragment key={index}>
                <SelectionMenu
                  key={i.id}
                  text={t(i.header)}
                  selectedValue={i.selectedData}
                  menuList={i.data}
                  type={i.calenderType}
                  onSelectedValue={(value) => {
                    const updateItem = { ...i, selectedData: value }
                    const newFilter: FilterData[] = [...filterData]
                    newFilter[index] = updateItem
                    setFilterData([...newFilter])
                  }}
                  rightIcon={
                    i.calenderType ? (
                      <CalenderIcon className="h-4 w-4 ml-3 fill-textPrimary" />
                    ) : undefined
                  }
                  onFilterChange={filterTxt => i.id == 4 && handleFilterChange(filterTxt)}
                  onClearFilter={() => {
                    const updateItem = { ...i, selectedData: [] }
                    const newFilter: FilterData[] = [...filterData]
                    newFilter[index] = updateItem
                    setFilterData([...newFilter])
                  }}
                />
                <div className="h-8 bg-black/10 w-[1px]" />
              </React.Fragment>
            ))}
          </div>
          <BorderButton
            children={t('filter')}
            className="self-end w-auto"
            onClick={handleRefetch}
          />
        </div>
        <div className="bg-white p-5 rounded-lg border-stroke border mt-6">
          <div className="flex flex-col sm:flex-row mb-4 gap-4">
            <CustomText wrapText={false} variant="xlPoppinsSemiBold">
              {t('myActions')}
            </CustomText>
            <div className="flex flex-1 items-center justify-between">
              <div className="flex flex-1 items-center w-auto gap-4">
                <SearchIcon />
                <input
                  type="text"
                  value={actionSearchTxt}
                  placeholder={t('searchPlaceholder')}
                  className="outline-0 flex-1 min-w-[100px]"
                  onChange={e => setActionSearchTxt(e.target.value)}
                  onKeyDown={e => handleKeyDown(e, handleRefetch)}
                />
                {actionSearchTxt && (
                  <button onClick={() => setActionSearchTxt('')}>
                    <CloseIcon className="h-5" />
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 items-center gap-5">
            {actionTypes.map((type, index) => (
              <MyActionCard
                key={index}
                ref={cardRefs[index]}
                actionType={type.actionType}
                title={t(type.title)}
                className={`${type.actionType == 'Critical' ? 'border-l-textDanger' : type.actionType == 'Recommended' ? 'border-l-yellow' : 'border-l-grey'}`}
                searchTxt={actionSearchTxt}
                organizationUnitHandles={filterData[0].selectedData}
                stateNames={filterData[1].selectedData}
                managerHandles={filterData[2].selectedData}
                startDate={filterData[3].selectedData[0]?.toString()}
                endDate={filterData[3].selectedData[1]?.toString()}
              />
            ))}
          </div>
        </div>
        <div className="flex flex-1 flex-col mt-6 py-6 px-5 bg-white rounded-lg border-stroke border h-[100%]">
          <div className="flex flex-col sm:flex-row mb-2 gap-2">
            <CustomText className="mr-auto self-center" wrapText={false} variant="xlPoppinsBold">
              {`${t('total')}: ${actionTableData.length ?? 0}`}
            </CustomText>
            <div className="flex flex-1 items-center justify-between">
              <div className="flex items-center w-auto gap-4">
                <SearchIcon className="ml-0 sm:ml-2" />
                <input
                  type="text"
                  value={searchTxt}
                  placeholder={t('searchPlaceholder')}
                  className="outline-0 flex-1 min-w-[100px]"
                  onChange={e => setSearchTxt(e.target.value)}
                  onKeyDown={e => handleKeyDown(e, refetch)}
                />
                {searchTxt && (
                  <button onClick={() => setSearchTxt('')}>
                    <CloseIcon className="h-5" />
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row items-center pb-4">
            <CustomText variant="smPoppinsRegular" className="mb-2 sm:mb-0 mr-auto sm:mr-4">
              {t('filterOn')}
            </CustomText>
            <div className="flex flex-1 items-center mr-auto gap-2">
              {selectedFilter.map((label, index) => (
                <BorderButton
                  key={label.id}
                  children={t(label.title)}
                  className={`!py-1 !px-5 !rounded-full !border-secondary ${label.selected ? 'bg-secondary' : ''}`}
                  textVariant="smPoppinsRegular"
                  textColor={label.selected ? 'text-textWhite' : 'text-textPrimary'}
                  onClick={() => {
                    handleFilterSelect(index)
                  }}
                />
              ))}
              <div />
            </div>
          </div>
          <div className="flex flex-col overflow-hidden rounded-lg border-stroke border">
            <div className="flex-1 overflow-x-auto overflow-y-auto scrollbar bg-white">
              <CustomTable
                tableHeaderList={tableHeaderList}
                isLoading={isTableFetching}
                tableDataList={actionTableData.slice(skipItem, skipItem + itemPerPage)}
                displayKeys={['severity', 'description', 'additionalData', 'title', 'unit', 'date']}
              />
            </div>
            {actionTableData.length > 0 && (
              <CustomPagination
                skip={skipItem}
                totalItem={actionTableData.length}
                itemPerPage={itemPerPage}
                onChange={setItemPage}
                onPagePress={index => setSkipItem((index - 1) * itemPerPage)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyDay
