export function InfoIcon({ className = '' }) {
  return (
    <svg width="32.000000pt" height="32.000000pt" className={className}>
      <g transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)" stroke="none">
        <path d="M95 306 c-101 -44 -125 -178 -46 -257 65 -65 157 -65 222 0 124 124 -15 327 -176 257z m142 -26 c87 -52 83 -198 -6 -245 -38 -19 -104 -19 -142 0 -89 47 -93 193 -6 245 42 26 112 26 154 0z" />
        <path d="M162 224 c4 -21 22 -23 26 -1 2 10 -3 17 -13 17 -10 0 -15 -6 -13 -16z" />
        <path d="M140 180 c-8 -5 -9 -9 -1 -11 7 -3 8 -14 3 -34 -5 -17 -7 -36 -5 -42 5 -15 39 -17 48 -3 3 6 1 10 -5 10 -6 0 -8 14 -4 39 6 40 -9 58 -36 41z" />
      </g>
    </svg>
  )
}
