import { useTranslation } from 'react-i18next'
import { useContext, useEffect, useState } from 'react'
import { CustomTab, CustomText, NewList, Spinner } from '../../component'
import NewDetails from '../NewDetails'
import { useFollowersQuery } from '../../packages/api-web'
import type { Group, News as NewsProps } from '../../packages/api-web'
import { UserContext } from '../../contexts/UserContext'

function News() {
  const { t } = useTranslation('common')
  const { user } = useContext(UserContext)
  const [selectedItem, setSelectedItem] = useState<Group>()
  const [tabListData, setTabListData] = useState<Group[]>([])
  const [selectedNewItem, setSelectedNewItem] = useState<NewsProps>()
  const { isLoading, data: tabData } = useFollowersQuery()

  useEffect(() => {
    if (tabData && tabData?.length)
      setTabListData(tabData)
  }, [tabData])

  if (isLoading)
    return <Spinner />

  return (
    <div className="p-5 h-full flex flex-col">
      <CustomText variant="xlPoppinsBold">{`${t('news')}`}</CustomText>
      <div className="py-5 flex">
        <CustomTab
          tabList={tabListData}
          selectedItem={selectedItem}
          onClick={(i) => {
            if (selectedItem?.handle !== i.handle) {
              setSelectedItem(i)
              setSelectedNewItem(undefined)
            }
          }}
        />
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 flex-1 overflow-hidden">
        <div className="overflow-y-auto scrollbar">
          <NewList
            selectedNewItem={selectedNewItem}
            selectedItem={selectedItem}
            setSelectedNewItem={setSelectedNewItem}
          />
        </div>
        {selectedNewItem && (
          <div className="ml-5 col-span-2 overflow-y-auto relative flex flex-col max-lg:hidden">
            <NewDetails newsHandle={selectedNewItem?.handle} typeHandle={selectedItem?.handle} />
          </div>
        )}
      </div>
    </div>
  )
}

export default News
