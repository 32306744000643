import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ERecommendedActionType } from '@inphiz-shared/shared-models/src/F_RecomededActions/e-recommended-action-type'
import { ERecommendedActionSeverity } from '@inphiz-shared/shared-models/src/F_RecomededActions/e-recommended-action-severity'
import { CustomCircleBar, CustomText } from '../../atoms'
import {
  useCertificateList,
  useEmployeeProgress,
  useRecommendedActionsEntity,
} from '../../../packages/api-web/Employee'
import { RecommendedActionsCongrats, RightIcon } from '../../../icons'
import { EmployeeActionCard } from '../../molecules'

interface Params {
  handle?: string
}

export function EmployeeActions({ handle }: Params) {
  const { t } = useTranslation('common')
  const [criticalItemIndex, setCriticalItemIndex] = useState<number>(0)
  const [recommendedItemIndex, setRecommendedItemIndex] = useState<number>(0)
  const [otherItemIndex, setOtherItemIndex] = useState<number>(0)
  const { data: employeeProgress } = useEmployeeProgress({
    handle,
  })
  const { data: recommendedActionsEntity } = useRecommendedActionsEntity({
    handle,
  })
  const { data: certificateList } = useCertificateList()

  const typesForOtherRecommendedActionsPile: ERecommendedActionType[] = [
    ERecommendedActionType.UserStartDateMissing,
    ERecommendedActionType.UserDidNotPassOnboarding,
    ERecommendedActionType.UserIsNotLoggedIn,
    ERecommendedActionType.UserProfileInfoIsMissing,
  ]

  const otherActions = useMemo(() => {
    const result = recommendedActionsEntity?.items?.filter(
      x => x.type !== undefined && typesForOtherRecommendedActionsPile.includes(x.type),
    )
    return result
  }, [recommendedActionsEntity])

  const criticalOrRecommendedActions = useMemo(() => {
    const result = recommendedActionsEntity?.items?.filter(x => !otherActions?.includes(x))
    return result
  }, [recommendedActionsEntity])

  const criticalActions = useMemo(() => {
    const result = criticalOrRecommendedActions?.filter(
      x =>
        x.type !== undefined
        && (x.type === ERecommendedActionType.UserPolicyIsNotAccepted
          || x.type === ERecommendedActionType.UserCertificateIsExpired
          || x.type === ERecommendedActionType.UserJobTitleRequirementIsMissing
          || x.type === ERecommendedActionType.UserJobRoleRequirementIsMissing
          || certificateList?.items?.some(y => y.title === x.additionalData && y.isCompliance)),
    )
    return result
  }, [recommendedActionsEntity])

  const recommendedActions = useMemo(() => {
    const result = criticalOrRecommendedActions?.filter(x => !criticalActions?.includes(x))
    return result
  }, [recommendedActionsEntity])

  const progressValues = useMemo(() => {
    const result = employeeProgress?.progress?.progressValues
    return result
  }, [employeeProgress])

  const criticalValue = progressValues?.find(i => i?.name == 'Critical')
  const legalValue = progressValues?.find(i => i?.name == 'Legal')
  const generalValue = progressValues?.find(i => i?.name == 'General')

  return (
    <div className="flex-1 border border-stroke rounded-lg">
      <CustomText variant="mdPoppinsSemiBold" className="py-3 px-5 flex-1 border-b border-stroke">
        {t('actions')}
      </CustomText>
      <div className="flex-1 lg:gap-3 lg:py-8 grid grid-cols-1 lg:grid-cols-3 content-start">
        <div className="justify-center items-center flex-1 flex max-lg:py-5">
          <div className="bg-table-odd-bg px-7 py-5 rounded-lg">
            <CustomText variant="xxlPoppinsSemiBold">
              {t('leftCount', {
                count: recommendedActionsEntity?.totalCount ?? 0,
              })}
            </CustomText>
            <CustomText variant="mdPoppinsRegular">{t('finishThis')}</CustomText>
          </div>
        </div>
        <div className="max-lg:border-t max-lg:py-5 border-stroke flex-1 flex relative items-center justify-center">
          <CustomCircleBar
            colour="stroke-purple"
            percentage={criticalValue?.value as number}
            size={230}
          />
          <CustomCircleBar
            colour="stroke-aquaMarine"
            percentage={generalValue?.value as number}
            size={200}
            className="absolute"
          />
          <CustomCircleBar
            colour="stroke-turquoise"
            percentage={legalValue?.value as number}
            size={170}
            className="absolute"
          />
          <CustomText variant="xxlPoppinsSemiBold" className="absolute">
            {`${(((criticalValue?.value as number) + (legalValue?.value as number) + (generalValue?.value as number)) / 3).toFixed()}%`}
          </CustomText>
        </div>
        <div className="max-lg:border-t max-lg:py-5 max-lg:px-5 border-stroke flex-1 gap-5 flex flex-col justify-center">
          <div>
            <div className="flex gap-2">
              <CustomText variant="mdPoppinsSemiBold" className="!text-purple">
                {`${criticalValue?.value?.toFixed()}%`}
              </CustomText>
              <CustomText variant="mdPoppinsSemiBold">
                {`${t('ofTarget')} ${criticalValue?.requiredValue}%`}
              </CustomText>
            </div>
            <CustomText variant="mdPoppinsRegular">{t('requirementTypeSpecialization')}</CustomText>
          </div>
          <div>
            <div className="flex gap-2">
              <CustomText variant="mdPoppinsSemiBold" className="!text-turquoise">
                {`${legalValue?.value?.toFixed()}%`}
              </CustomText>
              <CustomText variant="mdPoppinsSemiBold">
                {`${t('ofTarget')} ${legalValue?.requiredValue}%`}
              </CustomText>
            </div>
            <CustomText variant="mdPoppinsRegular">
              {t('requirementsExistingExperience')}
            </CustomText>
          </div>
          <div>
            <div className="flex gap-2">
              <CustomText variant="mdPoppinsSemiBold" className="!text-aquaMarine">
                {`${generalValue?.value?.toFixed()}%`}
              </CustomText>
              <CustomText variant="mdPoppinsSemiBold">
                {`${t('ofTarget')} ${generalValue?.requiredValue}%`}
              </CustomText>
            </div>
            <CustomText variant="mdPoppinsRegular">{t('basicInformation')}</CustomText>
          </div>
        </div>
      </div>
      <div className="flex-1 lg:gap-3 grid grid-cols-1 lg:grid-cols-3 content-start">
        <div>
          <CustomText
            variant="mdPoppinsSemiBold"
            className="py-5 px-5 flex-1 border-b border-stroke"
          >
            {t('neededAction')}
          </CustomText>
          <div className="flex flex-col items-center gap-5 py-5 px-3">
            {criticalActions && criticalActions?.length > 0 ? (
              <>
                <div>
                  <CustomText variant="smPoppinsRegular">{t('youOnlyHave')}</CustomText>
                  <CustomText variant="lgPoppinsSemiBold">
                    {`${criticalActions?.length} ${t('leftGoal')}`}
                  </CustomText>
                </div>
                <div className="flex w-full items-center justify-between">
                  <button
                    disabled={criticalItemIndex == 0}
                    className={`w-6 h-6 ${criticalItemIndex == 0 && 'opacity-0'}`}
                    onClick={() => {
                      setCriticalItemIndex(criticalItemIndex - 1)
                    }}
                  >
                    <RightIcon className="rotate-180" />
                  </button>
                  <EmployeeActionCard
                    item={criticalActions?.[criticalItemIndex]}
                    total={criticalActions?.length}
                    status={ERecommendedActionSeverity.Critical}
                  />
                  <button
                    disabled={criticalActions && criticalItemIndex == criticalActions?.length - 1}
                    className={`w-6 h-6 ${criticalActions && criticalItemIndex == criticalActions?.length - 1 && 'opacity-0'}`}
                    onClick={() => {
                      setCriticalItemIndex(criticalItemIndex + 1)
                    }}
                  >
                    <RightIcon />
                  </button>
                </div>
                <CustomText variant="smPoppinsRegular">
                  {`${criticalItemIndex + 1}/${criticalActions?.length}`}
                </CustomText>
              </>
            ) : (
              <>
                <div>
                  <CustomText variant="lgPoppinsSemiBold">{`${t('greatWork')}!`}</CustomText>
                  <CustomText variant="smPoppinsRegular">{`${t('allActionsDone')}!`}</CustomText>
                </div>
                <img src={RecommendedActionsCongrats} className="h-32 w-32" />
              </>
            )}
          </div>
        </div>
        <div>
          <CustomText
            variant="mdPoppinsSemiBold"
            className="py-5 px-5 flex-1 border-b border-stroke"
          >
            {t('recommendedAction')}
          </CustomText>
          <div className="flex flex-col items-center gap-5 py-5 px-3">
            {recommendedActions && recommendedActions?.length > 0 ? (
              <>
                <div>
                  <CustomText variant="smPoppinsRegular">{t('youOnlyHave')}</CustomText>
                  <CustomText variant="lgPoppinsSemiBold">
                    {`${recommendedActions?.length} ${t('leftGoal')}`}
                  </CustomText>
                </div>
                <div className="flex w-full items-center justify-between">
                  <button
                    disabled={recommendedItemIndex == 0}
                    className={`w-6 h-6 ${recommendedItemIndex == 0 && 'opacity-0'}`}
                    onClick={() => {
                      setRecommendedItemIndex(recommendedItemIndex - 1)
                    }}
                  >
                    <RightIcon className="rotate-180" />
                  </button>
                  <EmployeeActionCard
                    item={recommendedActions?.[recommendedItemIndex]}
                    total={recommendedActions?.length}
                    status={ERecommendedActionSeverity.Recommended}
                  />
                  <button
                    disabled={
                      recommendedActions && recommendedItemIndex == recommendedActions?.length - 1
                    }
                    className={`w-6 h-6 ${recommendedActions && recommendedItemIndex == recommendedActions?.length - 1 && 'opacity-0'}`}
                    onClick={() => {
                      setRecommendedItemIndex(recommendedItemIndex + 1)
                    }}
                  >
                    <RightIcon />
                  </button>
                </div>
                <CustomText variant="smPoppinsRegular">{`1/${recommendedActions?.length}`}</CustomText>
              </>
            ) : (
              <>
                <div>
                  <CustomText variant="lgPoppinsSemiBold">{`${t('greatWork')}!`}</CustomText>
                  <CustomText variant="smPoppinsRegular">{`${t('allActionsDone')}!`}</CustomText>
                </div>
                <img src={RecommendedActionsCongrats} className="h-32 w-32" />
              </>
            )}
          </div>
        </div>
        <div>
          <CustomText
            variant="mdPoppinsSemiBold"
            className="py-5 px-5 flex-1 border-b border-stroke"
          >
            {t('otherAction')}
          </CustomText>
          <div className="flex flex-col items-center gap-5 py-5 px-3">
            {otherActions && otherActions?.length > 0 ? (
              <>
                <div>
                  <CustomText variant="smPoppinsRegular">{t('youOnlyHave')}</CustomText>
                  <CustomText variant="lgPoppinsSemiBold">
                    {`${otherActions?.length} ${t('leftGoal')}`}
                  </CustomText>
                </div>
                <div className="flex w-full items-center justify-between">
                  <button
                    disabled={otherItemIndex == 0}
                    className={`w-6 h-6 ${otherItemIndex == 0 && 'opacity-0'}`}
                    onClick={() => {
                      setOtherItemIndex(otherItemIndex - 1)
                    }}
                  >
                    <RightIcon className="rotate-180" />
                  </button>
                  <EmployeeActionCard
                    item={otherActions?.[otherItemIndex]}
                    total={otherActions?.length}
                    status={ERecommendedActionSeverity.Other}
                  />
                  <button
                    disabled={otherActions && otherItemIndex == otherActions?.length - 1}
                    className={`w-6 h-6 ${otherActions && otherItemIndex == otherActions?.length - 1 && 'opacity-0'}`}
                    onClick={() => {
                      setOtherItemIndex(otherItemIndex + 1)
                    }}
                  >
                    <RightIcon />
                  </button>
                </div>
                <CustomText variant="smPoppinsRegular">{`1/${otherActions?.length}`}</CustomText>
              </>
            ) : (
              <>
                <div>
                  <CustomText variant="lgPoppinsSemiBold">{`${t('greatWork')}!`}</CustomText>
                  <CustomText variant="smPoppinsRegular">{`${t('allActionsDone')}!`}</CustomText>
                </div>
                <img src={RecommendedActionsCongrats} className="h-32 w-32" />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
