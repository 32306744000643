import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import { APIConst } from '@inphiz/api'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { UserDto } from '@inphiz-shared/shared-models/src/Dto/user-dto'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'

const EquipmentUserParamDto = new UserDto()
type EquipmentUserParam = typeof EquipmentUserParamDto

const EquipmentUserListDto = new DataCollectionApiResponse<EquipmentUserParam>()
type EquipmentUserListParam = typeof EquipmentUserListDto

export function useEquipmentUsers({ filterTxt = '', isEnabled = false }) {
  return useQuery<EquipmentUserListParam>({
    queryKey: [QueryKey.equipmentUser, isEnabled],
    queryFn: async () => {
      const result = await APIFunctions.GET(`${APIConst.usersList}?Filter=${filterTxt}`)
      return {
        ...result,
        items: result.items.map((item: EquipmentUserParam) => {
          return {
            ...item,
            title: item?.displayName,
          }
        }),
      }
    },
    ...(!isEnabled && { staleTime: Number.POSITIVE_INFINITY }),
    enabled: isEnabled,
  })
}
