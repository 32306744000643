import { useTranslation } from 'react-i18next'
import { CustomText, GroupBarChart } from '../../atoms'
import { MenuIcon } from '../../../icons'

interface tabArrayParams {
  color?: string
  title?: string
  info?: string
  subTitle?: string
  rounded?: boolean | undefined
}

export function ControlCenterEcodriving() {
  const { t } = useTranslation('common')
  const barLabel = [
    {
      color: 'bg-chartBlue',
      title: t('fuelConsumption'),
      rounded: true,
    },
    {
      color: 'bg-chartGreen',
      title: `*${t('drivingStyle')}`,
      info: `*${t('ecoDrivingScoreSettings')}.`,
    },
    {
      color: 'bg-chartGreen',
      title: '100-90',
    },
    {
      color: 'bg-chartLightGreen',
      title: '90-75',
    },
    {
      color: 'bg-chartLightYellow',
      title: '75-60',
    },
    {
      color: 'bg-chartYellow',
      title: '60-45',
    },
    {
      color: 'bg-chartOrange',
      title: '45-30',
    },
    {
      color: 'bg-chartRed',
      title: '30-15',
    },
    {
      color: 'bg-chartWine',
      title: '15-0',
    },
  ] as unknown as tabArrayParams[]

  return (
    <div>
      <div className="mt-5 py-5 px-5 bg-white rounded-lg border border-stroke flex flex-col overflow-hidden">
        <div className="flex items-center">
          <CustomText variant="mdPoppinsSemiBold">{t('ecodriving')}</CustomText>
          <button>
            <MenuIcon className="stroke-black ml-2" />
          </button>
        </div>
        <div className="mt-5 grid grid-cols-1 lg:grid-cols-3 gap-5">
          <div className="col-span-2">
            <GroupBarChart
              minYAxis={0}
              maxYAxis={90}
              gapYAxis={10}
              labels={['TOTAL', 'Region Mitt', 'Region Nord', 'Region Syd']}
              datasets={[
                {
                  type: 'line',
                  label: 'Line Dataset',
                  data: [40, 20, 30, 60],
                  showLine: false,
                  pointRadius: 7,
                  pointHoverRadius: 6,
                  pointBackgroundColor: 'rgb(50,200,90)',
                },
                {
                  label: 'Dataset 1',
                  data: [66, 64, 64, 72],
                  backgroundColor: 'rgb(255, 99, 132)',
                  barPercentage: 0.25,
                },
                {
                  label: 'Dataset 2',
                  data: [3, 0, 4, 6],
                  backgroundColor: 'rgb(75, 192, 192)',
                  barPercentage: 0.25,
                },
                {
                  label: 'Dataset 3',
                  data: [27, 27, 32, 21],
                  backgroundColor: 'rgb(53, 162, 235)',
                  barPercentage: 0.25,
                },
              ]}
            />
          </div>
          <div className="flex flex-col gap-3 justify-center">
            {barLabel?.map((item) => {
              return (
                <div>
                  <div className="flex items-center">
                    <div
                      className={`h-5 w-5 mr-2 ${item?.color} ${item?.rounded ? 'rounded-full' : ''}`}
                    />
                    <CustomText variant="mdPoppinsRegular">{item?.title ?? ''}</CustomText>
                  </div>
                  {item?.info && (
                    <CustomText variant="xsmPoppinsRegular" className="my-3 italic">
                      {item?.info ?? ''}
                    </CustomText>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
