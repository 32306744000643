import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import type { MinimizedUserDto } from '@inphiz-shared/shared-models/src/Dto/minimized-user-dto'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { QueryBuilder } from '@inphiz/core-utilities'

const MemberDto = new DataCollectionApiResponse<MinimizedUserDto>()
export type AddMembersData = typeof MemberDto

interface GroupMemberParams {
  groupHandle?: string
  searchTxt?: string
}

export function useAddMemberList({ groupHandle = '', searchTxt = '' }: GroupMemberParams) {
  return useQuery<AddMembersData>({
    queryKey: [QueryKey.addMembersList],
    queryFn: async () => {
      const qb = new QueryBuilder(`${APIConst.membersList}/${groupHandle}`)
        .add('cursor', 0)
        .add('take', 0)
        .add('filter', searchTxt)
        .add('isorderascending', 'False')
        .add('ParametersWithValues[IsSubscribed]', 'False')
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
  })
}
