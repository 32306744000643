import type { ReactNode } from 'react'
import { CustomText } from '../../atoms'
import { StatusValueItem } from '../StatusValueItem'

interface Params {
  title?: string
  subTitle?: string
  subTitleValue?: string
  leftTitle?: string
  leftValue?: string
  leftBottomText?: string
  leftBottomIcon?: ReactNode
  rightTitle?: string
  rightValue?: string
  rightBottomText?: string
  rightBottomIcon?: ReactNode
  className?: string
}
export function ApprovalStatusCard({
  title,
  subTitle,
  subTitleValue,
  leftTitle,
  leftValue,
  leftBottomText,
  leftBottomIcon,
  rightTitle,
  rightValue,
  rightBottomText,
  rightBottomIcon,
  className,
}: Params) {
  return (
    <div className={`flex flex-col rounded-lg border border-stroke ${className}`}>
      {(title || subTitle || subTitleValue) && (
        <div className="flex justify-between p-3 border-b border-stroke">
          {title && <CustomText variant="mdPoppinsSemiBold">{title}</CustomText>}
          <div className="flex">
            {subTitle && <CustomText variant="mdPoppinsRegular">{`${subTitle}: `}</CustomText>}
            {subTitleValue && <CustomText variant="mdPoppinsSemiBold">{subTitleValue}</CustomText>}
          </div>
        </div>
      )}
      <div className="flex flex-1">
        <div className="w-1/2 border-r border-stroke p-5">
          <StatusValueItem
            title={leftTitle}
            value={leftValue}
            bottomText={leftBottomText}
            bottomIcon={leftBottomIcon}
          />
        </div>
        <div className="w-1/2 p-5">
          <StatusValueItem
            title={rightTitle}
            value={rightValue}
            bottomText={rightBottomText}
            bottomIcon={rightBottomIcon}
          />
        </div>
      </div>
    </div>
  )
}
