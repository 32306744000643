import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import type { CertificateTypeDto } from '@inphiz-shared/shared-models/src/F_Certificates/certificate-type-dto'
import moment from 'moment'
import { Statuses } from '@inphiz-shared/shared-models/src/F_Certificates/UserCertificateConstants/statuses'
import { States } from '@inphiz-shared/shared-models/src/F_Certificates/UserCertificateConstants/states'
import { SensitiveDataTypes } from '@inphiz-shared/shared-models/src/SensitiveData/sensitive-data-types'
import { useLocation, useNavigate } from 'react-router-dom'
import { BorderButton, CustomMenu, CustomText, Spinner, TextWithIcon } from '../../atoms'
import {
  AddCertificateEmployee,
  CustomPagination,
  CustomTable,
  SensitiveDataModal,
} from '../../molecules'
import type { CertificateItems } from '../../../packages/api-web/Employee'
import {
  useCertificateItems,
  useCertificateTypes,
  useEmployeeDetails,
  useGetAllPolicy,
  useSensitiveInfo,
} from '../../../packages/api-web/Employee'
import { useEquipmentList } from '../../../packages/api-web/Equipment'
import { CloseIcon, RightIcon } from '../../../icons'
import { UserContext } from '../../../contexts/UserContext'

interface TableItem {
  title?: string
  date?: string
  accepted?: string
}

interface EquipmentTableItem {
  title?: string
  mainCategory?: string
  subCategory?: string
  cost?: string
  signature?: string
  isVisible?: string
  handle?: string
}

interface CertificateTypeItem {
  title?: string
  startDate?: string
  endDate?: string
  file?: string
  status?: string
}

interface SensitiveItem {
  title?: string
  type?: string
  data?: string
  displayData?: string
  date?: string
}
interface TableListItem {
  totalCount?: number
  items?: [TableItem | EquipmentTableItem | CertificateTypeItem | SensitiveItem]
}

interface TableHeaderItem {
  title?: string
  id?: number
}

interface Params {
  handle: string
}

interface CertificateTypeExtra extends CertificateTypeDto {
  expiredCount?: number
  expiringCount?: number
}

export function EmployeeCertificate({ handle }: Params) {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { state } = useLocation()
  const { user } = useContext(UserContext)
  const [itemPerPage, setItemPage] = useState(20)
  const [skipItem, setSkipItem] = useState(0)
  const [tableHeaderList, setTableHeaderList] = useState<TableHeaderItem[]>([])
  const [tableDisplayList, setTableDisplayList] = useState<string[]>([])
  const [tableData, setTableData] = useState<TableListItem>({})
  const [selectedTypeHandle, setSelectedTypeHandle] = useState<string>('policies')
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null)
  const [isAddCertificate, setIsAddCertificate] = useState<boolean>(false)
  const [isSensitive, setIsSensitive] = useState<boolean>(false)
  const [selectedCertificateItem, setSelectedCertificateItem] = useState<CertificateItems>()
  const [selectedGDPR, setSelectedGDPR] = useState<SensitiveItem>()
  const { data: certificateTypes } = useCertificateTypes()
  const { data: policyData, refetch: policyDataRefetch } = useGetAllPolicy()
  const { data: employeeItem } = useEmployeeDetails({
    handle,
  })
  const {
    isFetching: isEquipmentListLoad,
    data: equipmentTypes,
    refetch,
  } = useEquipmentList({
    itemPerPage,
    skip: skipItem,
    filterData: [{}, { selectedData: handle as any }],
  })
  const { data: certificateItems, refetch: certificateItemsRefetch } = useCertificateItems({
    handle,
    certificateTypeHandle: !selectedTypeHandle.includes('certificate-type')
      ? undefined
      : selectedTypeHandle,
    ...(selectedTypeHandle.includes('certificate-type') && { itemPerPage }),
    skip: skipItem,
    status: selectedStatus || undefined,
  })
  const { data: sensitiveData, refetch: sensitiveRefetch } = useSensitiveInfo(handle)

  const certificateTypesList = useMemo(() => {
    const typeArray = certificateTypes?.items?.map((i) => {
      const expired = certificateItems?.items?.filter(
        j => j?.certificateTypeHandle == i?.handle && j.state?.title == States.expired,
      )
      const expiring = certificateItems?.items?.filter(
        j => j?.certificateTypeHandle == i?.handle && j.state?.title == States.expiring,
      )
      return {
        ...i,
        ...(expired && expired?.length > 0 && { expiredCount: expired?.length }),
        ...(expiring && expiring?.length > 0 && { expiringCount: expiring?.length }),
      }
    }) as CertificateTypeDto[]
    const result = typeArray
    if (!result?.some(i => i?.handle == 'policies')) {
      result?.unshift({ title: 'Policies', handle: 'policies' })
    }
    if (!result?.some(i => i?.handle == 'equipment')) {
      result?.push({ title: 'Equipment', handle: 'equipment' })
    }
    if (handle == user?.userData?.handle && !result?.some(i => i?.handle == 'gdpr')) {
      result?.push({ title: 'GDPR-data', handle: 'gdpr' })
    }
    return result
  }, [certificateTypes, user])

  useEffect(() => {
    if (selectedTypeHandle === 'policies') {
      policyDataRefetch()
    }
    else if (selectedTypeHandle === 'equipment') {
      refetch()
    }
    else if (selectedTypeHandle.includes('certificate-type')) {
      certificateItemsRefetch()
    }
    else if (selectedTypeHandle === 'gdpr') {
      sensitiveRefetch()
    }
  }, [skipItem, itemPerPage, selectedTypeHandle, selectedStatus])

  useEffect(() => {
    if (selectedTypeHandle === 'policies') {
      const data: TableListItem = {
        totalCount: policyData?.items?.length,
        items: policyData?.items
          ?.filter(i =>
            employeeItem?.user?.userPolicyInfo?.items?.some(j => j?.policyHandle === i?.handle),
          )
          ?.map((i) => {
            const matchedPolicy = employeeItem?.user?.userPolicyInfo?.items?.find(
              j => j?.policyHandle === i?.handle,
            )

            return {
              title: i?.title,
              accepted:
                moment(matchedPolicy?.date).format('MM-DD-yyyy') === '01-01-0001' ? 'No' : 'Yes',
              date:
                moment(matchedPolicy?.date).format('MM-DD-yyyy') === '01-01-0001'
                  ? '-'
                  : moment(matchedPolicy?.date).format('MM-DD-yyyy') || '-',
            }
          }) as [TableItem],
      }
      setTableData(data)
      setTableHeaderList([
        { title: t('title'), id: 1 },
        { title: t('date'), id: 2 },
        { title: t('accepted'), id: 3 },
      ])
      setTableDisplayList(['title', 'date', 'accepted'])
    }
    else if (selectedTypeHandle === 'equipment') {
      const data: TableListItem = {
        totalCount: equipmentTypes?.totalCount,
        items: equipmentTypes?.items?.map((i) => {
          return {
            title: i?.title,
            mainCategory: i?.equipmentCategory?.parentEquipmentCategory?.title,
            subCategory: i?.equipmentCategory?.title,
            cost: i?.price,
            signature: i?.requiresSign,
            isVisible: i?.isVisible,
            handle: i?.handle,
          }
        }) as [EquipmentTableItem],
      }
      setTableData(data)
      setTableHeaderList([
        { title: t('title'), id: 1 },
        { title: t('mainCategory'), id: 2 },
        { title: t('subCategory'), id: 3 },
        { title: t('costSEK'), id: 4 },
        { title: t('requiresSignature'), id: 5 },
        { title: t('isVisible'), id: 6 },
      ])
      setTableDisplayList([
        'title',
        'mainCategory',
        'subCategory',
        'cost',
        'signature',
        'isVisible',
      ])
    }
    else if (selectedTypeHandle === 'gdpr') {
      const data: TableListItem = {
        totalCount: sensitiveData?.data?.length,
        items: sensitiveData?.data?.map((i) => {
          const emergencyContacts = i?.type === SensitiveDataTypes?.emergencyContacts
          const contactsArray = emergencyContacts
            ? `${t('contactsCount')}: ${JSON.parse(i?.data as any)?.length}`
            : i?.data
          return {
            type: i?.type,
            title:
              i?.type == SensitiveDataTypes.socialSecurityNumber
                ? t('personalIdentityNumber')
                : t('emergencyContacts'),
            data: emergencyContacts ? JSON.parse(i?.data as any) : i?.data,
            displayData: contactsArray,
            date: '-',
          }
        }) as [SensitiveItem],
      }
      setTableData(data)
      setTableHeaderList([
        { title: t('type'), id: 1 },
        { title: 'Data', id: 2 },
        { title: t('createdDate'), id: 3 },
      ])
      setTableDisplayList(['title', 'displayData', 'date'])
    }
    else {
      const data: TableListItem = {
        totalCount: certificateItems?.totalCount,
        items: certificateItems?.items?.map((i) => {
          return {
            ...i,
            title: i?.title,
            startDate: moment(i?.startDate).format('MM-DD-yyyy'),
            endDate: i?.endDate ? moment(i?.endDate).format('MM-DD-yyyy') : null,
            file: i?.mediaItemHandles && i?.mediaItemHandles?.length > 0 ? 'Yes' : 'No',
            status: i?.status?.name,
          }
        }) as [CertificateTypeItem],
      }
      setTableData(data)
      setTableHeaderList([
        { title: t('title'), id: 1 },
        { title: t('startDate'), id: 2 },
        { title: t('endDate'), id: 3 },
        { title: t('files'), id: 4 },
        { title: t('status'), id: 5 },
      ])
      setTableDisplayList(['title', 'startDate', 'endDate', 'file', 'status'])
    }
  }, [
    t,
    employeeItem,
    policyData,
    equipmentTypes,
    certificateItems,
    sensitiveData,
    selectedTypeHandle,
  ])

  return (
    <>
      {isEquipmentListLoad && <Spinner />}
      {isAddCertificate && (
        <AddCertificateEmployee
          userHandle={handle}
          handle={selectedTypeHandle}
          onCloseAlert={() => {
            setIsAddCertificate(false)
          }}
          selectedItem={selectedCertificateItem}
        />
      )}
      {isSensitive && (
        <SensitiveDataModal
          userHandle={handle}
          data={sensitiveData?.data}
          selectedData={selectedGDPR}
          onCloseAlert={() => {
            setSelectedGDPR(undefined)
            sensitiveRefetch()
            setIsSensitive(false)
          }}
        />
      )}
      <CustomText variant="xlPoppinsSemiBold" className="flex-1">
        {t('certificatesAuthorizations')}
      </CustomText>
      <div className="flex mt-5 flex-wrap">
        {certificateTypesList?.map((item: CertificateTypeExtra) => {
          return (
            <div className="flex relative">
              <CustomText
                variant="mdPoppinsSemiBold"
                className={`mt-2 mr-1 px-5 py-2 ${item?.handle == selectedTypeHandle ? 'bg-missing' : ''} rounded-full hover:bg-missing`}
                onClick={() => {
                  setSkipItem(0)
                  setItemPage(20)
                  setSelectedTypeHandle(item?.handle ?? '')
                }}
              >
                {item?.title ?? ''}
              </CustomText>
              <div className="absolute right-2 flex">
                {item?.expiredCount && (
                  <div className="h-4 w-4 rounded-full content-center text-center bg-textDanger">
                    <CustomText variant="xsmPoppinsSemiBold" textColor="text-textWhite">
                      {`${item?.expiredCount}`}
                    </CustomText>
                  </div>
                )}
                {item?.expiringCount && (
                  <div className="h-4 w-4 rounded-full content-center text-center bg-yellow">
                    <CustomText variant="xsmPoppinsSemiBold" textColor="text-textWhite">
                      {`${item?.expiringCount}`}
                    </CustomText>
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>
      {selectedTypeHandle.includes('certificate-type') && (
        <div className="mt-3 flex h-9 justify-between">
          <BorderButton
            disabled={state?.isShowDeleted}
            children={`+ ${t('createNew')}`}
            className={`px-7 ${state?.isShowDeleted ? 'opacity-50' : 'opacity-100'}`}
            onClick={() => {
              setSelectedCertificateItem(undefined)
              setIsAddCertificate(true)
            }}
          />
          <CustomMenu
            menuList={[
              { title: Statuses.approved, handle: Statuses.approved },
              { title: Statuses.pending, handle: Statuses.pending },
              { title: Statuses.archived, handle: Statuses.archived },
              { title: 'Deleted', handle: 'Deleted' },
            ]}
            selectedValue={selectedStatus as null}
            onClick={(value) => {
              setSelectedStatus(value?.handle)
            }}
            buttonComponent={(
              <div className="flex">
                <TextWithIcon
                  text="Status"
                  rightIcon={<RightIcon className="h-4 w-4 rotate-90 ml-4" />}
                  textVariant="smPoppinsRegular"
                />
                {selectedStatus && (
                  <>
                    <div className="absolute -top-4 right-1 bg-primary h-4 w-4 rounded-full justify-center items-center flex">
                      <CustomText variant="xsmPoppinsRegular" textColor="text-textWhite">
                        1
                      </CustomText>
                    </div>
                    <button
                      onClick={() => {
                        setSelectedStatus(null)
                      }}
                    >
                      <CloseIcon className="h-5" />
                    </button>
                  </>
                )}
              </div>
            )}
          />
        </div>
      )}
      {selectedTypeHandle == 'gdpr' && (
        <BorderButton
          children={`+ ${t('createNew')}`}
          className="px-7 mt-3"
          onClick={() => {
            setIsSensitive(true)
          }}
        />
      )}
      <div className="mt-5 flex flex-col overflow-hidden rounded-lg border border-stroke">
        <div className="flex-1 overflow-x-auto overflow-y-auto scrollbar bg-white">
          <CustomTable
            tableHeaderList={tableHeaderList}
            tableDataList={tableData?.items}
            displayKeys={tableDisplayList}
            onClick={(item) => {
              if (selectedTypeHandle.includes('certificate-type')) {
                setSelectedCertificateItem(item)
                setIsAddCertificate(true)
              }
              else if (selectedTypeHandle == 'gdpr') {
                setSelectedGDPR(item)
                setIsSensitive(true)
              }
              else if (selectedTypeHandle === 'equipment') {
                navigate(`/equipment/${item.handle}`)
              }
            }}
          />
        </div>
        {tableData?.items && tableData?.items?.length > 0 && (
          <CustomPagination
            totalItem={tableData?.totalCount}
            itemPerPage={itemPerPage}
            onChange={setItemPage}
            onPagePress={index => setSkipItem((index - 1) * itemPerPage)}
          />
        )}
      </div>
    </>
  )
}
