import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import type { AssistantConversationDto } from '@inphiz-shared/shared-models/src/F_AiAssistant/index'
import { AssistantResponseDto } from '@inphiz-shared/shared-models/src/F_AiAssistant/index'

export function usePostMessage() {
  return useMutation({
    mutationKey: [QueryKey.postAIMessage],
    mutationFn: async (data: AssistantConversationDto) => {
      const result = new AssistantResponseDto(
        await APIFunctions.POST(APIConst.aiAssistant.assistantChat, data),
      )
      return result
    },
  })
}
