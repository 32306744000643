import type { ReactNode } from 'react'
import { CustomText } from '../../atoms'

interface Params {
  title?: string
  value?: string
  bottomText?: string
  bottomIcon?: ReactNode
}
export function StatusValueItem({ title, value, bottomText, bottomIcon }: Params) {
  return (
    <div className="flex flex-col gap-5">
      {title && <CustomText variant="mdPoppinsRegular">{title}</CustomText>}
      {value && <CustomText variant="lgPoppinsSemiBold">{value}</CustomText>}
      <div className="flex">
        {bottomText && <CustomText variant="mdPoppinsRegular">{bottomText}</CustomText>}
        {bottomIcon && bottomIcon}
      </div>
    </div>
  )
}
