import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { EPeopleRelationshipType } from '@inphiz-shared/shared-models/src/SensitiveData/e-people-relationship-type'
import { SensitiveDataTypes } from '@inphiz-shared/shared-models/src/SensitiveData/sensitive-data-types'
import type { EmergencyContactDto } from '@inphiz-shared/shared-models/src/SensitiveData/emergency-contact-dto'
import { CloseIcon, PhoneIcon } from '../../../icons'
import {
  CustomAlert,
  CustomButton,
  CustomDropDown,
  CustomInput,
  CustomText,
  Spinner,
} from '../../atoms'
import type { SensitiveItem } from '../../../packages/api-web/Employee'
import {
  useDeleteSSN,
  usePostEmergencyNumber,
  usePostSSN,
} from '../../../packages/api-web/Employee'
import { formatTextInput } from '../../../helpers'
import { DeleteAlert } from '../DeleteAlert'

interface GroupMembersProps {
  userHandle?: string
  onCloseAlert?: () => void
  data?: SensitiveItem[]
  selectedData?: SensitiveItem
}

interface Relationship {
  title?: string
  handle?: number | string
}

export function SensitiveDataModal({
  userHandle,
  onCloseAlert,
  data,
  selectedData,
}: GroupMembersProps) {
  const { t } = useTranslation('common')
  const [personalNumber, setPersonalNumber] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [businessNumber, setBusinessNumber] = useState('')
  const [relationshipArray, setRelationshipArray] = useState<Relationship[]>([])
  const [selectedRelationship, setSelectedRelationship] = useState<Relationship>()
  const [typeArray, setTypeArray] = useState<Relationship[]>([])
  const [selectedType, setSelectedType] = useState<Relationship>()
  const [errorString, setErrorString] = useState<string[]>([])
  const [emergencyNumberArray, setEmergencyNumberArray] = useState<any[]>([])
  const [isDelete, setIsDelete] = useState<boolean>(false)
  const [selectedUserIndex, setSelectedUserIndex] = useState<number>()
  const { isPending: isLoadSSN, mutate: postSSNMutate } = usePostSSN()
  const { isPending: isLoadEmergency, mutate: postEmergencyMutate } = usePostEmergencyNumber()
  const { isPending: isLoadDeleteSSN, mutate: deleteSSNMutate } = useDeleteSSN()

  useEffect(() => {
    const relationshipArray = Object.entries(EPeopleRelationshipType)
      .filter(([key, value]) => typeof value === 'number')
      .map(([key, value]) => ({ title: key, handle: value })) as Relationship[]
    setRelationshipArray(relationshipArray)
    setSelectedRelationship(relationshipArray[0])
    if (selectedData) {
      if (selectedData?.type == SensitiveDataTypes.socialSecurityNumber) {
        setTypeArray([
          { title: t('personalIdentityNumber'), handle: SensitiveDataTypes.socialSecurityNumber },
        ])
        setSelectedType({
          title: t('personalIdentityNumber'),
          handle: SensitiveDataTypes.socialSecurityNumber,
        })
        setPersonalNumber(selectedData?.data ?? '')
      }
      else {
        setTypeArray([
          { title: t('emergencyContacts'), handle: SensitiveDataTypes.emergencyContacts },
        ])
        setSelectedType({
          title: t('emergencyContacts'),
          handle: SensitiveDataTypes.emergencyContacts,
        })
        setEmergencyNumberArray(selectedData?.data as any)
      }
    }
    else {
      if (data && data?.length > 0) {
        let array = []
        const hasEmergencyContacts = data.some(
          item => item.type === SensitiveDataTypes.emergencyContacts,
        )
        const hasSSN = data.some(item => item.type === SensitiveDataTypes.socialSecurityNumber)
        if (!hasEmergencyContacts) {
          array.push({
            title: t('emergencyContacts'),
            handle: SensitiveDataTypes.emergencyContacts,
          })
          setSelectedType({
            title: t('emergencyContacts'),
            handle: SensitiveDataTypes.emergencyContacts,
          })
        }
        if (!hasSSN) {
          array.push({
            title: t('personalIdentityNumber'),
            handle: SensitiveDataTypes.socialSecurityNumber,
          })
          setSelectedType({
            title: t('personalIdentityNumber'),
            handle: SensitiveDataTypes.socialSecurityNumber,
          })
        }
        if (hasEmergencyContacts && hasSSN) {
          array = []
        }
        setTypeArray(array)
      }
      else {
        setTypeArray([
          { title: t('personalIdentityNumber'), handle: SensitiveDataTypes.socialSecurityNumber },
          { title: t('emergencyContacts'), handle: SensitiveDataTypes.emergencyContacts },
        ])
        setSelectedType({
          title: t('personalIdentityNumber'),
          handle: SensitiveDataTypes.socialSecurityNumber,
        })
      }
    }
  }, [data, selectedData])

  const calculate = () => {
    const as = /^\d{8}[-+]\d{4}$/
    if (as.test(personalNumber)) {
      const month = Number(personalNumber.substr(4, 2))
      const day = Number(personalNumber.substr(6, 2))
      if (month <= 12 && day <= 31)
        return true
    }
    return false
  }

  const onTapSave = () => {
    if (
      selectedType?.handle == SensitiveDataTypes.socialSecurityNumber
      && personalNumber
      && calculate()
    ) {
      const data = {
        userHandle,
        socialSecurityNumber: personalNumber,
      }
      postSSNMutate(data, {
        onSuccess: () => {
          onCloseAlert?.()
        },
      })
    }
    else if (
      selectedType?.handle == SensitiveDataTypes.emergencyContacts
      && firstName
      && businessNumber
    ) {
      const tempArray: any[] = [...emergencyNumberArray]
      if (selectedUserIndex !== undefined && selectedUserIndex >= 0) {
        tempArray.splice(selectedUserIndex, 1)
      }
      const data = {
        userHandle,
        contacts: [
          ...tempArray,
          {
            firstName: firstName ?? '',
            lastName: lastName ?? '',
            phoneNumber: businessNumber ?? '',
            relationshipType: selectedRelationship?.handle as number,
          },
        ] as EmergencyContactDto[],
      }
      postEmergencyMutate(data, {
        onSuccess: () => {
          onCloseAlert?.()
        },
      })
    }
    else {
      const errors = []
      if (selectedType?.handle == SensitiveDataTypes.socialSecurityNumber && !calculate())
        errors.push(t('invalidFormat'))
      if (selectedType?.handle == SensitiveDataTypes.emergencyContacts && !firstName)
        errors.push(t('firstNameRequired'))
      if (selectedType?.handle == SensitiveDataTypes.emergencyContacts && !businessNumber)
        errors.push(t('businessMobileRequired'))
      setErrorString(errors)
    }
  }

  return (
    <CustomAlert
      onClose={() => {
        onCloseAlert?.()
      }}
    >
      {(isLoadSSN || isLoadEmergency || isLoadDeleteSSN) && <Spinner />}
      {isDelete && (
        <DeleteAlert
          title={t('delete')}
          subTitle={t('deleteCampaign')}
          leftButtonText={t('cancel')}
          rightButtonText={t('delete')}
          onCloseAlert={() => {
            setIsDelete(false)
          }}
          onLeftButton={() => {
            setIsDelete(false)
          }}
          onRightButton={() => {
            if (emergencyNumberArray?.length > 1) {
              const tempArray: any[] = [...emergencyNumberArray]
              if (selectedUserIndex !== undefined && selectedUserIndex >= 0) {
                tempArray.splice(selectedUserIndex, 1)
              }
              const data = {
                userHandle,
                contacts: [...tempArray] as EmergencyContactDto[],
              }
              postEmergencyMutate(data, {
                onSuccess: () => {
                  onCloseAlert?.()
                },
              })
            }
            else {
              const data = {
                type:
                  selectedData?.type == SensitiveDataTypes.socialSecurityNumber
                    ? SensitiveDataTypes.socialSecurityNumber
                    : SensitiveDataTypes.emergencyContacts,
                ownerHandle: userHandle,
              }
              deleteSSNMutate(data, {
                onSuccess: () => {
                  setIsDelete(false)
                  onCloseAlert?.()
                },
              })
            }
          }}
        />
      )}
      <div className="flex items-center justify-center">
        <CustomText variant="xlPoppinsSemiBold" className="flex-1">
          {t('gdprData')}
        </CustomText>
        <button onClick={() => onCloseAlert?.()}>
          <CloseIcon />
        </button>
      </div>
      {typeArray?.length > 0 || selectedData ? (
        <div className="mt-5 px-1 flex flex-col text-left gap-4 py-5 border-y max-h-96 overflow-hidden overflow-y-auto scrollbar border-stroke">
          <CustomDropDown
            value={selectedType?.handle}
            title={t('type')}
            data={typeArray}
            labelField="title"
            valueField="handle"
            className="!mb-2"
            onSelect={(_, item) => {
              setSelectedType(item as Relationship)
              setErrorString([])
            }}
          />
          {selectedType?.handle == SensitiveDataTypes.socialSecurityNumber ? (
            <CustomInput
              title={t('personalIdentityNumber')}
              className={`mt-[5px] ${selectedType?.handle == SensitiveDataTypes.socialSecurityNumber && !personalNumber && errorString.length > 0 ? 'outline-textDanger' : ''}`}
              value={personalNumber}
              placeholder="YYYYMMDD-XXXX"
              onChange={(e) => {
                const formattedText = formatTextInput(e.target.value, personalNumber)
                setPersonalNumber(formattedText)
              }}
              maxLength={13}
            />
          ) : (
            <>
              {emergencyNumberArray?.length > 0
              && emergencyNumberArray?.map((i: any, index: number) => {
                return (
                  <div
                    className="flex items-center gap-2"
                    onClick={() => {
                      setFirstName(emergencyNumberArray[index]?.FirstName ?? '')
                      setLastName(emergencyNumberArray[index]?.LastName ?? '')
                      setBusinessNumber(emergencyNumberArray[index]?.PhoneNumber ?? '')
                      const filter = relationshipArray?.filter(
                        i => i?.handle == emergencyNumberArray[index]?.RelationshipType,
                      )
                      setSelectedRelationship(filter?.length > 0 ? filter[0] : undefined)
                      setSelectedUserIndex(index)
                    }}
                  >
                    <PhoneIcon className="h-4 w-4 fill-grey" />
                    <CustomText variant="smPoppinsRegular">{i?.PhoneNumber as string}</CustomText>
                    <CustomText variant="smPoppinsRegular">{i?.FirstName as string}</CustomText>
                    <CustomText variant="smPoppinsRegular">{i?.LastName as string}</CustomText>
                    <CustomText variant="smPoppinsRegular">
                      {`(${EPeopleRelationshipType[i?.RelationshipType]})`}
                    </CustomText>
                    <button
                      onClick={() => {
                        setSelectedUserIndex(index)
                        setIsDelete(true)
                      }}
                    >
                      <CloseIcon />
                    </button>
                  </div>
                )
              })}
              <CustomDropDown
                value={selectedRelationship?.handle}
                title={t('relationship')}
                data={relationshipArray}
                labelField="title"
                valueField="handle"
                className="!mb-2"
                onSelect={(_, item) => {
                  setSelectedRelationship(item as Relationship)
                }}
              />
              <CustomInput
                title={t('firstName')}
                className={`mt-[5px] ${selectedType?.handle == SensitiveDataTypes.emergencyContacts && !firstName && errorString.length > 0 ? 'outline-textDanger' : ''}`}
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value)
                }}
              />
              <CustomInput
                title={t('lastName')}
                className="mt-[5px]"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value)
                }}
              />
              <CustomInput
                title={t('businessMobile')}
                className={`mt-[5px] ${selectedType?.handle == SensitiveDataTypes.emergencyContacts && !businessNumber && errorString.length > 0 ? 'outline-textDanger' : ''}`}
                value={businessNumber}
                onChange={(e) => {
                  const inputValue = e.target.value
                  if (!inputValue.startsWith('+')) {
                    setBusinessNumber(`+${inputValue.replace(/^\+/, '')}`) // Remove any stray '+' and add one
                  }
                  else {
                    setBusinessNumber(inputValue)
                  }
                }}
                maxLength={17}
              />
            </>
          )}
          {errorString.length > 0 && (
            <ul className="list-disc pl-4">
              {errorString.map((i, index) => (
                <li key={index} className="text-textDanger">
                  {i}
                </li>
              ))}
            </ul>
          )}
        </div>
      ) : (
        <CustomText variant="mdPoppinsRegular" className="flex-1 my-5">
          {`${t('noRecords')}...`}
        </CustomText>
      )}
      <div className="flex justify-end mt-5 gap-3">
        {typeArray?.length > 0
        && (selectedType?.handle == SensitiveDataTypes.socialSecurityNumber
          ? (
              <>
                {selectedData && (
                  <CustomButton
                    children={t('delete')}
                    backgroundColor="bg-primary"
                    textColor="text-textWhite"
                    className="!px-5"
                    onClick={() => {
                      setIsDelete(true)
                    }}
                  />
                )}
                <CustomButton
                  children={t('save')}
                  backgroundColor="bg-secondary"
                  textColor="text-textWhite"
                  className="!px-5"
                  onClick={onTapSave}
                />
              </>
            )
          : (
              <CustomButton
                children={
                  selectedUserIndex !== undefined && selectedUserIndex >= 0 ? t('edit') : t('add')
                }
                backgroundColor="bg-secondary"
                textColor="text-textWhite"
                className="!px-5"
                onClick={onTapSave}
              />
            ))}
        <CustomButton
          children={t('cancel')}
          backgroundColor="bg-bthDisable"
          textColor="text-textPrimary"
          onClick={() => {
            onCloseAlert?.()
          }}
          className="!px-5"
        />
      </div>
    </CustomAlert>
  )
}
