import { useTranslation } from 'react-i18next'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CustomButton, CustomPagination, CustomTable, CustomText, Spinner } from '../../component'
import { useManagementUser } from '../../packages/api-web'

export interface filterItem {
  id: number
  header: string
  data: any[]
  selectedData: null
  isSearch?: boolean
}

function SettingUsers() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const [itemPerPage, setItemPage] = useState(20)
  const [skipItem, setSkipItem] = useState(0)
  const { isLoading, data, refetch } = useManagementUser({
    itemPerPage,
    skip: skipItem,
  })

  const tableHeaderList = useMemo(
    () => [
      { title: `${t('nameUser')}?`, id: 1, key: 'displayName' },
      { title: t('email').toLowerCase(), id: 2, key: 'email' },
      { title: t('roleAccess'), id: 3, key: '' },
    ],
    [t],
  )

  useEffect(() => {
    refetch()
  }, [skipItem, itemPerPage])

  if (isLoading)
    return <Spinner />

  return (
    <div className="p-5 h-full flex flex-col">
      <CustomText variant="xlPoppinsBold">{`${t('settings')} ${t('users')}`}</CustomText>
      <div className="mt-6 py-6 px-5 bg-white rounded-lg border border-stroke flex flex-col overflow-hidden">
        <div className="flex flex-col sm:flex-row mb-4 gap-4">
          <CustomText className="mr-auto self-center" wrapText={false} variant="xlPoppinsBold">
            {`${t('total')}: ${data?.totalCount ?? 0}`}
          </CustomText>
          <CustomButton
            children={t('create')}
            textVariant="smPoppinsMedium"
            className="px-4 sm:px-8 bg-secondary"
            onClick={() => {
              navigate('/settings/users/create')
            }}
          />
        </div>
        <div className="flex flex-col overflow-hidden rounded-lg border border-stroke">
          <div className="flex-1 overflow-x-auto overflow-y-auto scrollbar bg-white">
            <CustomTable
              tableHeaderList={tableHeaderList}
              tableDataList={data?.items}
              displayKeys={['displayName', 'email', 'roles']}
              keyMapping={{
                roles: ['displayName'],
              }}
              onClick={(item) => {}}
            />
          </div>
          {data && data?.items && data?.items?.length > 0 && (
            <CustomPagination
              totalItem={data?.totalCount}
              itemPerPage={itemPerPage}
              onChange={setItemPage}
              onPagePress={index => setSkipItem((index - 1) * itemPerPage)}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default SettingUsers
