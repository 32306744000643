import type { ValueType } from 'rsuite/esm/Checkbox'
import React, { useState } from 'react'
import { Pie } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import {
  BorderButton,
  CustomMultiSelectMenu,
  CustomText,
  GroupBarChart,
  LineChart,
  TextWithIcon,
} from '../../component'
import { RightIcon } from '../../icons'

interface FilterData {
  id: number
  header: string
  data: any[] | []
  selectedData: ValueType[]
}

interface tabArrayParams {
  color?: string
  title?: string
  info?: string
  subTitle?: string
  rounded?: boolean | undefined
}

function ControlCenterAnalysis() {
  const { t } = useTranslation('common')
  const [filterData, setFilterData] = useState<FilterData[]>([
    {
      id: 1,
      header: 'Organization',
      data: [],
      selectedData: [],
    },
    {
      id: 2,
      header: 'Work Area',
      data: [],
      selectedData: [],
    },
    {
      id: 4,
      header: 'Manager',
      data: [],
      selectedData: [],
    },
    {
      id: 4,
      header: 'Certificates',
      data: [],
      selectedData: [],
    },
  ])

  const barLabel = [
    {
      color: 'bg-chartBlue',
      title: 'Första hjälpen -LABC',
    },
    {
      color: 'bg-chartGreen',
      title: 'RPA - Dashboard',
    },
    {
      color: 'bg-chartGreen',
      title: 'Models refactoring',
    },
  ] as unknown as tabArrayParams[]

  const pieLabel = [
    {
      color: 'bg-chartBlue',
      title: 'Environmental worker',
    },
    {
      color: 'bg-chartGreen',
      title: 'API Developer',
    },
  ] as unknown as tabArrayParams[]

  const processLabel = [
    {
      color: 'bg-chartBlue',
      title: 'Employee',
    },
    {
      color: 'bg-chartRed',
      title: 'Manager',
    },
  ] as unknown as tabArrayParams[]

  return (
    <div className="p-5 h-full flex flex-col">
      <CustomText variant="xlPoppinsBold">{t('predictiveAnalytics')}</CustomText>
      <div className="overflow-y-auto scrollbar flex flex-col mt-6">
        {/* Filter view */}
        <div className="mt-6 px-5 py-3 bg-white border-stroke border rounded-lg flex items-center justify-between">
          <div className="flex items-center gap-3 flex-wrap">
            {filterData.map((item, index) => (
              <React.Fragment key={index}>
                <CustomMultiSelectMenu
                  selectedValue={item?.selectedData}
                  menuList={item.data}
                  onSelectedValue={(value) => {
                    const updateItem = { ...item, selectedData: value }
                    const newFilter: FilterData[] = [...filterData]
                    newFilter[index] = updateItem
                    setFilterData([...newFilter])
                  }}
                  onFilterClear={() => {
                    const updateItem = { ...item, selectedData: [] }
                    const newFilter: FilterData[] = [...filterData]
                    newFilter[index] = updateItem
                    setFilterData([...newFilter])
                  }}
                  buttonComponent={(
                    <div className="flex items-center">
                      <TextWithIcon
                        text={item.header}
                        rightIcon={<RightIcon className="h-4 w-4 rotate-90 ml-3" />}
                        textVariant="smPoppinsRegular"
                      />
                      {item?.selectedData?.length > 0 && (
                        <div className="absolute -top-4 right-0 bg-primary h-4 w-4 rounded-full flex justify-center items-center">
                          <CustomText variant="xsmPoppinsRegular" textColor="text-textWhite">
                            {item?.selectedData.length.toString()}
                          </CustomText>
                        </div>
                      )}
                    </div>
                  )}
                />
                <div className="h-8 bg-black/10 w-[1px]" />
              </React.Fragment>
            ))}
          </div>
          <BorderButton children={t('filter')} className="self-end w-auto" onClick={() => {}} />
        </div>
        {/* Overview section */}
        <div className="mt-6 px-5 py-3 bg-white border-stroke border rounded-lg">
          <CustomText variant="lgPoppinsSemiBold">{t('overview')}</CustomText>
          <div className="mt-3 rounded-lg border border-stroke">
            <CustomText variant="mdPoppinsSemiBold" className="p-3 border-b border-stroke">
              Total certificate on upcoming trainings
            </CustomText>
            {/* Total certificate chart view */}
            <div className="p-5 grid grid-cols-1 lg:grid-cols-3 gap-5">
              <div className="col-span-2">
                <GroupBarChart
                  minYAxis={0}
                  maxYAxis={1}
                  labels={[
                    'Jan 2025',
                    'Feb 2025',
                    'Mar 2025',
                    'Apr 2025',
                    'May 2025',
                    'Jun 2025',
                    'Jul 2025',
                  ]}
                  datasets={[
                    {
                      label: 'Dataset 1',
                      data: [0, 0, 1, 1, 0, 0, 1],
                      backgroundColor: 'rgb(37, 80, 233)',
                      barPercentage: 0.5,
                    },
                  ]}
                />
              </div>
              <div className="flex flex-col gap-3 justify-center">
                {barLabel?.map((item) => {
                  return (
                    <div className="flex items-center">
                      <div
                        className={`h-5 w-5 mr-2 ${item?.color} ${item?.rounded ? 'rounded-full' : ''}`}
                      />
                      <CustomText variant="mdPoppinsRegular">{item?.title ?? ''}</CustomText>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          {/* Experiences, Estimated, Regulatory */}
          <div className="mt-5 grid grid-cols-1 lg:grid-cols-3 gap-5">
            {Array.from({ length: 3 })
              .fill(0)
              .map(() => {
                return (
                  <div className="border border-stroke rounded-lg">
                    <CustomText variant="mdPoppinsSemiBold" className="p-3 border-b border-stroke">
                      Total number of experiences to renew
                    </CustomText>
                    <div className="p-5 gap-5 flex flex-col">
                      <CustomText variant="mdPoppinsRegular">DATE: JAN 2025 - JUL 2025</CustomText>
                      <CustomText variant="xxlPoppinsBold">3 certs</CustomText>
                    </div>
                  </div>
                )
              })}
          </div>
          {/* Circle chart view */}
          <div className="mt-5 grid grid-cols-1 lg:grid-cols-2 gap-5">
            {Array.from({ length: 2 })
              .fill(0)
              .map(() => {
                return (
                  <div className="border border-stroke rounded-lg">
                    <CustomText variant="mdPoppinsSemiBold" className="p-3 border-b border-stroke">
                      Number of people within a job title
                    </CustomText>
                    <div className="grid grid-cols-1 lg:grid-cols-2 gap">
                      <div className="p-5 flex items-center">
                        <Pie
                          options={{
                            plugins: {
                              legend: {
                                display: false,
                              },
                            },
                            aspectRatio: 1,
                          }}
                          data={{
                            labels: ['Dataset 1', 'Dataset 2'],
                            datasets: [
                              {
                                label: 'My First Dataset',
                                data: [50, 50],
                                backgroundColor: ['#48D4EBB2', '#E9EDF0'],
                                hoverOffset: 2,
                              },
                            ],
                          }}
                        />
                      </div>
                      <div className="max-lg:p-3 flex flex-col gap-3 justify-center">
                        {pieLabel?.map((item) => {
                          return (
                            <div className="flex items-center">
                              <div
                                className={`h-5 w-5 mr-2 ${item?.color} ${item?.rounded ? 'rounded-full' : ''}`}
                              />
                              <CustomText variant="mdPoppinsRegular">
                                {item?.title ?? ''}
                              </CustomText>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
        <div className="mt-6 px-5 py-3 bg-white border-stroke border rounded-lg">
          <CustomText variant="lgPoppinsSemiBold">Process</CustomText>
          <div className="mt-3 border border-stroke rounded-lg">
            <CustomText variant="mdPoppinsSemiBold" className="p-3 border-b border-stroke">
              Certificate Updates
            </CustomText>
            <div className="mt-3 p-3 grid grid-cols-1 lg:grid-cols-3 gap-5">
              <div className="col-span-2">
                <LineChart
                  minYAxis={0}
                  maxYAxis={50}
                  labels={[
                    'Oct',
                    'Nov',
                    'Dec',
                    'Jab',
                    'Feb',
                    'Mar',
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                  ]}
                  datasets={[
                    {
                      label: t('budgetCost'),
                      data: [10, 20, 30, 40, 35, 25, 15, 20, 30, 20, 10, 40],
                      pointRadius: 0,
                      pointHoverRadius: 0,
                      pointBackgroundColor: 'blue',
                      tension: 0.1,
                      borderWidth: 1,
                      borderColor: 'blue',
                      stack: '1',
                    },
                    {
                      label: t('outcomeCost'),
                      data: [20, 10, 30, 25, 15, 30, 45, 15, 25, 35, 15, 20],
                      pointRadius: 0,
                      pointHoverRadius: 0,
                      pointBackgroundColor: 'red',
                      tension: 0.1,
                      borderWidth: 1,
                      borderColor: 'red',
                      stack: '2',
                    },
                  ]}
                />
              </div>
              <div className="flex flex-col gap-3 justify-center">
                {processLabel?.map((item) => {
                  return (
                    <div className="flex items-center">
                      <div
                        className={`h-5 w-5 mr-2 ${item?.color} ${item?.rounded ? 'rounded-full' : ''}`}
                      />
                      <CustomText variant="mdPoppinsRegular">{item?.title ?? ''}</CustomText>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ControlCenterAnalysis
