import { useMutation } from '@tanstack/react-query'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'

interface Params {
  handle?: string
}

export function usePostEquipmentNotification() {
  return useMutation({
    mutationFn: async (data: Params) => {
      const result = await APIFunctions.POST(
        `${APIConst.equipment.notificationEquipment}?handle=${data?.handle}`,
        {},
      )
      return result
    },
  })
}
