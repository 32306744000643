import { EGroupSubscriberType } from '@inphiz-shared/shared-models/src/F_News/e-group-subscriber-type'
import { ERecommendedActionType } from '@inphiz-shared/shared-models/src/F_RecomededActions/e-recommended-action-type'
import { useMediaQuery } from '../hooks/mediaQuery'
import { CertificateIcon, MyProfile, Policy } from '../icons'
import i18n from '../translations'

export function extractChatMessage(input: string): { extracted: string[], remaining: string } {
  const regex = /\[([^\]]+)\]/g
  const extracted: string[] = []
  let remaining = input
  let match = regex.exec(input)

  while (match !== null) {
    extracted.push(match[1] ?? '')
    remaining = remaining.replace(match[0], '')
    match = regex.exec(input)
  }

  remaining = remaining.trim() // Remove leading/trailing spaces

  return { extracted, remaining }
}

export function getActionDescription(id: number) {
  switch (id) {
    case ERecommendedActionType.UserCertificateIsExpired:
      return { title: i18n.t('userCertificateIsExpired'), icon: <CertificateIcon /> }
    case ERecommendedActionType.UserPolicyIsNotAccepted:
      return { title: i18n.t('userPolicyIsNotAccepted'), icon: <Policy /> }
    case ERecommendedActionType.UserJobTitleRequirementIsMissing:
      return { title: i18n.t('userJobTitleRequirementIsMissing'), icon: <Policy /> }
    case ERecommendedActionType.UserJobRoleRequirementIsMissing:
      return { title: i18n.t('userJobRoleRequirementIsMissing'), icon: <Policy /> }
    case ERecommendedActionType.UserCertificateIsNotApproved:
      return { title: i18n.t('userCertificateIsNotApproved'), icon: <CertificateIcon /> }
    case ERecommendedActionType.UserCertificateIsExpiring:
      return { title: i18n.t('userCertificateIsExpiring'), icon: <CertificateIcon /> }
    case ERecommendedActionType.UserStartDateMissing:
      return { title: i18n.t('userStartDateMissing'), icon: <MyProfile /> }
    case ERecommendedActionType.UserProfileInfoIsMissing:
      return { title: i18n.t('userProfileInfoIsMissing'), icon: <MyProfile /> }
    case ERecommendedActionType.UserIsNotLoggedIn:
      return { title: i18n.t('userIsNotLoggedIn'), icon: <MyProfile /> }
    case ERecommendedActionType.UserDidNotPassOnboarding:
      return { title: i18n.t('userDidNotPassOnboarding'), icon: <MyProfile /> }
    case ERecommendedActionType.ArchivedUserIsNotRemovedFromAD:
      return { title: i18n.t('archivedUserIsNotRemovedFromAD'), icon: <MyProfile /> }
    case ERecommendedActionType.VehicleIsIdling:
      return { title: i18n.t('vehicleIsIdling'), icon: <MyProfile /> }
    case ERecommendedActionType.VehicleCostExceed:
      return { title: i18n.t('vehicleCostExceed'), icon: <MyProfile /> }
    case ERecommendedActionType.MissingTimereports:
      return { title: i18n.t('missingTimereports'), icon: <MyProfile /> }
    case ERecommendedActionType.NotPlannedEmptyings:
      return { title: i18n.t('notPlannedEmptyings'), icon: <MyProfile /> }
    case ERecommendedActionType.NotHandledEmptyings:
      return { title: i18n.t('notHandledEmptyings'), icon: <MyProfile /> }
    default:
      return { title: '', icon: null }
  }
}

export function getEnumKeysFromValue(value = 1): [{ key: string, val: string }] {
  const keys: any = []
  for (const [key, val] of Object.entries(EGroupSubscriberType)) {
    // Only check numeric values and avoid 'All' which is ~0 (-1)
    if (typeof val === 'number' && val > 0 && (value & val) === val) {
      keys.push({
        key,
        val,
      })
    }
  }
  return keys
}

export function formatTextInput(text: string, previousText: string) {
  if (!text)
    return text

  const deleting = previousText && previousText.length > text.length
  if (deleting)
    return text

  const cleaned = text.replace(/\D/g, '') // remove non-digit characters
  let match = null

  if (cleaned.length > 0 && cleaned.length < 9) {
    return cleaned
  }
  else if (cleaned.length >= 9 && cleaned.length < 13) {
    match = cleaned.match(/(\d{8})(\d{0,4})$/)
    if (match)
      return `${match[1]}-${match[2]}`
  }

  return text
}

export function validateEmail(email: string): boolean {
  // Regular expression for validating email
  const emailRegex = /^[^\s@]+@[^\s@][^\s.@]*\.[^\s@]+$/
  return emailRegex.test(email)
}

export function useMediaQueries() {
  const md = useMediaQuery('(min-width: 768px)')
  const lg = useMediaQuery('(min-width: 1024px)')

  return { md, lg }
}
