import { useMutation } from '@tanstack/react-query'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'

interface Params {
  handle?: string
  reason?: string
  isRecoveryMode?: boolean
}

export function usePostArchive() {
  return useMutation({
    mutationFn: async (params: Params) => {
      const result = await APIFunctions.POST(APIConst.employeesPerformance.archive, params)
      return result
    },
    onSuccess(_: any, variables: any) {},
  })
}
