import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { QueryBuilder } from '@inphiz/core-utilities'
import type { UserItem } from '../Social'
import type { UserDetails } from './useEmployeeDetails'

interface UserObj extends UserDetails {
  user: UserItem
}

interface params {
  handle: string
  isShowDeleted?: boolean
}

export function useEmployeeItem({ handle, isShowDeleted }: params) {
  return useQuery<UserObj>({
    queryKey: [QueryKey.getEmployeeItem, handle],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.usersDirectory.userProfile).add('handle', handle)
      if (isShowDeleted) {
        qb.add('showDeleted', isShowDeleted)
      }
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
    enabled: false,
  })
}
