import { useMutation } from '@tanstack/react-query'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst, QueryKey } from '@inphiz/api'
import { queryClient } from '../../../App'

interface Params {
  userHandle?: string
  password?: string
}

export function usePostLoginAccount() {
  return useMutation({
    mutationFn: async (params: Params) => {
      const result = await APIFunctions.POST(
        `${APIConst.employeesPerformance.loginAccount}`,
        params,
      )
      return result
    },
    onSuccess(_: any, variables: any) {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.getEmployeeDetails, variables.userHandle],
      })
    },
  })
}
