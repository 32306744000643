import { useMutation } from '@tanstack/react-query'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import type { UserItem } from '../Social'

const EmployeeListDto = new DataCollectionApiResponse<UserItem>()
export type EmployeeListData = typeof EmployeeListDto

interface EmployeeParams {
  cursor?: string
  take?: number
  isOrderAscending?: boolean
  predicateGroups?: [
    {
      operation: string
      parameterName: string
      predicates: [
        {
          parameter: string
          value: boolean
          operator: string
        },
      ]
    },
  ]
  parametersWithValues?: {
    HideWithoutJobTitle?: boolean
    IncludeOrganizationUnit?: boolean
    AllUsers?: boolean
    IsOnPremiseUser?: boolean
    ShowDeleted?: boolean
    IsResourceUser?: boolean
    ShowGuests?: boolean
  }
}

export function usePostEmployeeList() {
  return useMutation({
    mutationFn: async (params: EmployeeParams) => {
      const result = await APIFunctions.POST(APIConst.managerList, params)
      return result
    },
  })
}
