import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { DataItemApiResponse } from '@inphiz-shared/shared-models/src/Api/data-item-api-response'
import { QueryBuilder } from '@inphiz/core-utilities'
import type { UserItem } from '../Social'

const UserDetailsDto = new DataItemApiResponse<UserItem>()
export type UserDetails = typeof UserDetailsDto
interface UserObj extends UserDetails {
  user: UserItem
}

interface params {
  handle: string
  isShowDeleted?: boolean
}

export function useEmployeeDetails({ handle, isShowDeleted }: params) {
  return useQuery<UserObj>({
    queryKey: [QueryKey.getEmployeeDetails, handle],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.employeeDetails).add('handle', handle)
      if (isShowDeleted) {
        qb.add('showDeleted', isShowDeleted)
      }
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
  })
}
