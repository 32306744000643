import { useMutation } from '@tanstack/react-query'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'

interface Params {
  userHandle?: string
  equipmentTypeHandle?: string
}

export function usePostEquipmentAssign() {
  return useMutation({
    mutationFn: async (data: Params) => {
      const params = {
        item: data,
      }
      const result = await APIFunctions.POST(APIConst.equipment.assignmentEquipment, params)
      return result
    },
  })
}
