import type { SelectPickerProps } from 'rsuite'
import { SelectPicker } from 'rsuite'
import { CustomText } from '../CustomText'

export interface CustomDropDownProps extends SelectPickerProps {
  title?: string
  labelField?: string
  valueField?: string
  className?: string
}

export function CustomDropDown({
  title,
  labelField,
  valueField,
  className,
  ...props
}: CustomDropDownProps) {
  return (
    <div className="flex flex-col cm-dropdown-icon">
      {title && (
        <CustomText variant="mdPoppinsSemiBold" className={className}>
          {title}
        </CustomText>
      )}
      <SelectPicker
        labelKey={labelField}
        valueKey={valueField}
        placement="autoVertical"
        {...props}
      />
    </div>
  )
}
