import type { FeedbackGroupItem } from '../../../packages/api-web'
import { UserMetadataFields } from '@inphiz-shared/shared-models/src/F_Users/user-metadata-fields'
import { GroupSharedConstants } from '@inphiz-shared/shared-models/src/Groups/group-shared-constants'
import { APIConst } from '@inphiz/api'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Loader, SelectPicker } from 'rsuite'
import { queryClient } from '../../../App'
import { dateTimeFormat, validateEmail } from '../../../helpers'
import { CloseIcon, DeleteIcon } from '../../../icons'
import {
  useAvailableJobTitle,
  useDeleteMember,
  useDepartmentList,
  useEmployeeDetails,
  useFeedbackGroupList,
  useManagerFilterList,
  usePostArchive,
  useUpdateEmployee,
} from '../../../packages/api-web'
import { BorderButton, CustomButton, CustomImg, CustomText, Spinner } from '../../atoms'
import { AddGroupModal, ArchiveUserModal, DeleteAlert, ResetPasswordModal } from '../../molecules'

interface GroupModel {
  title: string
  handle: string
}
interface params {
  handle: string
}

export function EmployeeInfo({ handle }: params) {
  const { t } = useTranslation('common')
  const { state: routeParams } = useLocation()
  const navigate = useNavigate()
  const [isJobTitleDropDown, setIsJobTitleDropDown] = useState<boolean>(false)
  const [isDepartmentDropDown, setIsDepartmentDropDown] = useState<boolean>(false)
  const [isManagerDropDown, setIsManagerDropDown] = useState<boolean>(false)
  const [isBusinessInput, setIsBusinessInput] = useState<boolean>(false)
  const [isPrivateInput, setIsPrivateInput] = useState<boolean>(false)
  const [isContactEmailInput, setIsContactEmailInput] = useState<boolean>(false)
  const [isStateInput, setIsStateInput] = useState<boolean>(false)
  const [isCityInput, setIsCityInput] = useState<boolean>(false)
  const [isAddGroup, setIsAddGroup] = useState<boolean>(false)
  const [isDeleteGroupAlert, setIsDeleteGroupAlert] = useState<boolean>(false)
  const [isReset, setIsReset] = useState<boolean>(false)
  const [isCreateLogin, setIsCreateLogin] = useState<boolean>(false)
  const [isArchive, setIsArchive] = useState<boolean>(false)
  const [isUnArchive, setIsUnArchive] = useState<boolean>(false)
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)
  const [managerSearch, setManagerSearch] = useState<string>('')
  const [selectedGroup, setSelectedGroup] = useState<FeedbackGroupItem>()
  const { data: departmentList } = useDepartmentList()
  const { data: jobTitleList } = useAvailableJobTitle({
    handle,
  })
  const {
    data: managerList,
    isFetching: isManagerList,
    refetch: refetchManagerList,
  } = useManagerFilterList({ filterTxt: managerSearch })
  const { isLoading: isEmployeeItemLoad, data: employeeItem } = useEmployeeDetails({
    handle,
    isShowDeleted: routeParams?.isShowDeleted,
  })
  const { mutate: updateEmployeeInfoMutate, isPending: isEmployeeUpdate } = useUpdateEmployee()
  const { data: groupData } = useFeedbackGroupList(handle)
  const { isPending: isDeleteGroup, mutate } = useDeleteMember()
  const { isPending, mutate: unarchiveMutate } = usePostArchive()
  const [businessNum, setBusinessNum] = useState<string>('')
  const [privateNum, setPrivateNum] = useState<string>('')
  const [contactEmail, setContactEmail] = useState<string>('')
  const [emailErr, setEmailErr] = useState<string>('')
  const [state, setState] = useState<string>('')
  const [city, setCity] = useState<string>('')

  const employeeInfo = useMemo(() => {
    setBusinessNum(employeeItem?.user?.userMetadata?.find(i => i?.key == 'Phone')?.value ?? '-')
    setPrivateNum(employeeItem?.user?.userMetadata?.find(i => i?.key == 'Mobile')?.value ?? '-')
    // todo: HACK_OVERRIDE_USER_CONTACT_EMAIL_LOGIC
    setContactEmail(
      employeeItem?.user?.userMetadata?.find(i => i?.key == UserMetadataFields.contactEmail)
        ?.value ?? '-',
    )
    setState(employeeItem?.user?.userMetadata?.find(i => i?.key == 'State')?.value ?? '-')
    setCity(employeeItem?.user?.userMetadata?.find(i => i?.key == 'City')?.value ?? '-')
    return employeeItem
  }, [employeeItem])

  const department = useMemo(() => {
    const result = departmentList?.items
      ?.filter(i => i?.handle !== '')
      .map(i => ({
        ...i,
        label: i?.title,
        value: i?.handle,
      }))
    return result
  }, [departmentList])

  const jobTitleData = useMemo(() => {
    const result = jobTitleList?.items
      ?.filter(i => i?.handle !== '')
      .map(i => ({
        ...i,
        label: i?.title,
        value: i?.handle,
      }))
    return result
  }, [jobTitleList])

  const managerData = useMemo(() => {
    const result = managerList?.items
      ?.filter(i => i?.handle !== '')
      .map(i => ({
        ...i,
        label: i?.displayName,
        value: i?.handle,
      }))
    return result
  }, [managerList])

  const canGroupBeUnsubscribed = (group: GroupModel): boolean => {
    const meta = ['Department', 'OfficeLocation', 'State']
    return (
      !GroupSharedConstants.restrictedToModifyGroupTitles.includes(group.title)
      && !GroupSharedConstants.restrictedToModifyGroupHandles.includes(group.handle)
      && !employeeItem?.user?.userMetadata?.some(
        um => meta.includes(um?.key as string) && um.value === group.title,
      )
    )
  }

  const groupList = useMemo(() => {
    const result
      = groupData
        && groupData?.items?.map((i) => {
          return {
            ...i,
            isEdit: canGroupBeUnsubscribed({ title: i?.title, handle: i?.handle }),
          }
        })
    return result
  }, [groupData, employeeItem])

  useEffect(() => {
    refetchManagerList()
  }, [managerSearch])

  const validationCheck = () => {
    setEmailErr('')
    if (!validateEmail(contactEmail)) {
      setEmailErr(t('emailValidation'))
      return false
    }
    return true
  }

  return (
    <div className="col-span-2 rounded-lg border border-stroke">
      {(isEmployeeItemLoad || isDeleteGroup) && <Spinner />}
      {isReset && (
        <ResetPasswordModal
          isCreatePassword={isCreateLogin}
          userHandle={handle}
          onCloseAlert={() => {
            setIsReset(false)
          }}
        />
      )}
      {isArchive && (
        <ArchiveUserModal
          userHandle={handle}
          onCloseAlert={() => {
            setIsArchive(false)
          }}
        />
      )}
      {isAddGroup && (
        <AddGroupModal
          userHandle={handle}
          addedGroups={groupList ?? []}
          onCloseAlert={() => {
            setIsAddGroup(false)
          }}
        />
      )}
      {isDeleteGroupAlert && (
        <DeleteAlert
          title={`${t('remove')} ${employeeInfo?.user?.displayName} ${t('from')} ${selectedGroup?.title}`}
          subTitle={`${t('removeTheUser')} ${employeeInfo?.user?.displayName} ${t('from')} ${t('the')} ${selectedGroup?.title} ${t('group')}?`}
          leftButtonText={t('cancel')}
          rightButtonText={t('delete')}
          onCloseAlert={() => {
            setIsDeleteGroupAlert(false)
          }}
          onLeftButton={() => {
            setIsDeleteGroupAlert(false)
          }}
          onRightButton={() => {
            const data = {
              groupHandles: selectedGroup?.handle,
              subscriberHandle: handle,
            }
            mutate(data, {
              onSuccess: () => {
                queryClient.invalidateQueries({
                  queryKey: [QueryKey.feedbackGroup, handle],
                })
                setIsDeleteGroupAlert(false)
              },
            })
          }}
        />
      )}
      {isUnArchive && (
        <DeleteAlert
          title={`Unarchive ${employeeInfo?.user?.displayName}`}
          subTitle={`${t('areYouWantUnarchive')} ${employeeInfo?.user?.displayName}?`}
          leftButtonText={t('no')}
          rightButtonText={t('yes')}
          onCloseAlert={() => {
            setIsUnArchive(false)
          }}
          onLeftButton={() => {
            setIsUnArchive(false)
          }}
          onRightButton={() => {
            const data = {
              handle,
              reason: 'Recovered from dashboard',
              isRecoveryMode: true,
            }
            unarchiveMutate(data, {
              onSuccess: () => {
                navigate(-1)
              },
            })
          }}
        />
      )}
      <div className="p-5 grid grid-cols-3 items-start">
        <div
          className={`flex col-span-2 mr-4 ${!routeParams?.isShowDeleted ? 'flex-col items-end' : ''}`}
        >
          {routeParams?.isShowDeleted && (
            <CustomText variant="xsmPoppinsRegular" className="flex-1 italic">
              {`${t('archivedOn')} ${dateTimeFormat(employeeInfo?.user?.deletedTime ?? '')}`}
            </CustomText>
          )}
          <div className="flex flex-col items-center gap-2">
            <CustomImg
              name={employeeInfo?.user?.displayName ?? ''}
              imgSrc={
                employeeInfo?.user?.profileImageHandle
                  ? `${APIConst.baseURL}/${APIConst.mediaUpload}/${employeeInfo?.user?.profileImageHandle}`
                  : ''
              }
              altText="user_image"
              className="h-28 w-28 rounded-full"
              imgClassName="rounded-full"
              textVariant="xxlPoppinsSemiBold"
            />
            <CustomText variant="xlPoppinsBold" className="flex-1">
              {employeeInfo?.user?.displayName ?? ''}
            </CustomText>
            <CustomText variant="mdPoppinsRegular" className="flex-1">
              {employeeInfo?.user?.jobTitle ?? ''}
            </CustomText>
          </div>
        </div>
        <div className="flex flex-wrap gap-2 items-start">
          <BorderButton
            children={routeParams?.isShowDeleted ? t('unarchive') : t('archive')}
            className="px-7"
            textColor="text-textDanger"
            onClick={() => {
              if (routeParams?.isShowDeleted) {
                setIsUnArchive(true)
              }
              else {
                setIsArchive(true)
              }
            }}
          />
          <BorderButton
            children={t('history')}
            className="px-7"
            onClick={() => {
              navigate('/employee/history', { state: { userHandle: handle } })
            }}
          />
          {!routeParams?.isShowDeleted && (
            <BorderButton
              disabled={employeeInfo?.user?.metadata?.IsBlankUser === 'True'}
              children={t('resetPassword')}
              className={`px-7 ${employeeInfo?.user?.metadata?.IsBlankUser === 'True' ? 'opacity-50' : ''}`}
              onClick={() => {
                setIsCreateLogin(false)
                setIsReset(true)
              }}
              {...(employeeInfo?.user?.metadata?.IsBlankUser === 'True' && {
                title:
                  'Unable to reset password for this user. It could be due to that the user has external dependemcies or is an external user.',
              })}
            />
          )}
          {employeeInfo?.user?.metadata?.IsBlankUser === 'True' && (
            <BorderButton
              children="Create login"
              className="px-7"
              onClick={() => {
                setIsCreateLogin(true)
                setIsReset(true)
              }}
            />
          )}
        </div>
      </div>
      <div className="p-5 grid grid-cols-1 lg:grid-cols-2 border-t border-stroke gap-5">
        <div className="flex flex-1 flex-col gap-3">
          <div className="flex justify-between">
            <CustomText variant="mdPoppinsRegular" className="flex-1">
              {t('userName')}
            </CustomText>
            <CustomText variant="mdPoppinsSemiBold" className="flex-1 opacity-50">
              {employeeInfo?.user?.email ?? ''}
            </CustomText>
          </div>
          <div className="flex justify-between">
            <CustomText variant="mdPoppinsRegular" className="flex-1">
              {t('jobTitle')}
            </CustomText>
            {isJobTitleDropDown ? (
              <div className="flex flex-1 items-center">
                <SelectPicker
                  size="md"
                  loading={isEmployeeUpdate}
                  defaultValue={
                    employeeInfo?.user?.userJobTitles?.find(
                      i => i?.title == employeeInfo?.user?.jobTitle,
                    )?.jobTitleHandle
                  }
                  data={jobTitleData ?? []}
                  style={{ flex: 1 }}
                  onChange={(value) => {
                    const data = {
                      handle,
                      jobTitleHandle: `${value}`,
                    }
                    updateEmployeeInfoMutate(data, {
                      onSuccess: () => {
                        setIsJobTitleDropDown(false)
                      },
                    })
                  }}
                />
                <button
                  onClick={() => {
                    setIsJobTitleDropDown(false)
                  }}
                >
                  <CloseIcon />
                </button>
              </div>
            ) : (
              <CustomText
                variant="mdPoppinsSemiBold"
                className={`flex-1 ${routeParams?.isShowDeleted ? 'opacity-50' : 'opacity-100'}`}
                onClick={() => {
                  if (!routeParams?.isShowDeleted) {
                    setIsJobTitleDropDown(true)
                  }
                }}
              >
                {employeeInfo?.user?.jobTitle ?? ''}
              </CustomText>
            )}
          </div>
          <div className="flex justify-between">
            <CustomText variant="mdPoppinsRegular" className="flex-1">
              {t('businessMobile')}
            </CustomText>
            {isBusinessInput ? (
              <>
                <input
                  value={businessNum}
                  className="flex-1 border border-stroke px-2"
                  onChange={e => setBusinessNum(e.target.value)}
                  onBlur={() => {
                    const data = {
                      handle,
                      phone: `${businessNum}`,
                    }
                    updateEmployeeInfoMutate(data, {
                      onSuccess: () => {
                        setIsBusinessInput(false)
                      },
                    })
                  }}
                  autoFocus
                />
                {isEmployeeUpdate && <Loader />}
              </>
            ) : (
              <CustomText
                variant="mdPoppinsSemiBold"
                className={`flex-1 ${routeParams?.isShowDeleted ? 'opacity-50' : 'opacity-100'}`}
                onClick={() => {
                  if (!routeParams?.isShowDeleted) {
                    setIsBusinessInput(true)
                  }
                }}
              >
                {businessNum}
              </CustomText>
            )}
          </div>
          <div className="flex justify-between">
            <CustomText variant="mdPoppinsRegular" className="flex-1">
              {t('privateMobile')}
            </CustomText>
            {isPrivateInput ? (
              <>
                <input
                  value={privateNum}
                  className="flex-1 border border-stroke px-2"
                  onChange={e => setPrivateNum(e.target.value)}
                  onBlur={() => {
                    const data = {
                      handle,
                      mobile: `${privateNum}`,
                    }
                    updateEmployeeInfoMutate(data, {
                      onSuccess: () => {
                        setIsPrivateInput(false)
                      },
                    })
                  }}
                  autoFocus
                />
                {isEmployeeUpdate && <Loader />}
              </>
            ) : (
              <CustomText
                variant="mdPoppinsSemiBold"
                className={`flex-1 ${routeParams?.isShowDeleted ? 'opacity-50' : 'opacity-100'}`}
                onClick={() => {
                  if (!routeParams?.isShowDeleted) {
                    setIsPrivateInput(true)
                  }
                }}
              >
                {privateNum}
              </CustomText>
            )}
          </div>
          <div>
            <div className="flex justify-between">
              <CustomText variant="mdPoppinsRegular" className="flex-1">
                {t('contactEmail')}
              </CustomText>
              {isContactEmailInput ? (
                <>
                  <input
                    value={contactEmail}
                    className="flex-1 border border-stroke px-2"
                    onChange={e => setContactEmail(e.target.value)}
                    onBlur={() => {
                      if (validationCheck()) {
                        const data = {
                          handle,
                          contactEmail: `${contactEmail}`,
                        }
                        updateEmployeeInfoMutate(data, {
                          onSuccess: () => {
                            setIsContactEmailInput(false)
                          },
                        })
                      }
                    }}
                    autoFocus
                  />
                  {isEmployeeUpdate && <Loader />}
                </>
              ) : (
                <CustomText
                  variant="mdPoppinsSemiBold"
                  className={`flex-1 ${routeParams?.isShowDeleted ? 'opacity-50' : 'opacity-100'}`}
                  onClick={() => {
                    if (!routeParams?.isShowDeleted) {
                      setIsContactEmailInput(true)
                    }
                  }}
                >
                  {contactEmail}
                </CustomText>
              )}
            </div>
            {emailErr && (
              <CustomText variant="smPoppinsRegular" textColor="text-textDanger">
                {emailErr}
              </CustomText>
            )}
          </div>
        </div>
        <div className="flex flex-1 flex-col gap-3">
          <div className="flex justify-between">
            <CustomText variant="mdPoppinsRegular" className="flex-1">
              {t('organization')}
            </CustomText>
            <CustomText variant="mdPoppinsSemiBold" className="flex-1 opacity-50">
              {employeeInfo?.user?.userOrganizationUnits?.flatMap(i => i?.title).join(',') ?? '-'}
            </CustomText>
          </div>
          <div className="flex justify-between">
            <CustomText variant="mdPoppinsRegular" className="flex-1">
              {t('department')}
            </CustomText>
            {isDepartmentDropDown ? (
              <div className="flex flex-1 items-center">
                <SelectPicker
                  loading={isEmployeeUpdate}
                  size="md"
                  defaultValue={employeeInfo?.user?.department}
                  data={department ?? []}
                  style={{ flex: 1 }}
                  onChange={(value) => {
                    const data = {
                      handle,
                      department: `${value}`,
                    }
                    updateEmployeeInfoMutate(data, {
                      onSuccess: () => {
                        setIsDepartmentDropDown(false)
                      },
                    })
                  }}
                />
                <button
                  onClick={() => {
                    setIsDepartmentDropDown(false)
                  }}
                >
                  <CloseIcon />
                </button>
              </div>
            ) : (
              <CustomText
                variant="mdPoppinsSemiBold"
                className={`flex-1 ${routeParams?.isShowDeleted ? 'opacity-50' : 'opacity-100'}`}
                onClick={() => {
                  if (!routeParams?.isShowDeleted) {
                    setIsDepartmentDropDown(true)
                  }
                }}
              >
                {employeeInfo?.user?.department ?? '-'}
              </CustomText>
            )}
          </div>
          <div className="flex justify-between">
            <CustomText variant="mdPoppinsRegular" className="flex-1">
              {t('state')}
            </CustomText>
            {isStateInput ? (
              <>
                <input
                  value={state}
                  className="flex-1 border border-stroke px-2"
                  onChange={e => setState(e.target.value)}
                  onBlur={() => {
                    const data = {
                      handle,
                      state: `${state}`,
                    }
                    updateEmployeeInfoMutate(data, {
                      onSuccess: () => {
                        setIsStateInput(false)
                      },
                    })
                  }}
                  autoFocus
                />
                {isEmployeeUpdate && <Loader />}
              </>
            ) : (
              <CustomText
                variant="mdPoppinsSemiBold"
                className={`flex-1 ${routeParams?.isShowDeleted ? 'opacity-50' : 'opacity-100'}`}
                onClick={() => {
                  if (!routeParams?.isShowDeleted) {
                    setIsStateInput(true)
                  }
                }}
              >
                {state}
              </CustomText>
            )}
          </div>
          <div className="flex justify-between">
            <CustomText variant="mdPoppinsRegular" className="flex-1">
              {t('city')}
            </CustomText>
            {isCityInput ? (
              <>
                <input
                  value={city}
                  className="flex-1 border border-stroke px-2"
                  onChange={e => setCity(e.target.value)}
                  onBlur={() => {
                    const data = {
                      handle,
                      city: `${city}`,
                    }
                    updateEmployeeInfoMutate(data, {
                      onSuccess: () => {
                        setIsCityInput(false)
                      },
                    })
                  }}
                  autoFocus
                />
                {isEmployeeUpdate && <Loader />}
              </>
            ) : (
              <CustomText
                variant="mdPoppinsSemiBold"
                className={`flex-1 ${routeParams?.isShowDeleted ? 'opacity-50' : 'opacity-100'}`}
                onClick={() => {
                  if (!routeParams?.isShowDeleted) {
                    setIsCityInput(true)
                  }
                }}
              >
                {employeeInfo?.user?.userMetadata?.find(i => i?.key == 'City')?.value ?? '-'}
              </CustomText>
            )}
          </div>
          <div className="flex justify-between">
            <CustomText variant="mdPoppinsRegular" className="flex-1">
              {t('reportsTo')}
            </CustomText>
            {isManagerDropDown ? (
              <div className="flex flex-1 items-center">
                <SelectPicker
                  size="md"
                  defaultValue={employeeInfo?.user?.managerHandle}
                  data={managerData ?? []}
                  style={{ flex: 1 }}
                  loading={isManagerList || isEmployeeUpdate}
                  onSearch={(e) => {
                    if (e?.length > 3) {
                      setManagerSearch(e)
                    }
                    else if (e?.length == 0) {
                      setManagerSearch(e)
                    }
                  }}
                  placeholder={
                    employeeInfo?.user?.userMetadata?.find(i => i?.key == 'Manager')?.value ?? '-'
                  }
                  onChange={(value) => {
                    const data = {
                      handle,
                      managerHandle: `${value}`,
                    }
                    updateEmployeeInfoMutate(data, {
                      onSuccess: () => {
                        setIsManagerDropDown(false)
                      },
                    })
                  }}
                />
                <button
                  onClick={() => {
                    setIsManagerDropDown(false)
                  }}
                >
                  <CloseIcon />
                </button>
                <button
                  onClick={() => {
                    const data = {
                      handle,
                      managerHandle: '',
                    }
                    updateEmployeeInfoMutate(data, {
                      onSuccess: () => {
                        setIsManagerDropDown(false)
                      },
                    })
                  }}
                >
                  <DeleteIcon />
                </button>
              </div>
            ) : (
              <CustomText
                variant="mdPoppinsSemiBold"
                className={`flex-1 ${routeParams?.isShowDeleted ? 'opacity-50' : 'opacity-100'}`}
                onClick={() => {
                  if (!routeParams?.isShowDeleted) {
                    setIsManagerDropDown(true)
                  }
                }}
              >
                {employeeInfo?.user?.userMetadata?.find(i => i?.key == 'Manager')?.value ?? '-'}
              </CustomText>
            )}
          </div>
        </div>
      </div>
      <div className="px-5">
        <div className="flex items-center">
          <CustomText variant="mdPoppinsSemiBold" className="flex-1" onClick={() => {}}>
            {t('myGroups')}
          </CustomText>
          <CustomButton
            children={t('addToGroup')}
            backgroundColor="bg-bthDisable"
            textColor="text-textPrimary"
            onClick={() => {
              setIsAddGroup(true)
            }}
            className="!px-5"
          />
        </div>
        <div className="py-5 flex flex-wrap items-center">
          {groupList?.map((i, index) => {
            return (
              <div
                className="flex items-center mb-3"
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <CustomButton
                  children={i?.title}
                  textColor="text-textPrimary"
                  onClick={() => {
                    navigate(`/group/${i.handle}`)
                  }}
                  className={`${(hoveredIndex !== index || !i?.isEdit) && 'mr-3'} !rounded-full !px-5 !py-1 bg-white border border-secondary`}
                />
                {i?.isEdit && (
                  <button
                    className={`ml-1 mr-3 ${hoveredIndex !== index && 'hidden'}`}
                    onClick={() => {
                      setSelectedGroup(i)
                      setIsDeleteGroupAlert(true)
                    }}
                  >
                    <DeleteIcon />
                  </button>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}
