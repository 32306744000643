import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import { APIConst } from '@inphiz/api'
import {
  CustomButton,
  CustomDropDown,
  CustomImg,
  CustomText,
  CustomTextArea,
  ImagePicker,
  Spinner,
  TextWithIcon,
} from '../../component'
import { UserContext } from '../../contexts/UserContext'
import { CheckedIcon, UncheckIcon } from '../../icons'
import { queryClient } from '../../App'
import type { CreateSocialPostParam, Group, UploadResponse } from '../../packages/api-web'
import { useCreateSocialPostMutation, useUploadMedia } from '../../packages/api-web'
import { ContentType, SocialPostType } from '../../helpers'

interface Images {
  uri: string
  type: string | null
  name: string | null
  createdTime?: string
}

function AddSocialPost() {
  const navigate = useNavigate()
  const { state } = useLocation()
  const { t } = useTranslation('common')
  const { user } = useContext(UserContext)
  const data = queryClient.getQueryData([QueryKey.groupsList, 'groupwallpost']) as Group[]
  const [selectCheckBox, setSelectCheckBox] = useState<boolean>(false)
  const [groupList, setGroupList] = useState<Group[] | null>(null)
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null)
  const [conversation, setConversation] = useState<string>('')
  const [selectedImage, setSelectedImage] = useState<any[]>()
  const { createPostMutate, isPending } = useCreateSocialPostMutation()
  const { mutate: uploadMediaMutate, isPending: isPendingImage } = useUploadMedia()

  useEffect(() => {
    if (data) {
      const notManager = user.userData.metadata?.HasReportingToMe === 'False'
      const filteredData = notManager
        ? data.slice(1).filter(i => i.metadata?.Social !== 'OnlyManagerCanPost')
        : data.slice(1)
      setGroupList(filteredData)
    }
  }, [data])

  useEffect(() => {
    if (state?.gType && state?.gType.handle)
      setSelectedGroup(state?.gType)
    else getPreviousGroupType()
  }, [state?.gType, groupList])

  const getPreviousGroupType = async () => {
    if (!groupList)
      return
    const sortedData = groupList.sort((a, b) => a.title.localeCompare(b.title))
    const filteredData
      = user.userData.metadata?.HasReportingToMe === 'False'
        ? sortedData.filter(i => i.metadata?.Social !== 'OnlyManagerCanPost')
        : sortedData

    if (filteredData && filteredData.length > 0) {
      setSelectedGroup(filteredData[0] as Group)
    }
  }

  const createSnackPost = () => {
    const requestParams: FormData = new FormData()
    if (selectedImage && selectedImage?.length > 0) {
      requestParams.append('files', selectedImage[0])
    }
    const createPostObj: CreateSocialPostParam = {
      ownerHandle: selectedGroup?.handle,
      ownerContentType: 'group',
      type: SocialPostType.default,
      content: conversation.replace(/\n/g, '<br>'),
      authorHandle: '@me',
      contentType: ContentType.groupwallpost,
    }
    if (selectCheckBox) {
      createPostObj.actionsInfo = {
        socialActions: [
          {
            value: 'True',
            type: SocialPostType.readConfirmed,
            userHandle: '@me',
          },
        ],
      }
    }
    if (selectedImage && selectedImage.length > 0) {
      uploadMediaMutate(requestParams, {
        onSuccess: (data: UploadResponse) => {
          if (data.isSuccess) {
            const newObj = {
              ...createPostObj,
              coverMediaHandles: data.items?.map(i => i.handle)?.filter(Boolean) as string[],
            }
            createPostMutate(newObj, {
              onSuccess: (data) => {
                if (data.isSuccess)
                  refreshingData()
              },
            })
          }
        },
      })
    }
    else {
      createPostMutate(createPostObj, {
        onSuccess: (data) => {
          if (data.isSuccess)
            refreshingData()
        },
      })
    }
  }

  const refreshingData = async () => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.socialList, state?.gType?.handle] })
    // setSelectedItem(groupType)
    navigate(-1)
  }

  return (
    <div className="p-5 h-full flex flex-col">
      <CustomText variant="xlPoppinsSemiBold">{`${t('newPost')}`}</CustomText>
      <div className="mt-5 mb-5 flex flex-1 flex-col overflow-y-auto scrollbar rounded-lg border border-stroke">
        <div className="bg-white flex-1">
          <div className="p-5 flex items-center border-b border-b-stroke">
            <CustomImg
              name={user?.userData?.displayName ?? 'Anonym'}
              imgSrc={
                user?.userData?.profileImageHandle
                  ? `${APIConst.baseURL}/${APIConst.mediaUpload}/${user?.userData?.profileImageHandle}`
                  : ''
              }
              altText="user_image"
              className="h-10 w-10 rounded-md"
              imgClassName="rounded-md"
            />
            <div className="ml-3">
              <CustomText variant="smPoppinsSemiBold">{`${user?.userData?.displayName ?? 'Anonym'} `}</CustomText>
              <CustomText
                className="mt-1"
                variant="smPoppinsRegular"
                textColor="text-textSecondary"
              >
                {`${user?.userData?.subtitle?.text ?? '-'} `}
              </CustomText>
            </div>
          </div>
          <div className="p-5">
            <CustomDropDown
              value={selectedGroup?.handle}
              className="!mb-2"
              title={`${t('category')}`}
              data={groupList ?? []}
              labelField="title"
              valueField="handle"
              onSelect={(_, values) => {
                setSelectedGroup(values as Group)
              }}
            />
            <div className="mt-3" />
            <CustomTextArea
              title={`${t('conversation')}`}
              placeholder={`${t('startAConversation')}`}
              aria-multiline
              value={conversation}
              onChange={(e) => {
                setConversation(e.target.value)
              }}
            />
            <div className="mt-3" />
            <ImagePicker
              title={`${t('chooseImageSocial')}`}
              buttonTitle={`${t('choosePicture')}`}
              selectedImage={(item) => {
                if (item) {
                  setSelectedImage([item])
                }
              }}
            />
          </div>
        </div>
      </div>
      {(selectedGroup?.metadata?.Social == 'OnlyManagerCanPostWithConfirmation'
        || selectedGroup?.metadata?.Social == 'OnlyManagerCanPost')
      && user.userData.metadata?.HasReportingToMe == 'True' && (
        <button
          className="text-start self-start"
          onClick={() => {
            setSelectCheckBox(!selectCheckBox)
          }}
        >
          <TextWithIcon
            leftIcon={
              selectCheckBox ? <CheckedIcon className="mr-2" /> : <UncheckIcon className="mr-2" />
            }
            text={`${t('requestReadReceipt')}.`}
            textVariant="smPoppinsRegular"
          />
        </button>
      )}
      <div className="self-end">
        <CustomButton
          children={t('cancel')}
          backgroundColor="bg-bthDisable"
          textColor="text-textPrimary"
          onClick={() => {
            navigate(-1)
          }}
        />
        <CustomButton
          children={t('publish')}
          className="ml-3"
          backgroundColor={
            selectedGroup && conversation?.length > 0 ? 'bg-primary' : 'bg-bthDisable'
          }
          textColor={
            selectedGroup && conversation?.length > 0 ? 'text-textWhite' : 'text-textSecondary'
          }
          onClick={() => {
            createSnackPost()
          }}
        />
      </div>
      {(isPending || isPendingImage) && <Spinner />}
    </div>
  )
}

export default AddSocialPost
