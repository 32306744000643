import FlatList from 'flatlist-react'
import { useTranslation } from 'react-i18next'
import { useContext, useEffect, useRef, useState } from 'react'
import type {
  AssistantResponseDto,
  CitationDto,
} from '@inphiz-shared/shared-models/src/F_AiAssistant'
import { Logger } from '@inphiz/logger'
import type { MediaItemDto } from '@inphiz-shared/shared-models/src/Base/media-item-dto'
import { APIConst } from '@inphiz/api'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { ChatBubbleView, ChatEmpty } from '../../molecules'
import { CustomText, TextWithIcon } from '../../atoms'
import { SendMessage } from '../../../icons'
import { UserContext } from '../../../contexts/UserContext'
import { usePostMessage } from '../../../packages/api-web'
import type { History } from '../../../database'
import { Stores, addDB, getStoreData, updateDB } from '../../../database'
import type { HistoryListItem } from '../HistoryList'

const typingMessageId = 'ID_0'

export interface IMessage {
  id: string
  text: string
  createdAt: Date | number
  user: {
    id: string
  }
  citations?: CitationDto[]
}
interface ChatListProps {
  historyItem?: HistoryListItem
  isDashboard?: boolean
}

export function ChatList({ historyItem, isDashboard = false }: ChatListProps) {
  const { t } = useTranslation('common')
  const { user } = useContext(UserContext)
  const { mutate, isPending } = usePostMessage()
  const [messages, setMessages] = useState<IMessage[]>([])
  const [historyId, setHistoryId] = useState<number>(0)
  const [sendMessage, setSendMessage] = useState<string>('')
  const messagesEndRef = useRef<null | HTMLDivElement>(null)
  const event = new CustomEvent('updateHistory')

  useEffect(() => {
    if (historyItem) {
      const parseData = JSON.parse(historyItem.data.chats)
      const sortData = parseData.sort(
        (a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
      )
      setMessages(sortData)
      setHistoryId(historyItem.data.id)
    }
    else {
      setMessages([])
      setHistoryId(0)
    }
  }, [historyItem])

  useEffect(() => {
    if (isPending) {
      const array = {
        id: typingMessageId,
        text: t('thinkingAnswer'),
        createdAt: new Date(),
        user: {
          id: '',
        },
      }
      setMessages(oldArray => [...oldArray, array])
    }
  }, [isPending])

  const renderMessage = (item: IMessage, idx: string) => {
    return (
      <ChatBubbleView
        key={idx}
        message={item}
        userId={user?.userData?.handle ?? ''}
        onDocumentClick={onTapPDF}
        isDashboard={isDashboard}
      />
    )
  }

  const chatEmptyView = () => (
    <ChatEmpty
      onPressMessage={async (item: string) => {
        const localHistoryData = await getStoreData<History>(Stores.History)
        const id = localHistoryData?.length + 1
        setHistoryId(id)
        addMessageDB(id, item)
        postMessageToServer(item, id)
      }}
    />
  )

  const onTapPDF = async (item: MediaItemDto) => {
    if (!item.handle) {
      console.error('Invalid item handle')
      return
    }

    const fileName = `${item.handle.replace(/:/g, '_')}.pdf`

    // Optional: Implement caching logic here if you want to store files locally
    // For simplicity, we'll proceed without persistent storage

    // Attempt to open the PDF
    openPDF(fileName, item)
  }

  const openPDF = async (fileName: string, item: MediaItemDto) => {
    // Assuming the PDF is accessible via a URL
    const pdfUrl = `${APIConst.baseURL}/${APIConst.mediaUpload}/${item.handle}`

    // window.open(pdfUrl, '_blank')

    const response = await APIFunctions.GET(`${APIConst.mediaUpload}/${item.handle}`, {
      responseType: 'blob',
    })

    if (response) {
      const url = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', item.name ?? fileName)
      document.body.appendChild(link)
      link.click()
      link.parentNode?.removeChild(link)
    }

    Logger.Log.trace('response', response)

    // fetch(pdfUrl, {
    //   method: 'GET',
    //   headers: {
    //     'Authorization': `Bearer ${getLastUsedAccessToken()}`,
    //     'Content-Type': 'application/pdf',
    //   },
    // })
    //   .then(response => response.blob())
    //   .then((blob) => {
    //     const url = window.URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }))
    //     const link = document.createElement('a')
    //     link.href = url
    //     link.setAttribute('download', item.name ?? fileName)
    //     document.body.appendChild(link)
    //     link.click()
    //     link.parentNode?.removeChild(link)
    //   })
    //   .catch((error) => {
    //     console.error('Error opening PDF:', error)
    //   })
  }

  const postMessageToServer = (message: string, id: number) => {
    const newmsg = {
      id: `${new Date().getTime()}`,
      text: message,
      createdAt: new Date(),
      user: {
        id: user?.userData?.handle ?? '',
      },
    }
    const pastMsg: IMessage[] = [...messages].slice(0, 10)
    setMessages(oldArray => [...oldArray, newmsg])
    const requestParams = {
      question: message,
      history: pastMsg.reverse().map(i => (i.user.id ? `user: ${i.text}` : `assistant: ${i.text}`)),
    }
    mutate(requestParams, {
      onSuccess: async (data: AssistantResponseDto) => {
        if (!data)
          return
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
        const newmsg: IMessage = {
          id: `${new Date().getTime()}`,
          text: data.answer!,
          createdAt: new Date(),
          user: {
            id: '',
          },
          citations: data.citations,
        }
        const localHistoryData = await getStoreData<History>(Stores.History)
        setMessages((previousMessages: IMessage[]) => {
          const filter = previousMessages.filter(obj => obj.id !== typingMessageId)
          const array = [...[newmsg], ...filter]
          const toUpdate = localHistoryData.filter((obj: History) => obj?.id === id)
          if (toUpdate && toUpdate.length > 0) {
            const data = {
              id,
              question: toUpdate[0].question,
              chats: JSON.stringify(array),
              createdDate: `${toUpdate[0].createdDate}`,
            }
            updateData(id, data)
          }
          return [...filter, newmsg]
        })
      },
    })
  }

  const updateData = async (id: number, data: any) => {
    await updateDB(Stores.History, id, data)
    window.dispatchEvent(event)
  }

  const onSend = async () => {
    let idForHistory
    if (messages.length > 0) {
      idForHistory = historyId
    }
    else {
      const localHistoryData = await getStoreData<History>(Stores.History)
      const id = localHistoryData?.length + 1
      setHistoryId(id)
      idForHistory = id
      addMessageDB(id, sendMessage)
    }
    setSendMessage('')
    postMessageToServer(sendMessage, idForHistory)
  }

  const addMessageDB = async (id: number, text: string) => {
    try {
      const data = {
        id,
        question: text,
        chats: JSON.stringify(messages),
        createdDate: `${new Date().getTime()}`,
      }
      await addDB(Stores.History, data)
      window.dispatchEvent(event)
    }
    catch (err: unknown) {
      if (err instanceof Error) {
        console.log('add data : ', err.message)
      }
      else {
        console.log('Something went wrong')
      }
    }
  }

  return (
    <>
      <div className="flex flex-col flex-1 border border-stroke rounded-md m-5 overflow-y-auto scrollbar">
        <div className="flex flex-1" ref={messagesEndRef}>
          <ul className="flex flex-col-reverse flex-1 pt-4">
            <FlatList
              list={messages}
              renderItem={renderMessage}
              reversed
              renderWhenEmpty={() => {
                if (isDashboard) {
                  return (
                    <div className="flex flex-col flex-1 justify-center items-center text-center px-3">
                      <CustomText variant="smPoppinsSemiBold">{t('giveAnswersTime')}</CustomText>
                      <CustomText className="pt-2" variant="xsmPoppinsRegular">
                        {t('giveAnswersDes')}
                      </CustomText>
                    </div>
                  )
                }
                else {
                  return chatEmptyView()
                }
              }}
            />
          </ul>
        </div>
      </div>
      <div className="border border-stroke mx-5 mb-5 p-4 rounded-md">
        <TextWithIcon
          leftIcon={(
            <input
              type="text"
              className="w-full mr-5 pl-1 outline-white"
              placeholder={t('wantToAskToday')}
              value={sendMessage}
              onChange={(e) => {
                setSendMessage(e.target.value)
              }}
            />
          )}
          rightIcon={(
            <button
              onClick={() => {
                if (sendMessage?.length > 0) {
                  onSend()
                }
              }}
            >
              <SendMessage className="fill-secondary" />
            </button>
          )}
        />
      </div>
    </>
  )
}
