import { useMutation } from '@tanstack/react-query'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'

interface Params {
  userIdentityValue?: string
  password?: string
}

export function usePutPassword() {
  return useMutation({
    mutationFn: async (params: Params) => {
      const result = await APIFunctions.PUT(APIConst.employeesPerformance.resetPassword, params)
      return result
    },
    onSuccess(data) {
      alert(data?.message)
    },
  })
}
