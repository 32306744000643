import * as React from 'react'

export function ReminderIcon({ className = 'stroke-primary' }) {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" className={className}>
      <path
        d="M3 5.5L5 3.5M21 5.5L19 3.5M12 8.5V13.5M12 16.5H12.01M20 12.5C20 16.9183 16.4183 20.5 12 20.5C7.58172 20.5 4 16.9183 4 12.5C4 8.08172 7.58172 4.5 12 4.5C16.4183 4.5 20 8.08172 20 12.5Z"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
