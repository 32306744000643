import { APIConst } from '@inphiz/api'
import { ERecommendedActionSeverity } from '@inphiz-shared/shared-models/src/F_RecomededActions/e-recommended-action-severity'
import { getActionDescription } from '../../../helpers'
import type { RecommendedActionCardItem } from '../../../packages/api-web/Employee'
import { CustomImg, CustomText } from '../../atoms'

interface Params {
  item?: RecommendedActionCardItem
  total?: number
  status: number
  className?: string
}

export function EmployeeActionCard({ item, total, status, className }: Params) {
  const getStatus = (status: number) => {
    switch (status) {
      case ERecommendedActionSeverity.Critical:
        return { title: 'High priority', color: 'bg-expired', textColor: '!text-[#DD5965]' }
      case ERecommendedActionSeverity.Recommended:
        return { title: 'Medium priority', color: 'bg-awaiting', textColor: 'text-yellow' }
      case ERecommendedActionSeverity.Other:
        return { title: 'Low priority', color: 'bg-missing', textColor: 'text-grey' }
      default:
        return { title: '', color: '', textColor: '' }
    }
  }

  return (
    <div className={`flex-1 overflow-hidden relative z-0 ${total && total > 1 && 'pl-4'} pb-4`}>
      {total && total > 1 && (
        <div className="top-2 right-2 flex-1 h-[calc(100%-1rem)] w-[calc(100%-1rem)] border border-stroke rounded-xl absolute -z-10" />
      )}
      <div className={`border border-stroke rounded-xl p-4 bg-white overflow-hidden ${className}`}>
        <div id="parentView" className="flex items-center mb-4">
          <CustomImg
            name={item?.title ?? ''}
            imgSrc={
              item?.entityImageHandle
                ? `${APIConst.baseURL}/${APIConst.mediaUpload}/${item?.entityImageHandle}`
                : ''
            }
            altText="user_image"
            className="h-9 w-9 rounded-full"
            imgClassName="rounded-full"
          />
          <CustomText variant="smPoppinsSemiBold" className="ml-2">
            {item?.title ?? ''}
          </CustomText>
        </div>
        <div className="flex items-center mb-4 gap-2">
          <CustomText
            variant="smPoppinsSemiBold"
            className={`${getStatus(status)?.color} ${getStatus(status)?.textColor} px-2 py-1 rounded-md`}
          >
            {getStatus(status)?.title}
          </CustomText>
          {/* <CustomText variant="smPoppinsRegular" className="flex-1">
            Deadline har passerat
          </CustomText> */}
        </div>
        <div className="flex flex-1 items-start overflow-hidden">
          <div className="mr-3 p-2 border border-stroke rounded-lg">
            {getActionDescription(item?.type ?? 0)?.icon}
          </div>
          <div className="overflow-hidden">
            <CustomText
              variant="mdPoppinsSemiBold"
              wrapText={false}
              className="text-ellipsis overflow-hidden"
            >
              {getActionDescription(item?.type ?? 0)?.title}
            </CustomText>
            <CustomText
              variant="smPoppinsRegular"
              wrapText={false}
              className="text-ellipsis overflow-hidden"
            >
              {item?.additionalData ?? ''}
            </CustomText>
          </div>
        </div>
      </div>
    </div>
  )
}
