import { Bar } from 'react-chartjs-2'
import { Chart, registerables } from 'chart.js'

Chart.register(...registerables)

interface Params {
  minYAxis?: number
  maxYAxis?: number
  gapYAxis?: number
  labels: string[] | []
  datasets: any[] | []
}

export function GroupBarChart({ minYAxis = 0, maxYAxis, gapYAxis, labels, datasets }: Params) {
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          maxRotation: 45,
          minRotation: 45,
        },
      },
      y: {
        stacked: true,
        min: minYAxis,
        max: maxYAxis,
        stepSize: gapYAxis,
      },
    },
  }

  const data = {
    labels,
    datasets,
  }

  return <Bar options={options} data={data} />
}
