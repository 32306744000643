import type { IBaseLogger } from './IBaseLogger'
import type { ILogger } from './ILogger'
import { InteractionManager } from 'react-native'
import { logger, mapConsoleTransport } from 'react-native-logs'
import { RNLoggerImpl } from './RNLoggerImpl'

const isProd = process.env.NODE_ENV === 'production'

const LogRn = logger.createLogger({
  levels: {
    trace: 0,
    debug: 1,
    info: 2,
    warn: 3,
    error: 4,
    fatal: 5,
  },
  dateFormat: 'iso',
  transport: [mapConsoleTransport],
  fixedExtLvlLength: false,
  printLevel: true,
  printDate: true,
  async: false,
  asyncFunc: InteractionManager.runAfterInteractions,
  severity: 'trace',
  enabled: !isProd,
  stringifyFunc: RNLoggerImpl.stringifyFunc,
})

// LogRn.debug('logger initialized', { someobjec: 'somevalue', objectValue: { a: 1, b: 2 } })

// const log: ILogFn = (...args: any[]): void => {
//   /// console.log('ilogfn')
//   console.log(
//     JSON.stringify(args, (key, value) => {
//       if (value instanceof Error) {
//         return serializeError(value)
//       }
//       return value
//     }),
//   )
// }

// function serializeError(error: any) {
//   return {
//     message: error.message,
//     name: error.name,
//     stack: error.stack,
//   }
// }

class RNLogger implements IBaseLogger {
  fatal = LogRn.fatal
  error = LogRn.error
  warn = LogRn.warn
  info = LogRn.info
  debug = LogRn.debug
  trace = LogRn.trace
}

const reactNativeLogger = new RNLogger() as IBaseLogger

export const Logger: ILogger = {
  Log: reactNativeLogger,
}
