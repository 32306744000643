import { useTranslation } from 'react-i18next'
import type { KeyboardEvent } from 'react'
import { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import * as F_CertificatesConstants from '@inphiz-shared/shared-models/src/F_Certificates/CertificateConstants'
import { useNavigate } from 'react-router-dom'
import {
  BorderButton,
  CertificateModal,
  CustomButton,
  CustomMultiSelectMenu,
  CustomPagination,
  CustomTable,
  CustomText,
  Spinner,
  TextWithIcon,
} from '../../component'
import { CloseIcon, RightIcon, SearchIcon, ShareIcon } from '../../icons'
import { useRolePositions, useRoleSpecializations } from '../../packages/api-web/RoleManagement'
import {
  useEmployeesStateList,
  useManagerFilterList,
  useOrganizationUnits,
} from '../../packages/api-web'
import {
  useCertificateList,
  useDepartmentList,
  usePostEmployeeList,
} from '../../packages/api-web/Employee'

export interface filterItem {
  id: number
  header: string
  data: any[]
  selectedData: any[]
  selectedDate?: any[]
  selectedOperation?: any
  selectedStatus?: any[]
  selectedStates?: any[]
}

function EmployeeList() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const [searchTxt, setSearchTxt] = useState('')
  // const [itemPerPage, setItemPage] = useState(20)
  // const [skipItem, setSkipItem] = useState(0)
  const [filterInput, setFilterInput] = useState({
    cursor: '0',
    take: 20,
    isOrderAscending: false,
    parametersWithValues: {
      HideWithoutJobTitle: true,
      IncludeOrganizationUnit: true,
    },
  })
  const { isLoading: isLoadJobTitle, data: jobTitle } = useRolePositions({
    itemPerPage: 2147483647,
    skip: 0,
  })
  const { isLoading: isLoadJobRole, data: jobRole } = useRoleSpecializations({
    itemPerPage: 2147483647,
    skip: 0,
  })
  const { isLoading: isLoadOrganization, data: organizationList } = useOrganizationUnits()
  const { isLoading: isDepartment, data: departmentList } = useDepartmentList()
  const [managerSearch, setManagerSearch] = useState('')
  const {
    data: managerList,
    isLoading: isManagerList,
    refetch: refetchManagerList,
  } = useManagerFilterList({ filterTxt: managerSearch })
  const { data: stateList, isLoading: isStateLoad } = useEmployeesStateList()
  const { data: certificateList, isLoading: isCertificateLoad } = useCertificateList()
  const [filterData, setFilterData] = useState<filterItem[]>([
    {
      id: 0,
      header: t('filterOnly').toUpperCase(),
      data: [
        { handle: 1, title: t('userType') },
        { handle: 2, title: t('compliance') },
        { handle: 3, title: t('jobTitle') },
        { handle: 4, title: t('reportsTo') },
        { handle: 5, title: t('jobRole') },
        { handle: 6, title: t('organization') },
        { handle: 7, title: t('department') },
        { handle: 8, title: t('state') },
        { handle: 9, title: t('certificates') },
      ],
      selectedData: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    },
    {
      id: 1,
      header: t('userType'),
      data: [
        {
          handle: 1,
          title: 'All users',
          paramsValues: { AllUsers: true, HideWithoutJobTitle: false },
        },
        { handle: 2, title: 'On-Premise', paramsValues: { IsOnPremiseUser: true } },
        {
          handle: 3,
          title: 'Archived users',
          paramsValues: { ShowDeleted: true },
          groups: { parameterName: 'IsDeleted', values: [true] },
        },
        { handle: 4, title: 'Resource', paramsValues: { IsResourceUser: true } },
        {
          handle: 5,
          title: 'Non-licensed users',
          groups: { parameterName: 'AssignedLicenses', values: [''] },
        },
        {
          handle: 6,
          title: 'Guests accounts',
          paramsValues: { ShowGuests: true },
          groups: { parameterName: 'IsGuestAccount', values: [true] },
        },
        {
          handle: 7,
          title: 'Without job title',
          paramsValues: { HideWithoutJobTitle: false },
          groups: { parameterName: 'JobTitleHandle', values: [''] },
        },
      ],
      selectedData: [],
    },
    {
      id: 2,
      header: t('compliance'),
      data: [
        {
          handle: 1,
          title: 'Not approved policies',
          groups: { parameterName: 'AcceptAllPolicies', values: [false] },
        },
        {
          handle: 2,
          title: 'Not approved certificates',
          groups: {
            parameterName: 'CertificateStatus',
            values: [F_CertificatesConstants.Statuses.pending],
          },
        },
        {
          handle: 3,
          title: 'Approved health proof',
          groups: {
            operation: 'And',
            parameterName: 'CertificateStatusWithTypeKey',
            values: ['Health', 'Pending'],
          },
        },
        {
          handle: 4,
          title: 'Not onboarded',
          groups: { parameterName: 'OnBoarded', values: [false] },
        },
        {
          handle: 5,
          title: 'Not finished onboarding',
          groups: { parameterName: 'NotFinishedOnboarding', values: [true] },
        },
        {
          handle: 6,
          title: 'Not logged in',
          groups: { parameterName: 'IsLoggedIn', values: [false] },
        },
        {
          handle: 7,
          title: 'Job title requirements',
          paramsValues: { HasMissingJobTitleRequirements: true },
        },
        {
          handle: 8,
          title: 'Job role requirements',
          paramsValues: { HasMissingJobRoleRequirements: true },
        },
      ],
      selectedData: [],
    },
    {
      id: 3,
      header: t('jobTitle'),
      data: [],
      selectedData: [],
    },
    {
      id: 4,
      header: t('reportsTo'),
      data: [],
      selectedData: [],
    },
    {
      id: 5,
      header: t('jobRole'),
      data: [],
      selectedData: [],
    },
    {
      id: 6,
      header: t('organization'),
      data: [],
      selectedData: [],
    },
    {
      id: 7,
      header: t('department'),
      data: [],
      selectedData: [],
    },
    {
      id: 8,
      header: t('state'),
      data: [],
      selectedData: [],
    },
    {
      id: 9,
      header: t('certificates'),
      data: [],
      selectedData: [],
      selectedDate: [],
      selectedOperation: { title: 'And', handle: 'And' },
      selectedStatus: [],
      selectedStates: [],
    },
  ])
  const [employeeList, setEmployeeList] = useState({
    items: [],
    totalCount: 0,
  })
  const {
    mutate: employeeMutate,
    isPending: isEmployeeListLoad,
    data: empList,
  } = usePostEmployeeList()

  const tableHeaderList = [
    { title: t('name'), id: 1 },
    { title: t('jobTitle'), id: 2 },
    { title: t('reportsTo'), id: 3 },
    { title: t('organization'), id: 4 },
    { title: t('department'), id: 5 },
    { title: t('state'), id: 6 },
  ]

  useMemo(() => {
    const newFilter: any = [...filterData]
    const jobTitleIndex = newFilter.findIndex((obj: any) => obj.id == 3)
    const managerIndex = newFilter.findIndex((obj: any) => obj.id == 4)
    const jobRoleIndex = newFilter.findIndex((obj: any) => obj.id == 5)
    const organizationIndex = newFilter.findIndex((obj: any) => obj.id == 6)
    const departmentIndex = newFilter.findIndex((obj: any) => obj.id == 7)
    const stateListIndex = newFilter.findIndex((obj: any) => obj.id == 8)
    const certificateIndex = newFilter.findIndex((obj: any) => obj.id == 9)
    newFilter[jobTitleIndex].data = jobTitle?.items
    newFilter[managerIndex].data = managerList?.items?.map(i => ({ ...i, title: i.displayName }))
    newFilter[jobRoleIndex].data = jobRole?.items
    newFilter[organizationIndex].data = organizationList?.items
    newFilter[departmentIndex].data = departmentList?.items
    newFilter[stateListIndex].data = stateList?.items
    newFilter[certificateIndex].data = certificateList?.items
    setFilterData(newFilter)
  }, [jobTitle, managerList, jobRole, organizationList, departmentList, stateList, certificateList])

  const handleFilterChange = (newFilterTxt: string) => {
    setManagerSearch(newFilterTxt)
    setTimeout(() => {
      refetchManagerList()
    }, 500)
  }

  useEffect(() => {
    if (!isEmployeeListLoad) {
      setEmployeeList(empList)
    }
  }, [empList])

  useEffect(() => {
    employeeMutate(filterInput)
  }, [filterInput])

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const data: any = {
        ...filterInput,
        cursor: '0',
        take: 20,
        filter: searchTxt,
      }
      setFilterInput(data)
    }
  }

  const predicateGroupData = (operation: string, parameterName: string, handles: string[]) => {
    return {
      operation,
      parameterName,
      predicates: handles?.map((i: any) => {
        return {
          parameter: parameterName,
          value: i,
          operator: 'Equal',
        }
      }),
    }
  }

  const onTapFilter = () => {
    const data: any = {
      ...filterInput,
      cursor: '0',
      take: 20,
      predicateGroups: [],
      parametersWithValues: {
        HideWithoutJobTitle: true,
        IncludeOrganizationUnit: true,
      },
    }
    const filterSelectedData = filterData?.filter(i => i.selectedData?.length > 0 && i.id != 0)
    const certificateData = filterData?.filter(
      i =>
        (i.selectedData?.length > 0
          || (i.selectedDate && i.selectedDate?.length > 0)
          || (i.selectedStatus && i.selectedStatus?.length > 0)
          || (i.selectedStates && i.selectedStates?.length > 0))
        && i.id != 0,
    )
    if (filterSelectedData?.length > 0 || certificateData?.length > 0) {
      const userType = filterSelectedData.find(x => x.id == 1)
      const compliance = filterSelectedData.find(x => x.id == 2)
      const jobTitle = filterSelectedData.find(x => x.id == 3)
      const managers = filterSelectedData.find(x => x.id == 4)
      const jobRole = filterSelectedData.find(x => x.id == 5)
      const organization = filterSelectedData.find(x => x.id == 6)
      const department = filterSelectedData.find(x => x.id == 7)
      const state = filterSelectedData.find(x => x.id == 8)
      const certificates = certificateData.find(x => x.id == 9)
      if (userType) {
        userType?.selectedData?.map((i) => {
          const item = userType?.data?.find(j => j.handle === i)
          Object.assign(data.parametersWithValues, item?.paramsValues)
          if (item?.groups) {
            data.predicateGroups.push(
              predicateGroupData(
                item?.groups?.operation ?? 'Or',
                item?.groups?.parameterName,
                item?.groups?.values,
              ),
            )
          }
        })
      }
      if (compliance) {
        compliance?.selectedData?.map((i) => {
          const item = compliance?.data?.find(j => j.handle === i)
          Object.assign(data.parametersWithValues, item?.paramsValues)
          if (item?.groups) {
            data.predicateGroups.push(
              predicateGroupData(
                item?.groups?.operation ?? 'Or',
                item?.groups?.parameterName,
                item?.groups?.values,
              ),
            )
          }
        })
      }
      if (jobTitle) {
        data.predicateGroups.push(
          predicateGroupData('Or', 'JobTitleHandle', jobTitle?.selectedData),
        )
      }
      if (managers) {
        data.predicateGroups.push(predicateGroupData('Or', 'ManagerHandle', managers?.selectedData))
      }
      if (jobRole) {
        data.predicateGroups.push(predicateGroupData('Or', 'JobRoleHandle', jobRole?.selectedData))
      }
      if (organization) {
        data.predicateGroups.push(
          predicateGroupData('Or', 'OrganizationUnitHandle', organization?.selectedData),
        )
      }
      if (department) {
        data.predicateGroups.push(predicateGroupData('Or', 'Department', department?.selectedData))
      }
      if (state) {
        data.predicateGroups.push(predicateGroupData('Or', 'State', state?.selectedData))
      }
      if (certificates) {
        if (certificates?.selectedDate && certificates?.selectedDate?.length > 0) {
          data.predicateGroups.push(
            predicateGroupData('Or', 'CertificateEndDateFrom', [
              moment(certificates?.selectedDate[0]).format('YYYY-MM-DD'),
            ]),
            predicateGroupData('Or', 'CertificateEndDateTo', [
              moment(certificates?.selectedDate[1]).format('YYYY-MM-DD'),
            ]),
          )
        }
        if (certificates?.selectedStatus && certificates?.selectedStatus?.length > 0) {
          data.predicateGroups.push(
            predicateGroupData('Or', 'CertificateStatus', certificates?.selectedStatus),
          )
        }
        if (certificates?.selectedStates && certificates?.selectedStates?.length > 0) {
          data.predicateGroups.push(
            predicateGroupData('Or', 'CertificateState', certificates?.selectedStates),
          )
        }
        if (certificates?.selectedData && certificates?.selectedData?.length > 0) {
          data.predicateGroups.push(
            predicateGroupData(
              certificates?.selectedOperation?.handle,
              'CertificateHandle',
              certificates?.selectedData,
            ),
          )
        }
      }
    }
    setFilterInput(data)
  }

  if (
    isLoadJobTitle
    || isLoadJobRole
    || isLoadOrganization
    || isDepartment
    || isManagerList
    || isStateLoad
    || isCertificateLoad
  ) {
    return <Spinner />
  }

  return (
    <div className="p-5 h-full flex flex-col">
      {isEmployeeListLoad && <Spinner />}
      <CustomText variant="xlPoppinsBold">{t('employeeList')}</CustomText>
      <div className="mt-6 px-5 py-3 bg-white rounded-lg flex items-center justify-between border border-stroke">
        <div className="flex items-center gap-3 flex-wrap">
          {filterData.map((item, index) => {
            if (index != 0 && !filterData[0]?.selectedData?.includes(item?.id)) {
              return
            }
            if (index == 9) {
              return (
                <div className="flex items-center gap-3" key={index}>
                  <CertificateModal
                    selectedDate={item?.selectedDate}
                    selectedOperation={item?.selectedOperation}
                    selectedStatus={item?.selectedStatus}
                    selectedStates={item?.selectedStates}
                    selectedValue={item?.selectedData}
                    menuList={item?.data ?? []}
                    onSelectedDate={(value) => {
                      const updateItem = { ...item, selectedDate: value }
                      const newFilter: any = [...filterData]
                      newFilter[index] = updateItem
                      setFilterData(newFilter)
                    }}
                    onSelectedOperation={(value) => {
                      const updateItem = { ...item, selectedOperation: value }
                      const newFilter: any = [...filterData]
                      newFilter[index] = updateItem
                      setFilterData(newFilter)
                    }}
                    onSelectedStatus={(value) => {
                      const updateItem = { ...item, selectedStatus: value }
                      const newFilter: any[] = [...filterData]
                      const indexCert = newFilter.findIndex(i => i.id == 2)
                      if (value.at(-1) === F_CertificatesConstants.Statuses.pending) {
                        newFilter[indexCert] = { ...newFilter[indexCert], selectedData: [2] }
                      }
                      else if (value.at(-1) !== F_CertificatesConstants.Statuses.pending) {
                        newFilter[indexCert] = { ...newFilter[indexCert], selectedData: [] }
                      }
                      newFilter[index] = updateItem
                      setFilterData(newFilter)
                    }}
                    onSelectedStates={(value) => {
                      const updateItem = { ...item, selectedStates: value }
                      const newFilter: any = [...filterData]
                      newFilter[index] = updateItem
                      setFilterData(newFilter)
                    }}
                    onSelectedValue={(value) => {
                      const updateItem = { ...item, selectedData: value }
                      const newFilter: any = [...filterData]
                      newFilter[index] = updateItem
                      setFilterData(newFilter)
                    }}
                    onFilterClear={() => {
                      const updateItem = {
                        ...item,
                        selectedData: [],
                        selectedDate: [],
                        selectedStatus: [],
                        selectedStates: [],
                      }
                      const newFilter: any = [...filterData]
                      newFilter[index] = updateItem
                      setFilterData(newFilter)
                    }}
                    buttonComponent={(
                      <div>
                        <TextWithIcon
                          text={item?.header}
                          rightIcon={<RightIcon className="h-4 w-4 rotate-90 ml-4" />}
                          textVariant="smPoppinsRegular"
                        />
                        {item?.selectedDate
                        && item?.selectedStatus
                        && item?.selectedStates
                        && [
                          ...item?.selectedData,
                          ...item?.selectedDate,
                          ...item?.selectedStatus,
                          ...item?.selectedStates,
                        ].length > 0 && (
                          <div className="absolute -top-4 right-0 bg-primary h-4 w-4 rounded-full justify-center items-center flex">
                            <CustomText variant="xsmPoppinsRegular" textColor="text-textWhite">
                              {`${[...item?.selectedData, ...item?.selectedStatus, ...item?.selectedStates].length + (item?.selectedDate?.length > 0 ? item?.selectedDate?.length - 1 : 0)}`}
                            </CustomText>
                          </div>
                        )}
                      </div>
                    )}
                  />
                </div>
              )
            }
            return (
              <div className="flex items-center gap-3" key={index}>
                <CustomMultiSelectMenu
                  key={item.id}
                  selectedValue={item?.selectedData}
                  menuList={item?.data ?? []}
                  onSelectedValue={(value) => {
                    let updateItem = {}
                    const newFilter: any[] = [...filterData]
                    if (item.id == 1) {
                      if (value.at(-1) == 1) {
                        updateItem = { ...item, selectedData: [1] }
                      }
                      else {
                        updateItem = { ...item, selectedData: value.filter(item => item !== 1) }
                      }
                    }
                    else {
                      updateItem = { ...item, selectedData: value }
                    }
                    if (item.id == 2) {
                      const index = newFilter.findIndex(i => i.id == 9)
                      if (value.at(-1) == 2) {
                        newFilter[index] = {
                          ...newFilter[index],
                          selectedStatus: [F_CertificatesConstants.Statuses.pending],
                        }
                      }
                      else if (value.at(-1) !== 2) {
                        newFilter[index] = { ...newFilter[index], selectedStatus: [] }
                      }
                      if (value.at(-1) == 5) {
                        updateItem = { ...item, selectedData: value.filter(item => item !== 6) }
                      }
                      else if (value.at(-1) == 6) {
                        updateItem = { ...item, selectedData: value.filter(item => item !== 5) }
                      }
                    }
                    else {
                      updateItem = { ...item, selectedData: value }
                    }
                    newFilter[index] = updateItem
                    setFilterData(newFilter)
                  }}
                  onFilterClear={() => {
                    const updateItem = { ...item, selectedData: [] }
                    const newFilter: any = [...filterData]
                    newFilter[index] = updateItem
                    setFilterData(newFilter)
                  }}
                  onFilterChange={filterTxt => item.id == 4 && handleFilterChange(filterTxt)}
                  buttonComponent={(
                    <div>
                      <TextWithIcon
                        text={item?.header}
                        rightIcon={<RightIcon className="h-4 w-4 rotate-90 ml-4" />}
                        textVariant="smPoppinsRegular"
                      />
                      {index != 0 && item?.selectedData.length > 0 && (
                        <div className="absolute -top-4 right-0 bg-primary h-4 w-4 rounded-full justify-center items-center flex">
                          <CustomText variant="xsmPoppinsRegular" textColor="text-textWhite">
                            {item?.selectedData.length.toString()}
                          </CustomText>
                        </div>
                      )}
                    </div>
                  )}
                />
                <div className="h-8 bg-black/10 w-[1px]" />
              </div>
            )
          })}
        </div>
        <BorderButton
          children={t('filter')}
          className="px-7 self-end"
          onClick={() => {
            onTapFilter()
          }}
        />
      </div>
      <div className="mt-6 py-6 px-5 bg-white rounded-lg border border-stroke flex flex-col overflow-hidden">
        <div className="flex flex-col sm:flex-row mb-4 gap-4">
          <CustomText className="mr-auto self-center" wrapText={false} variant="xlPoppinsBold">
            {`${t('total')}: ${employeeList?.totalCount ?? 0}`}
          </CustomText>
          <div className="flex flex-1 items-center justify-between">
            <div className="flex items-center w-auto gap-4">
              <SearchIcon />
              <input
                type="text"
                value={searchTxt}
                placeholder={t('searchPlaceholder')}
                className="outline-0 flex-1 min-w-[100px]"
                onChange={e => setSearchTxt(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              {searchTxt && (
                <button
                  onClick={() => {
                    setSearchTxt('')
                    setTimeout(() => {
                      const data: any = {
                        ...filterInput,
                        cursor: '0',
                        take: 20,
                        filter: '',
                      }
                      setFilterInput(data)
                    }, 1000)
                  }}
                >
                  <CloseIcon className="h-5" />
                </button>
              )}
            </div>
            <div className="flex">
              <CustomButton
                children={t('create')}
                textVariant="smPoppinsMedium"
                className="px-4 sm:px-8 mr-6 bg-secondary"
                onClick={() => navigate('/employee/create')}
              />
              <div className="flex items-center">
                <ShareIcon />
                <CustomText variant="smPoppinsMedium" className="ml-2">
                  Export
                </CustomText>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col overflow-hidden rounded-lg border border-stroke">
          <div className="flex-1 overflow-x-auto overflow-y-auto scrollbar bg-white">
            <CustomTable
              tableHeaderList={tableHeaderList}
              tableDataList={employeeList?.items}
              displayKeys={[
                'displayName',
                'userJobTitles',
                'userMetadata:Manager',
                'userOrganizationUnits',
                'department',
                'userMetadata:State',
              ]}
              keyMapping={{
                userJobTitles: ['title'],
                userOrganizationUnits: ['title'],
              }}
              onClick={(item) => {
                navigate(`/employee/${item.handle}`)
              }}
            />
          </div>
          {employeeList?.items && employeeList?.items?.length > 0 && (
            <CustomPagination
              skip={Number.parseInt(filterInput?.cursor)}
              totalItem={employeeList?.totalCount}
              itemPerPage={filterInput?.take}
              onChange={(value) => {
                const params = {
                  ...filterInput,
                  take: value,
                }
                setFilterInput(params)
              }}
              onPagePress={(index) => {
                const params = {
                  ...filterInput,
                  cursor: `${(index - 1) * filterInput?.take}`,
                }
                setFilterInput(params)
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default EmployeeList
