import React, { useContext, useEffect, useState } from 'react'
import { appConfig } from '@inphiz/app-config/src/AppConfig'
import { msalInstance } from '@inphiz/api-web/src/Networking/ApiWebAuthProvider'
import { EncryptedStorageKey } from '@inphiz/api'
import { useNavigate } from 'react-router-dom'
import { BellIcon, HorizontalDots, MenuIcon, SearchIcon } from '../../../icons'
import { CustomMenu } from '../CustomMenu'
import { INITIAL_USER_DATA, UserContext } from '../../../contexts/UserContext'
import { queryClient } from '../../../App'
import i18n from '../../../translations'

import F_WelcomeScreens_CompanyLogoIcon from '../../../assets/F_WelcomeScreens/CompanyLogoIcon'

interface CustomHeaderProps {
  sidebarToggle: boolean
  setSidebarToggle: React.Dispatch<React.SetStateAction<boolean>>
  setRightSidebarToggle: React.Dispatch<React.SetStateAction<boolean>>
}

export function CustomHeader({
  sidebarToggle,
  setSidebarToggle,
  setRightSidebarToggle,
}: CustomHeaderProps) {
  const { setUser } = useContext(UserContext)
  const navigate = useNavigate()
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language)

  useEffect(() => {
    setCurrentLanguage(i18n.language)
  }, [i18n.language])

  const onMenuPress = async (item: any) => {
    switch (item.id) {
      case 1:
        const newLanguage = currentLanguage === 'en' ? 'sv' : 'en'
        i18n.changeLanguage(newLanguage)
        localStorage.setItem(EncryptedStorageKey.languageCode, newLanguage)
        setCurrentLanguage(newLanguage)
        break
      case 2:
        setUser(INITIAL_USER_DATA)
        queryClient.removeQueries({ type: 'inactive' })
        await msalInstance.logoutPopup({
          mainWindowRedirectUri: appConfig.properties.webApp.postLogoutRedirectUri,
        })
        localStorage.removeItem(EncryptedStorageKey.languageCode)
        break
      default:
        break
    }
  }

  // Update menu list when language changes
  const menuList = [
    {
      title: currentLanguage === 'en' ? i18n.t('swedish') : i18n.t('english'),
      id: 1,
    },
    {
      title: i18n.t('logoutTxt'),
      id: 2,
    },
  ]

  return (
    <div className="fixed z-40 top-0 w-full bg-primary">
      <div className="mx-auto flex items-center justify-between px-4 py-4 sm:px-6 xl:px-8">
        <button
          className="inline-flex items-center"
          onClick={() => {
            setSidebarToggle(!sidebarToggle)
          }}
        >
          <MenuIcon className="h-6 w-6 mr-4 text-white" />
          <F_WelcomeScreens_CompanyLogoIcon width={80} height={30} />
          {/* <img src={inphiz_logo} style={{ height: 30, width: 80 }} alt="app_logo" /> */}
        </button>
        <div className="hidden xl:block">
          <div className="flex items-center space-x-8">
            <button
              onClick={() => {
                navigate('/chat')
              }}
            >
              <SearchIcon className="rotate-90 text-white" />
            </button>
            <button
              onClick={() => {
                setRightSidebarToggle(true)
              }}
            >
              <BellIcon className="text-white" />
            </button>
            <CustomMenu
              className="right-0"
              buttonComponent={<HorizontalDots className="text-white" />}
              menuList={menuList}
              onClick={onMenuPress}
            />
          </div>
        </div>
        <div className="xl:hidden inline-flex space-x-4">
          <button
            onClick={() => {
              navigate('/chat')
            }}
          >
            <SearchIcon className="rotate-90 text-white" />
          </button>
          <button
            onClick={() => {
              setRightSidebarToggle(true)
            }}
          >
            <BellIcon className="text-white" />
          </button>
          <CustomMenu
            className="right-0"
            buttonComponent={<HorizontalDots className="text-white" />}
            menuList={menuList}
            onClick={onMenuPress}
          />
        </div>
      </div>
    </div>
  )
}
