import type { ValueType } from 'rsuite/esm/Checkbox'
import React, { useState } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import {
  BorderButton,
  CustomDropDown,
  CustomMultiSelectMenu,
  CustomText,
  LineChart,
  TextWithIcon,
} from '../../component'
import { RightIcon, RoundArrowIcon, StarIcon } from '../../icons'

interface FilterData {
  id: number
  header: string
  data: any[] | []
  selectedData: ValueType[]
}

function ControlCenterENPS() {
  const { t } = useTranslation('common')
  const [filterData, setFilterData] = useState<FilterData[]>([
    {
      id: 1,
      header: 'Organization',
      data: [],
      selectedData: [],
    },
    {
      id: 2,
      header: 'Work Area',
      data: [],
      selectedData: [],
    },
    {
      id: 4,
      header: 'Manager',
      data: [],
      selectedData: [],
    },
  ])
  const chartsLabel = [
    { color: 'bg-chartGreen', value: '63', title: 'Detractors 9-10' },
    { color: 'bg-chartRed', value: '29', title: 'Detractors 0-6' },
    { color: 'bg-chartYellow', value: '8', title: 'Passives 7-8' },
  ]
  const testimonials = [
    { id: 1, text: 'Ett bra företag som tar hand sina anställda.' },
    { id: 2, text: 'Magiskt 👍' },
    {
      id: 3,
      text: 'Varierande jobb, chans till stor personlig utveckling & ett riktigt härligt gäng.',
    },
    { id: 4, text: 'Trevlig, bra information & smidigt!' },
    { id: 5, text: 'Spännande och mycket nya intryck att komma tillbaks in i koncernen.' },
    { id: 6, text: 'Bästa företaget!!' },
    { id: 7, text: 'Det är ett väldigt bra gäng här som jag uppskattar att få arbeta med!' },
    { id: 8, text: 'Mycket bra rekrytering, smidigt & inget krångel.' },
    { id: 9, text: 'Ja jag tycker att det är en professionell inställning på arbetsplatsen.' },
  ]
  const firstColumn = testimonials.filter((_, index) => index % 3 === 0)
  const secondColumn = testimonials.filter((_, index) => index % 3 === 1)
  const thirdColumn = testimonials.filter((_, index) => index % 3 === 2)

  return (
    <div className="p-5 h-full flex flex-col">
      <CustomText variant="xlPoppinsBold">eNPS - Employee Net Promoter Score</CustomText>
      <div className="overflow-y-auto scrollbar flex flex-col mt-6">
        {/* Filter view */}
        <div className="px-5 py-3 bg-white border-stroke border rounded-lg flex items-center justify-between">
          <div className="flex items-center gap-3 flex-wrap">
            {filterData.map((item, index) => (
              <React.Fragment key={index}>
                <CustomMultiSelectMenu
                  selectedValue={item?.selectedData}
                  menuList={item.data}
                  onSelectedValue={(value) => {
                    const updateItem = { ...item, selectedData: value }
                    const newFilter: FilterData[] = [...filterData]
                    newFilter[index] = updateItem
                    setFilterData([...newFilter])
                  }}
                  onFilterClear={() => {
                    const updateItem = { ...item, selectedData: [] }
                    const newFilter: FilterData[] = [...filterData]
                    newFilter[index] = updateItem
                    setFilterData([...newFilter])
                  }}
                  buttonComponent={(
                    <div className="flex items-center">
                      <TextWithIcon
                        text={item.header}
                        rightIcon={<RightIcon className="h-4 w-4 rotate-90 ml-3" />}
                        textVariant="smPoppinsRegular"
                      />
                      {item?.selectedData?.length > 0 && (
                        <div className="absolute -top-4 right-0 bg-primary h-4 w-4 rounded-full flex justify-center items-center">
                          <CustomText variant="xsmPoppinsRegular" textColor="text-textWhite">
                            {item?.selectedData.length.toString()}
                          </CustomText>
                        </div>
                      )}
                    </div>
                  )}
                />
                <div className="h-8 bg-black/10 w-[1px]" />
              </React.Fragment>
            ))}
          </div>
          <BorderButton children={t('filter')} className="self-end w-auto" onClick={() => {}} />
        </div>
        <div className="mt-6 px-5 py-3 bg-white border-stroke border rounded-lg">
          <div className="flex items-center justify-between">
            <CustomText variant="lgPoppinsSemiBold">{t('overview')}</CustomText>
            <div className="flex gap-5">
              <CustomDropDown
                value={{}}
                className="!mb-3"
                data={[]}
                labelField="displayName"
                valueField="handle"
                searchable={false}
                onSelect={(_, item) => {}}
                onClean={() => {}}
              />
              <CustomDropDown
                value={{}}
                className="!mb-3"
                data={[]}
                labelField="displayName"
                valueField="handle"
                searchable={false}
                onSelect={(_, item) => {}}
                onClean={() => {}}
              />
            </div>
          </div>
          {/* eNPS overview 3 section */}
          <div className="mt-3 grid grid-cols-1 lg:grid-cols-3 gap-3">
            {/* Section 1 */}
            <div className="flex flex-col rounded-lg border border-stroke justify-between">
              <CustomText variant="mdPoppinsSemiBold" className="p-3 border-b border-stroke">
                Question 2. A smooth onboarding
              </CustomText>
              <CustomText variant="lgPoppinsSemiBold" className="m-4">
                How did you experience the onboarding process?
              </CustomText>
              <CustomText variant="mdPoppinsRegular" className="m-3 p-4 bg-bubble italic">
                This score is calculated using the responses from the pulse examination.
              </CustomText>
            </div>
            {/* Section 2 */}
            <div className="rounded-lg border border-stroke">
              <CustomText variant="mdPoppinsSemiBold" className="p-3 border-b border-stroke">
                Point
              </CustomText>
              <div className="flex items-center p-3">
                <StarIcon />
                <CustomText variant="mdPoppinsSemiBold" className="ml-2">
                  Very good
                </CustomText>
              </div>
              <div className="flex flex-col text-center">
                <CustomText variant="mdPoppinsSemiBold">0</CustomText>
                <Doughnut
                  options={{
                    plugins: {
                      legend: {
                        display: false,
                      },
                    },
                    aspectRatio: 3,
                    cutout: '90%',
                  }}
                  data={{
                    labels: ['Dataset 1', 'Dataset 2'],
                    datasets: [
                      {
                        label: 'My First Dataset',
                        data: [75, 25],
                        backgroundColor: ['#71CC55', '#D4EBCD'],
                        hoverOffset: 2,
                        circumference: 180,
                        rotation: 270,
                      },
                    ],
                  }}
                />
                <div className="flex self-center justify-between my-3 w-4/5">
                  <CustomText variant="mdPoppinsSemiBold">-100</CustomText>
                  <CustomText variant="mdPoppinsSemiBold">100</CustomText>
                </div>
              </div>
            </div>
            {/* Section 3 */}
            <div className="rounded-lg border border-stroke">
              <CustomText variant="mdPoppinsSemiBold" className="p-3 border-b border-stroke">
                eNPS distribution in percentage
              </CustomText>
              <div className="p-3">
                <CustomText variant="mdPoppinsSemiBold">eNPS distribution %</CustomText>
                <div className="my-3 flex items-center">
                  <div className="w-1/3">
                    <Doughnut
                      options={{
                        plugins: {
                          legend: {
                            display: false,
                          },
                        },
                        aspectRatio: 1,
                        cutout: '80%',
                      }}
                      data={{
                        labels: ['Dataset 1', 'Dataset 2', 'Dataset 3'],
                        datasets: [
                          {
                            label: 'My First Dataset',
                            data: [63, 29, 8],
                            backgroundColor: ['#71CC55', '#ED6E84', '#F7CF6B'],
                            hoverOffset: 2,
                          },
                        ],
                      }}
                    />
                  </div>
                  <div className="ml-2">
                    {chartsLabel?.map((item) => {
                      return (
                        <div className="flex items-center gap-2">
                          <div className={`${item.color} h-4 w-4`} />
                          <CustomText variant="mdPoppinsSemiBold">{`${item?.value}%`}</CustomText>
                          <CustomText variant="mdPoppinsRegular">{`${item?.title}%`}</CustomText>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <CustomText variant="mdPoppinsRegular" className="p-4 bg-bubble italic">
                  Percentage of employees who responded: 52.4%
                </CustomText>
              </div>
            </div>
          </div>
          {/* eNPS score over time */}
          <div className="mt-5 rounded-lg border border-stroke">
            <CustomText variant="mdPoppinsSemiBold" className="p-3 border-b border-stroke">
              eNPS score over time
            </CustomText>
            <div className="flex justify-center items-center p-3">
              <LineChart
                minYAxis={-50}
                maxYAxis={100}
                gapYAxis={50}
                labels={[
                  'Oct',
                  'Nov',
                  'Dec',
                  'Jab',
                  'Feb',
                  'Mar',
                  'Apr',
                  'May',
                  'Jun',
                  'Jul',
                  'Aug',
                  'Sep',
                ]}
                datasets={[
                  {
                    label: t('budgetCost'),
                    data: [10, 20, 30, 40, 35, 25, 15, 20, 30, 20, 10, 40],
                    pointRadius: 0,
                    pointHoverRadius: 0,
                    pointBackgroundColor: 'blue',
                    tension: 0.1,
                    borderWidth: 1,
                    borderColor: 'blue',
                  },
                ]}
              />
            </div>
          </div>
        </div>
        <div className="mt-6 p-5 bg-white border-stroke border rounded-lg place-items-center">
          <CustomText variant="mdPoppinsSemiBold" className="place-self-start">
            What do your employees think?
          </CustomText>
          <div className="mt-3 p-3 rounded-lg grid grid-cols-2 md:grid-cols-3 gap-4 border border-stroke">
            <div className="grid gap-4">
              {firstColumn.map(item => (
                <CustomText
                  variant="mdPoppinsSemiBold"
                  className="p-4 rounded-lg border border-stroke"
                >
                  {item?.text}
                </CustomText>
              ))}
            </div>
            <div className="grid gap-4">
              {secondColumn.map(item => (
                <CustomText
                  variant="mdPoppinsSemiBold"
                  className="p-4 rounded-lg border border-stroke"
                >
                  {item?.text}
                </CustomText>
              ))}
            </div>
            <div className="grid gap-4">
              {thirdColumn.map(item => (
                <CustomText
                  variant="mdPoppinsSemiBold"
                  className="p-4 rounded-lg border border-stroke"
                >
                  {item?.text}
                </CustomText>
              ))}
            </div>
          </div>
          <button className="">
            <RoundArrowIcon className="mt-5 text-secondary rotate-90" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default ControlCenterENPS
