import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { CustomText, GroupBarChart } from '../../atoms'
import { InfoIcon, MenuIcon } from '../../../icons'

interface tabArrayParams {
  title?: string
  subTitle?: string
  infoButton?: boolean
  barLabel?: [
    {
      color?: string
      title?: string
      info?: string
      subTitle?: string
      rounded?: boolean | undefined
    },
  ]
}

export function ControlCenterProdDeviation() {
  const { t } = useTranslation('common')
  const tabArray = [
    {
      title: t('production'),
      subTitle: t('productionDeviation'),
      infoButton: true,
      barLabel: [
        {
          color: 'bg-chartGreen',
          title: `${t('correctPickUps')} (%)`,
          info: t('correctlyEmptying'),
          subTitle: t('emptyingDrivingList'),
        },
        {
          color: 'bg-chartLightGreen',
          title: `${t('emptiedDeviations')} (%)`,
          info: t('emptyDeviation'),
          subTitle: t('EmptyingDrivingListDeviation'),
        },
        {
          color: 'bg-chartYellow',
          title: `${t('deviationNotEmptied')} (%)`,
          info: t('notEmptiedDeviation'),
          subTitle: t('notEmptiedCorrectDeviationDrivingList'),
        },
        {
          color: 'bg-chartRed',
          title: `${t('notHandledPickUps')} (%)`,
          info: t('notHandled'),
          subTitle: t('notEmptiedAndNoDeviationsDrivingList'),
        },
        {
          color: 'bg-chartBlack',
          title: `${t('notPlannedPickUps')} (%)`,
          info: t('unplanned'),
          subTitle: t('rFIDCodeRegistered'),
        },
      ],
    },
    {
      title: t('staffUtilizationRate'),
      subTitle: t('staffUtilizationRate'),
      infoButton: true,
      barLabel: [
        {
          color: 'bg-chartBlue',
          title: t('scheduledTime'),
          subTitle: `${t('scheduledTime')}: 8h/day`,
          rounded: true,
        },
        { color: 'bg-chartBlack', title: t('overtime'), subTitle: t('timeVehicleMotion') },
        {
          color: 'bg-chartYellow',
          title: t('rFDReadyDay'),
          subTitle: t('timeVehicleStationaryRunningOutside'),
        },
        {
          color: 'bg-chartGreen',
          title: t('accomplishedTime'),
          subTitle: t('timeVehicleStationaryRunningInside'),
        },
        {
          color: 'bg-chartPink',
          title: t('absenceTime'),
          subTitle: t('timeSuperstructurePowered'),
        },
      ],
    },
    {
      title: t('vehicleUtilizationRate'),
      subTitle: t('vehicleUtilizationRate'),
      infoButton: false,
      barLabel: [
        { color: 'bg-chartBlue', title: t('scheduledTime'), rounded: true },
        { color: 'bg-chartGreen', title: t('turnOutAccomplishedTime') },
        { color: 'bg-chartYellow', title: t('idlingOutsideDepot') },
        { color: 'bg-chartOrange', title: t('idlingInDepot') },
        { color: 'bg-chartLightBlue', title: t('hybridUsage') },
      ],
    },
  ] as unknown as tabArrayParams[]
  const [selectedTab, setSelectedTab] = useState<number>(0)
  const [isInfo, setIsInfo] = useState<boolean>(false)

  return (
    <div>
      <div className="flex mt-6 -mb-[2px] z-50">
        {tabArray?.map((item, index) => {
          return (
            <div
              className={`${index == selectedTab ? 'bg-white' : 'bg-draft'} ${index == 0 || index == 2 ? 'border-x' : ''} ${index == 0 ? 'rounded-tl-lg' : ''} ${index == 2 ? 'rounded-tr-lg' : ''} border-t border-b-0 py-6 px-2 text-center border-stroke`}
              onClick={() => {
                setSelectedTab(index)
              }}
            >
              <CustomText variant="mdPoppinsSemiBold">{item?.title ?? ''}</CustomText>
            </div>
          )
        })}
      </div>
      <div className="py-5 px-5 bg-white rounded-lg rounded-tl-none border border-stroke flex flex-col overflow-hidden">
        <div className="flex items-center">
          <CustomText variant="mdPoppinsSemiBold">
            {tabArray[selectedTab]?.subTitle ?? ''}
          </CustomText>
          <button>
            <MenuIcon className="stroke-black ml-2" />
          </button>
        </div>
        <div className="mt-5 grid grid-cols-1 lg:grid-cols-3 gap-5">
          <div className="col-span-2">
            <GroupBarChart
              minYAxis={0}
              maxYAxis={105}
              gapYAxis={20}
              labels={['TOTAL', 'Region Mitt', 'Region Nord', 'Region Syd']}
              datasets={[
                {
                  type: 'line',
                  label: 'Line Dataset',
                  data: [40, 20, 30, 60],
                  showLine: false,
                  pointRadius: 7,
                  pointHoverRadius: 6,
                  pointBackgroundColor: 'rgb(50,200,90)',
                },
                {
                  label: 'Dataset 1',
                  data: [66, 64, 64, 72],
                  backgroundColor: 'rgb(255, 99, 132)',
                  barPercentage: 0.25,
                },
                {
                  label: 'Dataset 2',
                  data: [3, 0, 4, 6],
                  backgroundColor: 'rgb(75, 192, 192)',
                  barPercentage: 0.25,
                },
                {
                  label: 'Dataset 3',
                  data: [27, 27, 32, 21],
                  backgroundColor: 'rgb(53, 162, 235)',
                  barPercentage: 0.25,
                },
              ]}
            />
          </div>
          <div className="flex flex-col gap-3 justify-center">
            {tabArray[selectedTab]?.barLabel?.map((item) => {
              return (
                <div className="flex items-center">
                  <div
                    className={`h-5 w-5 mr-2 ${item?.color} ${item?.rounded ? 'rounded-full' : ''}`}
                  />
                  <CustomText variant="mdPoppinsRegular">{item?.title ?? ''}</CustomText>
                </div>
              )
            })}
          </div>
        </div>
        {tabArray[selectedTab]?.infoButton && (
          <div
            className="mt-5 flex items-center"
            onClick={() => {
              setIsInfo(!isInfo)
            }}
          >
            <InfoIcon />
            <div className="ml-3">
              <div className="flex">
                <CustomText variant="mdPoppinsSemiBold">{`${t('info')} `}</CustomText>
                <CustomText variant="mdPoppinsRegular" className="underline">
                  {` - ${t('clickHere')}`}
                </CustomText>
                <CustomText variant="mdPoppinsRegular">{` ${t('forDescriptionThem')}`}</CustomText>
              </div>
              <CustomText variant="mdPoppinsRegular">
                {`${t('differentCategoriesChart')}.`}
              </CustomText>
            </div>
          </div>
        )}
        {isInfo && tabArray[selectedTab]?.infoButton && (
          <div className="grid grid-cols-1 lg:grid-cols-3 mt-10 gap-2">
            {tabArray[selectedTab]?.barLabel?.map((item) => {
              return (
                <div>
                  <div className="flex">
                    <div
                      className={`h-5 w-5 mr-2 ${item?.color} ${item?.rounded ? 'rounded-full' : ''}`}
                    />
                    <CustomText variant="mdPoppinsRegular">
                      {item?.info ? item?.info : (item?.title ?? '')}
                    </CustomText>
                  </div>
                  <CustomText variant="mdPoppinsRegular">{item?.subTitle ?? ''}</CustomText>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
