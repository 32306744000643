import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { QueryBuilder } from '@inphiz/core-utilities'
import type { filterItem } from '../../../screen/EmployeeHistory'

interface HistoryItem {
  userHandle?: string
  userName?: string
  action?: string
  authorType?: string
  authorHandle?: string
  authorName?: string
  id?: string
  handle?: string
  createdTime?: Date
  modifiedTime?: Date
  rootServiceProvider?: null
}

const EmployeeHistoryDto = new DataCollectionApiResponse<HistoryItem>()
export type EmployeeHistory = typeof EmployeeHistoryDto

interface Params {
  itemPerPage?: number
  skip?: number
  filterData?: filterItem[]
}

export function useEmployeeHistory({ itemPerPage = 20, skip = 0, filterData = [] }: Params) {
  return useQuery<EmployeeHistory>({
    queryKey: [QueryKey.getEmployeeHistory],
    queryFn: async () => {
      const qb = new QueryBuilder(APIConst.employeesPerformance.employeeHistory)
        .add('Cursor', skip)
        .add('Take', itemPerPage)
        .add('UseContinuationToken', 'False')
        .add('IncludeChildCount', 0)
        .add('IsOrderAscending', 'False')
        .add('OnlyCount', 'False')
      if (filterData?.length > 0 && filterData[0]?.selectedData) {
        qb.add('UserHandle', filterData[0]?.selectedData)
      }
      if (filterData?.length > 0 && filterData[1]?.selectedData) {
        qb.add('Action', filterData[1]?.selectedData)
      }
      if (filterData?.length > 0 && filterData[2]?.selectedData?.length > 0) {
        filterData[2]?.selectedData?.map((i: string) => {
          qb.add('OrganizationUnitHandles', i)
        })
      }
      const result = await APIFunctions.GET(qb.toString())
      return result
    },
  })
}
