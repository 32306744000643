import { useMutation } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { queryClient } from '../../../App'

interface Params {
  handle?: string
  typeHandle?: string
  contentType?: string
}

export function useDeleteEquipmentAssignment() {
  return useMutation({
    mutationFn: async (data: Params) => {
      const params = {
        item: data,
      }
      const result = await APIFunctions.DELETE(APIConst.equipment.assignmentEquipment, params)
      return result
    },
    onSuccess: (_: any, variables: any) => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.byEquipmentType, variables.typeHandle] })
    },
  })
}
