import { useTranslation } from 'react-i18next'
import { Checkbox } from 'rsuite'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {
  BorderButton,
  CustomButton,
  CustomDropDown,
  CustomInput,
  CustomText,
  CustomTextArea,
  DeleteAlert,
  ImagePicker,
  Spinner,
} from '../../component'
import CustomDatePicker from '../../component/atoms/CustomDatePicker'
import type { UploadResponse } from '../../packages/api-web'
import {
  useCreateNew,
  useDashboardNewDetails,
  useDeleteNew,
  useNewCategoryList,
  useUpdateNewsItem,
  useUploadMedia,
} from '../../packages/api-web'

interface EquipmentDetails {
  isFeatured?: boolean
  title?: string
  category?: { handle: string }[]
  summary?: string
  publishDate?: Date | null
  author?: string
  isLikes?: boolean
  isComments?: boolean
  content?: string
  defaultImage?: string
}

function DashboardNewsCreate() {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { id } = useParams()
  const [newsItemParams, setNewsItemParams] = useState<EquipmentDetails>({
    isFeatured: false,
    title: '',
    category: [],
    summary: '',
    publishDate: null,
    author: '',
    isLikes: false,
    isComments: false,
    content: '',
    defaultImage: '',
  })
  const [selectedImage, setSelectedImage] = useState(undefined)
  const [errorString, setErrorString] = useState<string[]>([])
  const [isShowAlert, setIsShowAlert] = useState(false)
  const { data, isFetching: isNewFetching } = useDashboardNewDetails({ handle: id ?? '' })
  const { data: groupData, isFetching } = useNewCategoryList()
  const { isPending, mutate } = useCreateNew()
  const { isPending: isUpdateLoader, mutate: updateMutate } = useUpdateNewsItem()
  const { mutate: uploadMediaMute } = useUploadMedia()
  const { mutate: deleteNewsMutation, isPending: isDeleteNewPending } = useDeleteNew()

  useEffect(() => {
    if (data && data.item) {
      setNewsItemParams({
        isFeatured: data.item.type == 'Featured',
        title: data.item.title,
        summary: data.item.summary,
        content: data.item.content,
        publishDate: data.item.publishTime ? new Date(data.item.publishTime) : null,
        category: data.item.groups,
        isLikes: data.item.metadata.IsLikesEnabled == 'True',
        isComments: data.item.metadata.IsCommentsEnabled == 'True',
        author: data.item.authorName,
        defaultImage: data.item.coverMedia[0]?.url,
      })
    }
  }, [data])

  const onTapSave = (saveAsDraft: boolean) => {
    if (
      newsItemParams.title
      && newsItemParams?.category
      && newsItemParams?.category?.length > 0
      && newsItemParams.summary
      && newsItemParams?.content
      && (!newsItemParams?.isFeatured
        || (newsItemParams?.isFeatured && (selectedImage || newsItemParams.defaultImage)))
    ) {
      const newObj = {
        handle: data?.item?.handle ?? '',
        title: newsItemParams.title,
        summary: newsItemParams.summary,
        content: newsItemParams.content,
        contentFormat: 'Html',
        groups: newsItemParams.category,
        authorName: newsItemParams.author,
        publishTime: newsItemParams.publishDate
          ? new Date(newsItemParams.publishDate).toISOString()
          : new Date().toISOString(),
        status: saveAsDraft ? 'Draft' : 'Published',
        metadata: {
          IsLikesEnabled: newsItemParams.isLikes ? 'True' : 'False',
          IsCommentsEnabled: newsItemParams.isComments ? 'True' : 'False',
        },
        type: newsItemParams.isFeatured ? 'Featured' : null,
        coverMediaHandles: data?.item?.coverMediaHandles,
      }
      const newMutation = id ? updateMutate : mutate
      const requestParams: FormData = new FormData()
      if (selectedImage) {
        requestParams.append('files', selectedImage)
        uploadMediaMute(requestParams, {
          onSuccess: (response: UploadResponse) => {
            if (response.isSuccess) {
              const newObject = {
                ...newObj,
                coverMediaHandles: response.items?.map(i => i.handle)?.filter(Boolean) as string[],
              }
              newMutation(newObject, {
                onSuccess: (data) => {
                  if (data.isSuccess) {
                    navigate(-1)
                  }
                },
              })
            }
          },
        })
      }
      else {
        newMutation(newObj, {
          onSuccess: (data) => {
            if (data.isSuccess) {
              navigate(-1)
            }
          },
        })
      }
    }
    else {
      const errorArray = []
      if (!newsItemParams.title) {
        errorArray.push(t('newTitleError'))
      }
      if (newsItemParams?.category?.length == 0) {
        errorArray.push(t('newCategoryError'))
      }
      if (!newsItemParams.summary) {
        errorArray.push(t('newSummaryError'))
      }
      if (!newsItemParams.content) {
        errorArray.push(t('newContentError'))
      }
      if (newsItemParams.isFeatured && !selectedImage) {
        errorArray.push(t('newLogoError'))
      }
      setErrorString(errorArray)
    }
  }

  if (isFetching || isNewFetching)
    return <Spinner />

  return (
    <div className="p-5 h-full flex flex-col">
      {(isPending || isDeleteNewPending || isUpdateLoader) && <Spinner />}
      <CustomText variant="xlPoppinsBold">{t('news')}</CustomText>
      <div className="flex flex-1 flex-col mt-6 px-5 bg-white rounded-lg border border-stroke h-[100%] overflow-hidden">
        <CustomText variant="lgPoppinsSemiBold" className="my-3">
          {id ? 'Edit news item' : t('createNewsItem')}
        </CustomText>
        <div className="flex-1 overflow-hidden overflow-y-auto scrollbar">
          <div className="border border-stroke rounded-lg">
            <div className="py-3 px-5 border-b border-stroke">
              <CustomText variant="mdPoppinsSemiBold">Details</CustomText>
            </div>
            <div className="flex-1 p-5 overflow-y-auto scrollbar">
              <Checkbox
                checked={newsItemParams.isFeatured}
                onChange={() => {
                  setNewsItemParams({
                    ...newsItemParams,
                    isFeatured: !newsItemParams.isFeatured,
                  })
                }}
                className="cm-checkbox mb-3"
              >
                {t('showAsFeatured')}
              </Checkbox>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
                <CustomInput
                  className={`${!newsItemParams.title && errorString.length > 0 ? 'outline-textDanger' : ''}`}
                  title={t('title')}
                  value={newsItemParams.title}
                  onChange={(e) => {
                    setNewsItemParams({
                      ...newsItemParams,
                      title: e.target.value,
                    })
                  }}
                  placeholder={t('exHotWork')}
                />
                <CustomDropDown
                  value={newsItemParams?.category && newsItemParams?.category[0]?.handle}
                  className="!mb-3"
                  title={`${t('category')}`}
                  data={groupData?.items ?? []}
                  labelField="title"
                  valueField="handle"
                  style={{
                    borderColor:
                      newsItemParams?.category?.length == 0 && errorString.length > 0 ? 'red' : '',
                    borderWidth:
                      newsItemParams?.category?.length == 0 && errorString.length > 0 ? 2 : 0,
                    borderRadius: 10,
                  }}
                  onSelect={(_, values) => {
                    setNewsItemParams({
                      ...newsItemParams,
                      category: values as any,
                    })
                  }}
                  placeholder={t('selectExperienceType')}
                />
                <CustomDatePicker
                  className="!mt-3"
                  title={t('publishDate')}
                  minDate={new Date()}
                  value={newsItemParams.publishDate}
                  onChange={(date) => {
                    setNewsItemParams({
                      ...newsItemParams,
                      publishDate: date,
                    })
                  }}
                  placement="bottomEnd"
                />
                <CustomInput
                  title={t('author')}
                  value={newsItemParams.author}
                  onChange={(e) => {
                    setNewsItemParams({
                      ...newsItemParams,
                      author: e.target.value,
                    })
                  }}
                  placeholder={t('enterAuthor')}
                />
                <div className="flex flex-col gap-5">
                  <CustomTextArea
                    className={`${!newsItemParams.summary && errorString.length > 0 ? 'outline-textDanger' : ''}`}
                    rows={5}
                    title={t('summary')}
                    value={newsItemParams.summary}
                    onChange={(e) => {
                      setNewsItemParams({
                        ...newsItemParams,
                        summary: e.target.value,
                      })
                    }}
                  />
                  <Checkbox
                    checked={newsItemParams.isLikes}
                    onChange={() => {
                      setNewsItemParams({
                        ...newsItemParams,
                        isLikes: !newsItemParams.isLikes,
                      })
                    }}
                    className="cm-checkbox"
                  >
                    {t('enableLikes')}
                  </Checkbox>
                  <Checkbox
                    checked={newsItemParams.isComments}
                    onChange={() => {
                      setNewsItemParams({
                        ...newsItemParams,
                        isComments: !newsItemParams.isComments,
                      })
                    }}
                    className="cm-checkbox"
                  >
                    {t('enableComments')}
                  </Checkbox>
                </div>
                <ImagePicker
                  defaultImage={newsItemParams.defaultImage}
                  title="Upload image"
                  buttonTitle="Choose"
                  onClearImage={() => {
                    setNewsItemParams({
                      ...newsItemParams,
                      defaultImage: '',
                    })
                  }}
                  selectedImage={(item) => {
                    setSelectedImage(item)
                  }}
                />
              </div>
              <div className="mt-5">
                <CustomText variant="mdPoppinsSemiBold">{t('surveyTableContent')}</CustomText>
                <ReactQuill
                  className={`cm-reach min-h-40 rounded-lg overflow-auto mt-3 scrollbar border ${!newsItemParams.content && errorString.length > 0 ? 'border-textDanger' : 'border-stroke'}`}
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, false] }],
                      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      ['image'],
                    ],
                  }}
                  value={newsItemParams.content}
                  onChange={(value) => {
                    setNewsItemParams({
                      ...newsItemParams,
                      content: value,
                    })
                  }}
                />
              </div>
            </div>
          </div>
          {errorString.length > 0 && (
            <ul className="mt-5 list-disc pl-4">
              {errorString.map((i, index) => (
                <li key={index} className="text-textDanger">
                  {i}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="relative flex flex-wrap gap-4 justify-between my-4">
          {id ? (
            <BorderButton
              children={t('delete')}
              className="!px-6 hidden sm:block"
              textColor="text-textPrimary"
              onClick={() => setIsShowAlert(true)}
            />
          ) : (
            <div />
          )}
          <div className="flex gap-4 flex-wrap">
            {!!id && (
              <BorderButton
                children={t('delete')}
                className="!px-6 block sm:hidden flex-1"
                textColor="text-textPrimary"
                onClick={() => setIsShowAlert(true)}
              />
            )}
            <CustomButton
              children={t('save')}
              backgroundColor="bg-secondary"
              textColor="text-textWhite"
              className="!px-6 flex-1"
              onClick={() => onTapSave(false)}
            />
            <CustomButton
              children="SaveAsDraft"
              backgroundColor="bg-primary"
              className="!px-6 flex-1"
              textColor="text-textWhite"
              onClick={() => onTapSave(true)}
            />
            <CustomButton
              children={t('cancel')}
              backgroundColor="bg-bthDisable"
              className="!px-6 flex-auto"
              textColor="text-textPrimary"
              onClick={() => {
                navigate(-1)
              }}
            />
          </div>
        </div>
      </div>
      {isShowAlert && (
        <DeleteAlert
          title={t('deleteNewTitle')}
          subTitle={t('deleteNewDes')}
          leftButtonText={t('cancel')}
          rightButtonText={t('delete')}
          onCloseAlert={() => {
            setIsShowAlert(false)
          }}
          onRightButton={() => {
            setIsShowAlert(false)
            if (data?.item?.handle) {
              deleteNewsMutation(data?.item, {
                onSuccess: (data) => {
                  if (data.isSuccess) {
                    navigate(-1)
                  }
                },
              })
            }
          }}
          onLeftButton={() => {
            setIsShowAlert(false)
          }}
        />
      )}
    </div>
  )
}

export default DashboardNewsCreate
