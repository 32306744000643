export function InActiveIcon({ className = 'fill-textDanger' }) {
  return (
    <svg width={30} height={30} viewBox="0 0 30 30" className={className}>
      <defs>
        <clipPath id="clip-_">
          <rect width={30} height={30} />
        </clipPath>
      </defs>
      <g id="_" data-name="!" clipPath="url(#clip-_)">
        <g id="Group_12942" data-name="Group 12942" transform="translate(10072 -6616)">
          <g
            id="Ellipse_438"
            data-name="Ellipse 438"
            transform="translate(-10071 6617)"
            stroke="#fff"
            strokeWidth={2}
          >
            <circle cx={14} cy={14} r={14} stroke="none" />
            <circle cx={14} cy={14} r={13} fill="none" />
          </g>
          <g id="Group_12940" data-name="Group 12940" transform="translate(-19 -88)">
            <path
              id="Path_7356"
              data-name="Path 7356"
              d="M-10038,6712.278v7.942"
              transform="translate(0 0.33)"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeWidth={2}
            />
            <path
              id="Path_7357"
              data-name="Path 7357"
              d="M-10038,6712.278v0"
              transform="translate(0 13.109)"
              fill="none"
              stroke="#fff"
              strokeLinecap="round"
              strokeWidth={2}
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
