import { useTranslation } from 'react-i18next'
import FlatList from 'flatlist-react'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useSwipeable } from 'react-swipeable'
import { useNavigate } from 'react-router-dom'
import { APIConst } from '@inphiz/api'
import { ActionsCard, ChatList, CustomButton, CustomText, Spinner } from '../../component'
import { RightIcon, RoundArrowIcon, RoundCloseIcon, RoundMinus, RoundedRight } from '../../icons'
import SocialDetails from '../SocialDetailsScreen'
import type { News, Social } from '../../packages/api-web'
import {
  useActionList,
  useAllReadNotificationsMutation,
  useFollowersQuery,
  useNewsQuery,
  useSocialList,
} from '../../packages/api-web'
import { dateFormat, getActionDescription } from '../../helpers'
import NotificationList from '../NotificationList'
import { UserContext } from '../../contexts/UserContext'

function Dashboard() {
  const { t } = useTranslation('common')
  const { user } = useContext(UserContext)
  const navigate = useNavigate()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const isManager = user.userData.metadata?.HasReportingToMe === 'True' ? user.userData.handle : ''
  const { data: tabData, isFetching: tabDataIsFetching } = useFollowersQuery()
  const {
    isFetching: newsFetching,
    data,
    refetch,
  } = useNewsQuery({
    selectedNewsGroupHandle: undefined,
    isEnabled: false,
  })
  const { isFetching: socialIsFetching, data: socialGroupData } = useFollowersQuery('groupwallpost')
  const {
    isFetching: socialListIsFetching,
    data: socialData,
    refetch: socialRefetch,
  } = useSocialList({
    selectedSocialGroupHandle: undefined,
    isEnabled: false,
  })
  const { mutate, isPending } = useAllReadNotificationsMutation()

  const {
    isFetching: myCardIsFetching,
    data: myCardData,
    refetch: myCardRefetch,
  } = useActionList({
    actionType: '',
    managerHandles: [isManager ?? ''],
  })

  const actionData = useMemo(() => {
    const newData = [...(myCardData?.items ?? [])]
    return {
      critical: newData.filter(i => i.severity == 1),
      recommended: newData.filter(i => i.severity == 2),
      other: newData.filter(i => i.severity == 3),
    }
  }, [myCardData])

  useEffect(() => {
    if (user && user.userData.handle)
      myCardRefetch()
  }, [user])

  useEffect(() => {
    if (tabData) {
      refetch()
    }
  }, [tabData])

  useEffect(() => {
    if (socialGroupData) {
      socialRefetch()
    }
  }, [socialGroupData])

  const newList = useMemo(() => {
    const result = data?.pages?.flatMap(i => i.items).slice(0, 10)
    return result?.filter(i => i.coverMedia.length > 0) as News[]
  }, [data])

  const socialList = useMemo(() => {
    const result = socialData?.pages?.flatMap(i => i.items).slice(0, 10)
    return result as Social[]
  }, [socialData])

  const markAllRead = useCallback(() => {
    const data = {
      status: 'Read',
    }
    mutate(data)
  }, [mutate])

  const handleNext = () => {
    setCurrentIndex(prevIndex => (prevIndex + 1) % newList.length)
  }

  // Handle the left arrow click
  const handlePrev = () => {
    setCurrentIndex(prevIndex => (prevIndex - 1 + newList.length) % newList.length)
  }

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNext, // Swipe left to go to the next item
    onSwipedRight: handlePrev, // Swipe right to go to the previous item
    trackMouse: true, // Allows mouse swipe in addition to touch
  })

  const changeIndex = (increment: boolean) => {
    if (myCardData) {
      const newIndex = selectedIndex + (increment ? 1 : -1)
      if (newIndex < 0 || newIndex >= actionData.critical.slice(0, 20).length)
        return
      setSelectedIndex(newIndex)
    }
  }

  const currentAction = myCardData?.items?.[selectedIndex]

  const renderItem = (item: Social, idx: string) => {
    return (
      <li key={idx} className="pb-2 cursor-pointer" onClick={() => {}}>
        <SocialDetails
          socialHandle={item?.handle ?? ''}
          typeHandle={undefined}
          onDeleteSuccess={() => {}}
        />
      </li>
    )
  }

  if (
    tabDataIsFetching
    || newsFetching
    || socialIsFetching
    || socialListIsFetching
    || myCardIsFetching
  ) {
    return <Spinner />
  }

  return (
    <div className="p-5 h-full flex flex-col">
      <CustomText variant="xlPoppinsBold">{`${t('hello')} ${user?.userData?.displayName ?? ''}!`}</CustomText>
      <div className="grid grid-cols-1 lg:grid-cols-3 flex-1 overflow-hidden mt-5">
        <div className="col-span-2 relative flex flex-col overflow-hidden overflow-y-auto scrollbar">
          {/* My action section */}
          <div>
            <div className="flex justify-between">
              <CustomText variant="smPoppinsSemiBold">{t('myActions')}</CustomText>
              <CustomText
                className="cursor-pointer"
                onClick={() => navigate('/my_day')}
                variant="smPoppinsRegular"
              >
                {t('showAllActions')}
              </CustomText>
            </div>
            {actionData.critical.length > 0 && (
              <div className="flex flex-col mt-3 py-5 px-5 bg-white border border-stroke rounded-lg">
                <div className="flex-1 grid grid-cols-1 lg:grid-cols-2 gap-4">
                  <div className="flex-1 relative ml-5 mt-4 mr-4">
                    <div className="w-full absolute -top-4 -left-4 flex-1 bg-white">
                      <ActionsCard
                        className="bg-textDanger"
                        userName={currentAction?.title}
                        title={getActionDescription(currentAction?.type ?? 0)?.title}
                        image={
                          currentAction?.entityImageHandle
                            ? `${APIConst.baseURL}/${APIConst.mediaUpload}/${currentAction.entityImageHandle}`
                            : ''
                        }
                        subTitle={currentAction?.additionalData}
                        status={`Status: ${currentAction?.severity == 1 ? t('neededTxt') : currentAction?.severity == 2 ? t('recommendedTxt') : t('otherTxt')}`}
                      />
                    </div>
                    <ActionsCard className="bg-yellow" />
                  </div>
                  <div className="flex-1 justify-around flex flex-col gap-4">
                    <div className="flex items-center">
                      <RoundCloseIcon />
                      <CustomText variant="smPoppinsRegular" className="ml-4 flex-1">
                        {t('notApprovedRequired')}
                      </CustomText>
                      <CustomText variant="smPoppinsRegular">
                        {actionData?.critical?.length.toString()}
                      </CustomText>
                      <div className="bg-textDanger h-3 w-3 rounded-full ml-3" />
                    </div>
                    <div className="flex items-center">
                      <RoundMinus />
                      <CustomText variant="smPoppinsRegular" className="ml-4 flex-1">
                        {t('notCompletedRecommended')}
                      </CustomText>
                      <CustomText variant="smPoppinsRegular">
                        {actionData?.recommended?.length.toString()}
                      </CustomText>
                      <div className="bg-yellow h-3 w-3 rounded-full ml-3" />
                    </div>
                    <div className="flex items-center">
                      <RoundedRight />
                      <CustomText variant="smPoppinsRegular" className="ml-4 flex-1">
                        {t('completedOtherActions')}
                      </CustomText>
                      <CustomText variant="smPoppinsRegular">
                        {actionData?.other?.length.toString()}
                      </CustomText>
                      <div className="bg-green h-3 w-3 rounded-full ml-3" />
                    </div>
                  </div>
                </div>
                <div className="mt-3 h-8 w-full flex items-center justify-center">
                  <CustomText variant="mdPoppinsSemiBold">
                    {`${selectedIndex + 1}/${actionData.critical.slice(0, 20).length ?? 0}`}
                  </CustomText>
                  <div className="flex items-center gap-2 ml-2">
                    <button onClick={() => changeIndex(false)}>
                      <RoundArrowIcon
                        className={`rotate-180 ${selectedIndex > 0 ? 'text-secondary' : 'text-textSecondary'}`}
                      />
                    </button>
                    <button onClick={() => changeIndex(true)}>
                      <RoundArrowIcon className="text-secondary" />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* News section */}
          <div>
            <div className="flex justify-between mt-5">
              <CustomText variant="smPoppinsSemiBold">{t('news')}</CustomText>
              <CustomText
                className="cursor-pointer"
                variant="smPoppinsRegular"
                onClick={() => {
                  navigate('/news')
                }}
              >
                {t('showAllNews')}
              </CustomText>
            </div>
            {newList && newList.length > 0 && (
              <div className="flex flex-col mt-3 pb-5 bg-white border border-stroke rounded-lg overflow-hidden">
                <div className="horizontal-display" {...swipeHandlers}>
                  {newList?.length > 0 && newList[currentIndex].coverMedia?.length > 0 && (
                    <img
                      className="bg-center new-img !object-contain"
                      src={newList[currentIndex].coverMedia[0]?.url}
                    />
                  )}
                  <div className="px-5 py-5">
                    {newList?.length > 0 && (
                      <>
                        <CustomText variant="xsmPoppinsRegular">
                          {dateFormat(newList[currentIndex]?.publishTime)}
                        </CustomText>
                        <CustomText variant="mdPoppinsSemiBold" className="mt-3 mb-3">
                          {newList[currentIndex]?.title}
                        </CustomText>
                        <CustomText variant="smPoppinsRegular">
                          {newList[currentIndex]?.summary}
                        </CustomText>
                        <CustomButton
                          children={t('readFullArticle')}
                          className="flex-auto mt-3 !px-5"
                          backgroundColor="bg-secondary"
                          textColor="text-textWhite"
                          onClick={() => {
                            navigate('/newdetails', {
                              state: {
                                newsHandle: newList[currentIndex]?.handle,
                                typeHandle: null,
                              },
                            })
                          }}
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="flex justify-center">
                  <button
                    onClick={() => {
                      handlePrev()
                    }}
                  >
                    <RightIcon className="stroke-1 rotate-180" />
                  </button>
                  <div className="flex items-center justify-center">
                    {newList?.map((_, index) => (
                      <div
                        key={index}
                        className={`ml-1 mr-1 h-2 w-2 rounded-full ${index == currentIndex ? 'bg-black' : 'bg-bgColor'}`}
                      />
                    ))}
                  </div>
                  <button
                    onClick={() => {
                      handleNext()
                    }}
                  >
                    <RightIcon className="stroke-1" />
                  </button>
                </div>
              </div>
            )}
          </div>
          {/* Social section */}
          <div>
            <div className="flex justify-between mt-5">
              <CustomText variant="smPoppinsSemiBold">{t('snack')}</CustomText>
              <CustomText
                className="cursor-pointer"
                variant="smPoppinsRegular"
                onClick={() => {
                  navigate('/social')
                }}
              >
                {t('showAll')}
              </CustomText>
            </div>
            <div className="mt-3 overflow-hidden">
              <ul>
                <FlatList list={socialList} renderItem={renderItem} />
              </ul>
            </div>
          </div>
        </div>
        <div className="ml-5 overflow-y-auto relative flex flex-col max-lg:hidden scrollbar">
          {/* AI chat section */}
          <div>
            <div className="flex justify-between">
              <CustomText variant="smPoppinsSemiBold">{t('theAIChat')}</CustomText>
              <CustomText
                onClick={() => navigate('/chat')}
                className="cursor-pointer"
                variant="smPoppinsRegular"
              >
                {t('viewHistory')}
              </CustomText>
            </div>
            <div className="mt-3 bg-white border border-stroke rounded-lg h-64 overflow-hidden relative flex flex-col">
              <ChatList isDashboard={true} />
            </div>
          </div>
          {/* Notification section */}
          <div>
            <div className="flex justify-between mt-5">
              <CustomText variant="smPoppinsSemiBold">{t('notifications')}</CustomText>
              <CustomText variant="smPoppinsRegular" onClick={markAllRead}>
                {t('markAllRead')}
              </CustomText>
            </div>
            <NotificationList />
          </div>
        </div>
      </div>
      {isPending && <Spinner />}
    </div>
  )
}

export default Dashboard
