export class RNLoggerImpl {
  public static stringifyFunc(msg: any): string {
    let stringMsg = ''
    if (typeof msg === 'string') {
      stringMsg = `${msg} `
    }
    else if (typeof msg === 'function') {
      stringMsg = `[function ${msg.name}()] `
    }
    else if (msg && msg.stack && msg.message) {
      stringMsg = `${msg.message} `
    }
    else {
      try {
        stringMsg = `\n${JSON.stringify(msg, null, 3)}\n`
      }
      catch (error: any) {
        stringMsg += 'Undefined Message'
      }
    }
    return stringMsg
  }
}
