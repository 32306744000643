import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import defaultTheme from 'tailwindcss/defaultTheme'
import {
  BorderButton,
  CustomButton,
  CustomDropDown,
  CustomInput,
  CustomText,
  CustomTextArea,
  Spinner,
} from '../../atoms'
import type { EquipmentCategoryItem } from '../../../packages/api-web/Equipment'
import {
  useDeleteCategory,
  usePostCategory,
  usePutCategory,
} from '../../../packages/api-web/Equipment'
import { DeleteAlert } from '../../molecules'

interface EquipmentCategoryParams {
  categoryList?: EquipmentCategoryItem[]
  selectedItem?: EquipmentCategoryItem
}

function CreateEquipmentCategory({ categoryList, selectedItem }: EquipmentCategoryParams) {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const { mutate, isPending } = usePostCategory()
  const { mutate: updateMutate, isPending: updateIsPending } = usePutCategory()
  const { mutate: deleteMutate, isPending: deleteIsPending } = useDeleteCategory()
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [selectedCategory, setSelectedCategory] = useState<EquipmentCategoryItem>()
  const [isShowAlert, setIsShowAlert] = useState<boolean>(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [error, setError] = useState(false)

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)
    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (selectedItem) {
      if (selectedItem?.parentEquipmentCategoryHandle != null) {
        const filter
          = categoryList?.filter(i => i?.handle == selectedItem?.parentEquipmentCategoryHandle) ?? []
        if (filter?.length > 0) {
          setSelectedCategory(filter[0])
        }
      }
      else {
        setSelectedCategory(undefined)
      }
      setTitle(selectedItem?.title ?? '')
      setDescription(selectedItem?.content ?? '')
    }
    else {
      setSelectedCategory(undefined)
      setTitle('')
      setDescription('')
    }
  }, [selectedItem])

  const saveCategory = () => {
    if (title) {
      if (selectedItem) {
        const data = {
          title,
          content: description,
          parentEquipmentCategoryHandle: selectedCategory?.handle ?? undefined,
          handle: selectedItem?.handle ?? '',
        }
        updateMutate(data, {
          onSuccess: () => {
            if (screenWidth < Number.parseInt(defaultTheme.screens.lg.replace('px', ''), 10)) {
              navigate(-1)
            }
          },
        })
      }
      else {
        const data = {
          title,
          content: description,
          parentEquipmentCategoryHandle: selectedCategory?.handle ?? undefined,
        }
        mutate(data, {
          onSuccess: () => {
            if (screenWidth < Number.parseInt(defaultTheme.screens.lg.replace('px', ''), 10)) {
              navigate(-1)
            }
            else {
              setTitle('')
              setDescription('')
              setSelectedCategory(undefined)
            }
          },
        })
      }
      setError(false)
    }
    else {
      setError(true)
    }
  }

  const deleteCategory = () => {
    setIsShowAlert(true)
  }

  return (
    <div className="flex flex-col flex-1 border border-stroke rounded-md overflow-y-auto scrollbar bg-white">
      <div className="px-3 flex justify-between h-10 border-b border-stroke items-center">
        <CustomText variant="smPoppinsSemiBold">{`${t('category')} information`}</CustomText>
      </div>
      <div className="px-5 py-5 flex-1">
        <CustomInput
          title={t('title')}
          value={title}
          onChange={(e) => {
            setTitle(e.target.value)
          }}
          placeholder={t('exHotWork')}
          className={`mb-3 ${!title && error ? 'outline-textDanger' : ''}`}
        />
        <div className="mb-3">
          <CustomDropDown
            value={selectedCategory?.handle}
            className="!mb-2"
            title={`${t('category')}`}
            data={categoryList ?? []}
            labelField="title"
            valueField="handle"
            onSelect={(_, values) => {
              setSelectedCategory(values as EquipmentCategoryItem)
            }}
          />
        </div>
        <CustomTextArea
          title={`${t('description')} ${t('maxCharacters')}`}
          placeholder={`${t('writeDescription')}`}
          aria-multiline
          value={description}
          onChange={(e) => {
            setDescription(e.target.value)
          }}
        />
      </div>
      <div className="flex flex-wrap gap-4 justify-end py-4 px-3">
        {selectedItem && (
          <div className="flex-1">
            <CustomButton
              children={t('remove')}
              className="hidden sm:block"
              backgroundColor="bg-bthDisable"
              textColor="text-textPrimary"
              onClick={deleteCategory}
            />
          </div>
        )}
        <div className="flex gap-4 flex-wrap">
          {selectedItem && (
            <CustomButton
              children={t('remove')}
              className="!px-4 sm:!px-5 block sm:hidden flex-1"
              backgroundColor="bg-bthDisable"
              textColor="text-textPrimary"
              onClick={deleteCategory}
            />
          )}
          <BorderButton
            children={t('cancel')}
            className="!px-2 sm:!px-5 flex-1"
            textColor="text-textPrimary"
            onClick={() => {
              if (screenWidth < Number.parseInt(defaultTheme.screens.lg.replace('px', ''), 10)) {
                navigate(-1)
              }
              else {
                setTitle('')
                setDescription('')
                setSelectedCategory(undefined)
              }
            }}
          />
          <CustomButton
            children={t('save')}
            className="flex-auto"
            backgroundColor="bg-secondary"
            textColor="text-textWhite"
            onClick={saveCategory}
          />
        </div>
      </div>
      {(isPending || updateIsPending || deleteIsPending) && <Spinner />}
      {isShowAlert && (
        <DeleteAlert
          title={t('deleteEquipmentTitle')}
          subTitle={
            selectedItem?.metadata?.CanBeDeleted == 'True'
              ? t('deleteEquipment')
              : t('deleteEquipmentWarning')
          }
          {...(selectedItem?.metadata?.CanBeDeleted == 'True' && { leftButtonText: t('cancel') })}
          {...(selectedItem?.metadata?.CanBeDeleted == 'True' && {
            rightButtonText: t('deletePosts'),
          })}
          {...(selectedItem?.metadata?.CanBeDeleted == 'False' && {
            singleButtonText: t('cancel'),
          })}
          onCloseAlert={() => {
            setIsShowAlert(false)
          }}
          onLeftButton={() => {
            setIsShowAlert(false)
          }}
          onSingleButton={() => {
            setIsShowAlert(false)
          }}
          onRightButton={() => {
            const data = {
              type: 'default',
              handle: selectedItem?.handle,
              contentType: selectedItem?.contentType,
            }
            deleteMutate(data, {
              onSuccess: () => {
                setIsShowAlert(false)
                setTitle('')
                setDescription('')
                setSelectedCategory(undefined)
              },
            })
          }}
        />
      )}
    </div>
  )
}

export default CreateEquipmentCategory
