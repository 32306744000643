import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { APIConst } from '@inphiz/api'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import { Social } from '@inphiz-shared/shared-models/src/F_GroupsPermission/social'
import { CheckedIcon, CloseIcon, ImagePickerIcon, UncheckIcon } from '../../../icons'
import {
  CustomButton,
  CustomDropDown,
  CustomImg,
  CustomText,
  CustomTextArea,
  Divider,
  Spinner,
  TextWithIcon,
} from '../../atoms'
import { UserContext } from '../../../contexts/UserContext'
import { queryClient } from '../../../App'
import type { CreateSocialPostParam, Group, UploadResponse } from '../../../packages/api-web'
import { useCreateSocialPostMutation, useUploadMedia } from '../../../packages/api-web'
import { ContentType, SocialPostType } from '../../../helpers'

interface CreateSocialPostProps {
  isVisible: boolean
  onClose: () => void
  gType?: Group
}

export function CreateSocialPost({ isVisible, onClose, gType }: CreateSocialPostProps) {
  const { user } = useContext(UserContext)
  const { t } = useTranslation('common')
  const hiddenFileInput = useRef<HTMLInputElement>(null)
  const data = queryClient.getQueryData([QueryKey.groupsList, 'groupwallpost']) as Group[]
  const [showModal, setShowModal] = useState(isVisible)
  const [groupList, setGroupList] = useState<Group[] | null>(null)
  const [selectedGroup, setSelectedGroup] = useState<Group | null>(null)
  const [conversation, setConversation] = useState('')
  const [selectedImage, setSelectedImage] = useState<any[]>([])
  const [selectCheckBox, setSelectCheckBox] = useState(false)
  const [avatarURL, setAvatarURL] = useState<string>()
  const { createPostMutate, isPending } = useCreateSocialPostMutation()
  const { mutate: uploadMediaMutate, isPending: isPendingImage } = useUploadMedia()

  useEffect(() => {
    setShowModal(isVisible)
    setSelectedImage([])
    setAvatarURL('')
    setSelectCheckBox(false)
    setConversation('')
    if (data) {
      const notManager = user.userData.metadata?.HasReportingToMe === 'False'
      setGroupList(
        notManager
          ? data.slice(1).filter(i => i.metadata?.Social !== Social.onlyManagerCanPost)
          : data.slice(1),
      )
    }
  }, [isVisible, data])

  useEffect(() => {
    if (gType?.handle) {
      setSelectedGroup(gType)
    }
    else if (groupList) {
      const sortedData = groupList.sort((a, b) => a.title.localeCompare(b.title))
      const filteredData
        = user.userData.metadata?.HasReportingToMe === 'False'
          ? sortedData.filter(i => i.metadata?.Social !== Social.onlyManagerCanPost)
          : sortedData
      if (filteredData.length)
        setSelectedGroup(filteredData[0])
    }
  }, [gType, groupList])

  const handleChange = (event: any) => {
    const fileUploaded = event.target.files[0]
    const cachedURL = URL.createObjectURL(fileUploaded)
    setAvatarURL(cachedURL)
    setSelectedImage([fileUploaded])
  }

  const refreshingData = async () => {
    queryClient.invalidateQueries({ queryKey: [QueryKey.socialList, gType?.handle] })
    onClose()
  }

  const createSnackPost = () => {
    const requestParams: FormData = new FormData()
    if (selectedImage && selectedImage.length > 0) {
      requestParams.append('files', selectedImage[0])
    }
    const createPostObj: CreateSocialPostParam = {
      ownerHandle: selectedGroup?.handle,
      ownerContentType: 'group',
      type: SocialPostType.default,
      content: conversation.replace(/\n/g, '<br>'),
      authorHandle: '@me',
      contentType: ContentType.groupwallpost,
    }
    if (selectCheckBox) {
      createPostObj.actionsInfo = {
        socialActions: [
          {
            value: 'True',
            type: SocialPostType.readConfirmed,
            userHandle: '@me',
          },
        ],
      }
    }
    if (selectedImage && selectedImage.length > 0) {
      uploadMediaMutate(requestParams, {
        onSuccess: (data: UploadResponse) => {
          if (data.isSuccess) {
            const newObj = {
              ...createPostObj,
              coverMediaHandles: data.items?.map(i => i.handle)?.filter(Boolean) as string[],
            }
            createPostMutate(newObj, {
              onSuccess: (data) => {
                if (data.isSuccess)
                  refreshingData()
              },
            })
          }
        },
      })
    }
    else {
      createPostMutate(createPostObj, {
        onSuccess: (data) => {
          if (data.isSuccess)
            refreshingData()
        },
      })
    }
  }

  if (!showModal)
    return null

  return (
    <div
      className={`fixed top-0 bottom-0 left-0 right-0 z-50 flex items-center justify-center bg-stroke-60 transition-opacity ${isVisible ? 'opacity-100' : 'opacity-0'} duration-300`}
      style={{ zIndex: 999 }}
    >
      <div
        className={`bg-white border-stroke border z-50 rounded-md transform transition-transform ${isVisible ? 'scale-100' : 'scale-90 duration-300'} duration-300 w-[440px]`}
      >
        <div className="flex items-center p-4 justify-between">
          <CustomText className="!text-[12px]" variant="smPoppinsSemiBold">
            {t('newPost')}
          </CustomText>
          <button onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        <Divider />
        <div className="h-[400px] flex flex-col flex-1 overflow-hidden">
          <div className="pl-4 flex flex-1 flex-col  overflow-hidden">
            <div className="flex items-center py-4">
              <CustomImg
                name={user?.userData?.displayName ?? 'Anonym'}
                imgSrc={
                  user?.userData?.profileImageHandle
                    ? `${APIConst.baseURL}/${APIConst.mediaUpload}/${user?.userData?.profileImageHandle}`
                    : ''
                }
                altText="user_image"
                className="h-10 w-10 rounded-full"
                imgClassName="rounded-full"
              />
              <div className="px-2">
                <CustomText className="mb-1.5" variant="smPoppinsMedium">
                  {user?.userData?.displayName ?? ''}
                </CustomText>
                <CustomDropDown
                  placeholder={t('targetGroup')}
                  data={groupList ?? []}
                  value={groupList?.find(i => i.handle == selectedGroup?.handle)?.handle ?? []}
                  onSelect={(_, item) => {
                    setSelectedGroup(item as Group)
                  }}
                  valueField="handle"
                  labelField="title"
                />
              </div>
            </div>
            <div className="flex flex-1 flex-col overflow-y-auto scrollbar pr-4 mb-4">
              <CustomTextArea
                rows={5}
                placeholder={t('startAConversation')}
                className="!mt-0 !outline-none !rounded-none !py-0 !px-0"
                value={conversation}
                onChange={e => setConversation(e.target.value)}
              />
              {!!avatarURL && (
                <div className="relative pt-4">
                  <img src={avatarURL} alt="app_logo" />
                  <button
                    onClick={() => {
                      setAvatarURL('')
                      if (hiddenFileInput.current) {
                        hiddenFileInput.current.value = ''
                      }
                    }}
                    className="bg-white absolute top-7 right-3 rounded-full p-1 shadow-black"
                  >
                    <CloseIcon className="h-5 w-5" />
                  </button>
                </div>
              )}
            </div>
            <div className="mb-4 pr-4 flex justify-between items-center">
              {[Social.onlyManagerCanPostWithConfirmation, Social.onlyManagerCanPost].includes(
                selectedGroup?.metadata?.Social ?? '',
              )
              && user.userData.metadata?.HasReportingToMe === 'True' && (
                <button
                  className="text-start self-start"
                  onClick={() => setSelectCheckBox(!selectCheckBox)}
                >
                  <TextWithIcon
                    leftIcon={
                      selectCheckBox ? (
                        <CheckedIcon className="mr-2" />
                      ) : (
                        <UncheckIcon className="mr-2" />
                      )
                    }
                    text={`${t('requestReadReceipt')}.`}
                    textVariant="smPoppinsRegular"
                  />
                </button>
              )}
              <button onClick={() => hiddenFileInput.current?.click()}>
                <ImagePickerIcon />
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleChange}
                  ref={hiddenFileInput}
                  hidden
                />
              </button>
            </div>
          </div>
        </div>
        <Divider />
        <div className="flex flex-1 flex-col p-4">
          <CustomButton onClick={createSnackPost} children={t('publish')} />
        </div>
      </div>
      {(isPending || isPendingImage) && <Spinner />}
    </div>
  )
}
