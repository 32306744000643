import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { Logger } from '@inphiz/logger'
import * as Sentry from '@sentry/react-native'

export class ApiRequestError extends Error {
  constructor(message: string) {
    super(message)
    this.name = `ApiRequestError`

    // Removes this constructor from the stack trace, if supported
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiRequestError)
    }
  }
}

export function logResponse(response: AxiosResponse): AxiosResponse {
  if (!response)
    return response

  const config = response.config as AxiosRequestConfig<any>

  Logger.Log.debug('HTTP Request', {
    'status': response.status,
    'method': config.method?.toUpperCase(),
    'url': config.url,
    'baseUrl': config.baseURL,
    'correlation-id': config.headers?.['correlation-id'],
    // configuration: response.config,
    'requestBody': config.data,
    'responseBody': response.data,
    'requestHeaders': response.config?.headers,
  })
  return response
}

export function logErrorPreRequest(error: AxiosError) {
  const requestConfig = error.config
  const requestData = error.config?.data
  const method = error.config?.method?.toUpperCase()

  const message = `Pre-Request failed at: ${method}:${requestConfig?.url} `

  const errc = new ApiRequestError(message)

  const extraData: any = {
    requestMethod: method,
    requestUrl: requestConfig?.url,
    requestData,
  }

  Sentry.withScope((scope) => {
    scope.setFingerprint([
      // The “{{ default }}” token ensures Sentry also uses the original
      // stack trace in the grouping. But you can omit it if you want
      // to group only by your custom fields.
      '{{ default }}',

      // Then include any fields that make sense for grouping
      method || 'unknown_method',
      requestConfig?.url || 'unknown_url',
    ])

    scope.setExtra('http_request_data', extraData)
    Sentry.captureException(errc)
  })

  Logger.Log.error(`Pre-Request failed: ${message}`, {
    ...extraData,
    headers: requestConfig?.headers,
  })
}

export function logErrorResponse(error: AxiosError) {
  const requestConfig = error.config
  const requestData = error.config?.data
  const responseData = error.response?.data
  const statusCode = error.response?.status
  const method = error.config?.method?.toUpperCase()

  const message = `Request failed at: ${method}:${requestConfig?.url} [${statusCode}]`

  const errc = new ApiRequestError(message)

  const extraData: any = {
    requestMethod: method,
    requestUrl: requestConfig?.url,
    responseStatus: statusCode,
    requestData,
    responseData,
  }

  Sentry.withScope((scope) => {
    scope.setFingerprint([
      // The “{{ default }}” token ensures Sentry also uses the original
      // stack trace in the grouping. But you can omit it if you want
      // to group only by your custom fields.
      '{{ default }}',

      // Then include any fields that make sense for grouping
      method || 'unknown_method',
      requestConfig?.url || 'unknown_url',
      String(statusCode || 'unknown_status'),
    ])

    scope.setExtra('http_request_data', extraData)
    Sentry.captureException(errc)
  })

  Logger.Log.error(`Request failed: ${message}`, { ...extraData, headers: requestConfig?.headers })
}
