import moment from 'moment'

export function calculateDateText(date: string) {
  const today = new Date()
  const notificationDate = new Date(date)

  if (
    today.getMonth() === notificationDate.getMonth()
    && today.getFullYear() === notificationDate.getFullYear()
  ) {
    if (today.getDate() === notificationDate.getDate()) {
      const diff = today.getTime() - notificationDate.getTime()
      const hours = Math.floor((diff / (1000 * 60 * 60)) % 24)
      if (hours > 0)
        return `${hours} hours ago`

      const minutes = Math.floor((diff / (1000 * 60)) % 60)
      if (minutes > 0)
        return `${minutes} minutes ago`

      return 'Just now'
    }

    const dateDiff = today.getDate() - notificationDate.getDate()

    if (dateDiff === 1) {
      return `Yesterday at ${notificationDate.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      })}`
    }
  }

  return `${notificationDate.toLocaleDateString()} at ${notificationDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  })}`
}

export function dateFormat(publishTime: string) {
  const duration = moment.duration(moment().diff(publishTime))
  if (duration.asWeeks() < 2) {
    let formattedResult = ''
    if (duration.asSeconds() < 60)
      formattedResult = `${Math.floor(duration.asSeconds())}s`
    else if (duration.asMinutes() < 60)
      formattedResult = `${Math.floor(duration.asMinutes())}m`
    else if (duration.asHours() < 24)
      formattedResult = `${Math.floor(duration.asHours())}h`
    else formattedResult = `${Math.floor(duration.asDays())}d`

    return formattedResult
  }
  else {
    const isoDate = moment(publishTime).format('YYYY-MM-DD')
    return isoDate
  }
}

export function globalDateFormat(dateValue: string) {
  return moment(dateValue).format('yyyy-MM-DD')
}

export function dateTimeFormat(dateValue: string) {
  return moment(dateValue).format('yyyy-MM-DD hh:mm:ss A')
}

export function datePickerFormat(value: Date | null) {
  if (value) {
    const fixedTimeDate = new Date(
      value.getFullYear(),
      value.getMonth(),
      value.getDate(),
      0, // Hours set to 0 (12 AM)
      0, // Minutes set to 0
      0, // Seconds set to 0
    )
    return fixedTimeDate.toISOString()
  }
}
