import { useQuery } from '@tanstack/react-query'
import { QueryKey } from '@inphiz/api/src/QueryKey'
import APIFunctions from '@inphiz/api/src/Networking/APIFunctions'
import { APIConst } from '@inphiz/api'
import { DataCollectionApiResponse } from '@inphiz-shared/shared-models/src/Api/data-collection-api-response'
import { EquipmentAssignmentDto } from '@inphiz-shared/shared-models/src/F_Equipment/equipment-assignment-dto'
import { QueryBuilder } from '@inphiz/core-utilities'

const EquipmentAssignmentDTO = new EquipmentAssignmentDto()
export type EquipmentAssignmentItem = typeof EquipmentAssignmentDTO

const EquipmentAssignmentListDto = new DataCollectionApiResponse<EquipmentAssignmentItem>()
export type EquipmentAssignmentList = typeof EquipmentAssignmentListDto

interface EquipmentListParams {
  itemPerPage?: number
  skip?: number
  typeHandle?: string
}

export function useByequipmentType({
  itemPerPage = 0,
  skip = 0,
  typeHandle = '',
}: EquipmentListParams) {
  return useQuery<EquipmentAssignmentList>({
    queryKey: [QueryKey.byEquipmentType, typeHandle],
    queryFn: () => {
      const qb = new QueryBuilder(APIConst.equipment.getByequipmentType)
        .add('Cursor', skip)
        .add('Take', itemPerPage)
        .add('ParametersWithValues[EquipmentTypeHandle]', typeHandle)
      return APIFunctions.GET(qb.toString())
    },
  })
}
